import React, {useEffect, useState} from 'react';
import {Card, CardBody, Col, Container, Row} from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import ProductsTable from './ProductsTable';
import {useDispatch, useSelector} from 'react-redux';
import {deleteProduct, getProducts, productCode} from '../../../store/MastersProducts/actions';
import ProductOperationModal from './ProductOperationsModal';
import ProductDeleteModal from './ProductDeleteModal';

function Products() {
  const dispatch = useDispatch();
  const [modal_invoice, setModal_invoice] = useState(false);
  const [currentUser, setCurrentUser] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [isActive, setIsActive] = useState(true);
  document.title = 'Product - account-books';

  useEffect(() => {
    dispatch(getProducts);
    dispatch(productCode);
  }, [dispatch]);

  const productNumber = useSelector((state) => state.productsReducer?.productCode?.value);

  const productsList = useSelector((state) => state?.productsReducer?.productsList);

  const editClick = (item) => {
    setModal_invoice(true);
    setCurrentUser(item);
    setIsActive(item?.isActive);
  };

  const deleteClick = (item) => {
    setCurrentUser(item);
    setDeleteModal(true);
  };

  // modal actions
  const toggleModal = () => {
    setModal_invoice(!modal_invoice);
  };
  const toggleModalClose = () => {
    setModal_invoice(false);
    setCurrentUser('');
  };
  const handleModalClose = () => {
    setModal_invoice(false);
    setCurrentUser();
  };
  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };
  const toggleDeleteModalClose = () => {
    setDeleteModal(false);
    setCurrentUser();
  };
  const handleConfirmDelete = () => {
    dispatch(deleteProduct(currentUser.id));
    setDeleteModal(false);
    dispatch(getProducts);

    setCurrentUser();
  };
  return (
    <>
      <div className='page-content'>
        <Container fluid={true}>
          <Breadcrumbs title='Products' breadcrumbItem='Products' />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className='position-relative'>
                    <div className='modal-button mt-2'>
                      <Row className='align-items-start'>
                        <Col className='col-sm'>
                          <div>
                            <button
                              type='button'
                              className='btn btn-success mb-4'
                              data-bs-toggle='modal'
                              data-bs-target='#addInvoiceModal'
                              onClick={() => {
                                setModal_invoice(true);
                                setCurrentUser();
                              }}
                            >
                              <i className='mdi mdi-plus me-1'></i> Add New Product
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div id='table-invoices-list'>
                    <ProductsTable
                      listData={productsList}
                      onEditClick={(data) => editClick(data)}
                      onDeleteClick={(data) => deleteClick(data)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ProductOperationModal
        modal_user={modal_invoice}
        tog_user={() => toggleModal()}
        tog_userClose={() => toggleModalClose()}
        setmodal_user={() => handleModalClose()}
        currentUser={currentUser}
        productNumber={productNumber}
        isActive={isActive}
      />

      <ProductDeleteModal
        deleteModal={deleteModal}
        toggleDeleteModal={() => toggleDeleteModal()}
        toggleDeleteModalClose={() => toggleDeleteModalClose()}
        setDeleteModal={() => setDeleteModal(false)}
        handleConfirmDelete={(id) => handleConfirmDelete(id)}
      />
    </>
  );
}

export default Products;
