import {Form, useFormik} from 'formik';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Alert, Col, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row} from 'reactstrap';
import * as Yup from 'yup';
import {changePassword} from '../../../store/users/userTable/actions';

function UsersPaswordOperationModal(props) {
  const dispatch = useDispatch();

  const validationPassword = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: '',
      confirmpassword: ''
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Please enter password').matches(/^\S*$/, 'Whitespace is not allowed'),
      confirmpassword: Yup.string()
        .required('Please enter password Again')
        .matches(/^\S*$/, 'Whitespace is not allowed')
        .oneOf([Yup.ref('password'), null], 'Password and confirm password must match')
    }),
    onSubmit: (values) => {
      values.userID = props?.passwordId;
      if (isActive == 'login') {
        values.isTran = false;
      } else {
        values.isTran = true;
      }
      dispatch(changePassword(values));
      validationPassword.resetForm();
      props?.toggle();
    }
  });
  const [isActive, setIsActive] = useState('login');

  return (
    <>
      <Modal
        isOpen={props?.modal}
        toggle={() => {
          props?.toggle();
          validationPassword.resetForm();
        }}
        {...props?.args}
      >
        <ModalHeader
          toggle={() => {
            props?.toggle();
            validationPassword.resetForm();
          }}
        >
          Change Password{' '}
        </ModalHeader>
        <ModalBody>
          <form
            tag={Form}
            className='form-horizontal'
            onSubmit={(e) => {
              e.preventDefault();
              validationPassword.handleSubmit();
              return false;
            }}
          >
            {props?.error ? (
              <Alert color='danger'>
                <div>{props?.errorPassword}</div>
              </Alert>
            ) : null}
            <Row className='mb-3'>
              <Col>
                <input
                  className='form-check-input me-2'
                  type='radio'
                  name='formRadios'
                  id='formRadios1'
                  value='login'
                  checked={isActive === 'login'}
                  onChange={(e) => {
                    setIsActive(e?.target?.value);
                  }}
                />
                <Label className='form-check-label' htmlFor='formRadios1'>
                  Login Password
                </Label>

                <span className='ms-2'>
                  <input
                    className='form-check-input me-2'
                    type='radio'
                    name='formRadios'
                    id='formRadios2'
                    value='tran'
                    checked={isActive === 'tran'}
                    onChange={(e) => {
                      setIsActive(e?.target?.value);
                    }}
                  />
                  <Label className='form-check-label' htmlFor='formRadios2'>
                    Transaction Password{' '}
                  </Label>
                </span>
              </Col>
            </Row>

            <div className='mb-3'>
              <Label className='form-label'>{isActive == 'login' ? 'Login ' : 'Transaction '}Password</Label>
              <Input
                name='password'
                value={validationPassword.values.password || ''}
                type='password'
                placeholder={`Enter ${isActive == 'login' ? 'Login ' : 'Transaction '} password`}
                onChange={validationPassword.handleChange}
                onBlur={validationPassword.handleBlur}
                invalid={validationPassword.touched.password && validationPassword.errors.password ? true : false}
              />
              {validationPassword.touched.password && validationPassword.errors.password ? (
                <FormFeedback type='invalid'>
                  <div> {validationPassword.errors.password} </div>
                </FormFeedback>
              ) : null}
            </div>
            <div className='mb-3'>
              <Label className='form-label'>Confirm {isActive == 'login' ? 'Login ' : 'Transaction '} Password</Label>
              <Input
                name='confirmpassword'
                value={validationPassword.values.confirmpassword || ''}
                type='password'
                placeholder={`Enter confirm ${isActive == 'login' ? 'Login ' : 'Transaction '} password`}
                onChange={validationPassword.handleChange}
                onBlur={validationPassword.handleBlur}
                invalid={validationPassword.touched.confirmpassword && validationPassword.errors.confirmpassword ? true : false}
              />
              {validationPassword.touched.confirmpassword && validationPassword.errors.confirmpassword ? (
                <FormFeedback type='invalid'>
                  <div> {validationPassword.errors.confirmpassword} </div>
                </FormFeedback>
              ) : null}
            </div>

            <div className='mt-3 text-end'>
              <button className='btn btn-primary btn-block' type='submit'>
                Change {isActive == 'login' ? 'Login ' : 'Transaction '} Password
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}

export default UsersPaswordOperationModal;
