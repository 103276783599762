import React, {useEffect, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';
import {Modal} from 'reactstrap';
import {saveAccountGroup} from '../../../store/AccountGroup/actions';

function AddAccountGroupModal(props) {
  const dispatch = useDispatch();
  const result = useSelector((state) => state.accountsGroupReducer);

  const [firstClick, setFirstClick] = useState(false);
  let defaultValue = {
    groupName: '',
    parent: '',
    error: true
  };
  const [accGroup, setAccGroup] = useState(defaultValue);
  const [errorMsg, setErrorMsg] = useState(defaultValue);

  useEffect(() => {
    setFirstClick(props.firstClick);
    setAccGroup(props.accGroup);
    setErrorMsg(props.errorMsg);
  }, [props.accGroup, props.errorMsg]);

  const handleSave = () => {
    setFirstClick(true);
    handleCheckError(true);
  };

  useEffect(() => {
    if (firstClick) {
      handleCheckError();
    }
  }, [accGroup, firstClick]);

  const handleCheckError = (isSave) => {
    let msg = 'Please select';
    let obj = {...errorMsg};

    if (!accGroup?.parent?.value) {
      obj.parent = msg + ' parent';
      obj.error = true;
    } else {
      obj.parent = '';
      obj.error = false;
    }

    if (!accGroup?.groupName) {
      obj.groupName = msg + ' group name';
      obj.error = true;
    } else {
      obj.groupName = '';
      obj.error = false;
    }

    if (isSave && !obj.error) {
      dispatch(saveAccountGroup(accGroup));
    }

    setErrorMsg(obj);
  };

  return (
    <>
      <Modal isOpen={props.modal_invoice} onClosed={props.handleModalClose} className='addInvoiceModal' centered>
        <div className='modal-header'>
          <h5 className='modal-title' id='addInvoiceModalLabel'>
            {accGroup.recId ? 'Edit' : 'New'} Account Group
          </h5>
          <button onClick={props.toggleModal} type='button' className='btn-close' aria-label='Close'></button>
        </div>
        <div className='modal-body p-4'>
          <form>
            <div>
              <div>
                Parent Group<span className='text-danger'>*</span>
              </div>
              <Select
                placeholder='Parent Group'
                options={result?.accountsGroup}
                openMenuOnFocus={true}
                value={accGroup.parent}
                onChange={(e) => setAccGroup({...accGroup, parent: e})}
              />{' '}
              <div className=' text-error'>{errorMsg?.parent}</div>
              <br />
              <div>
                Group Name<span className='text-danger'>*</span>
              </div>
              <input
                type='text'
                placeholder='Group Name'
                className='form-control'
                value={accGroup.groupName || ''}
                onChange={(e) => setAccGroup({...accGroup, groupName: e.target.value})}
                onBlur={(e) => setAccGroup({...accGroup, groupName: e.target.value?.trim()})}
              />
              <div className='text-error'>{errorMsg?.groupName}</div>
              <br />
              <div className='d-flex align-items-start gap-3 mt-4'>
                <button type='button' id='nextBtn' className='btn btn-primary w-sm ms-auto' onClick={handleSave}>
                  {accGroup.recId ? 'Update' : 'Save'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default AddAccountGroupModal;
