import {PRODUCTS_FETCH_SUCCESS, PRODUCTS_TYPE_LIST, PRODUCTS_UNIT_LIST, PRODUCT_CODE} from './actionType';

const initialState = {
  error: '',
  productsList: []
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCTS_FETCH_SUCCESS:
      state = {
        ...state,

        error: '',
        productsList: action.payload
      };
      break;
    case PRODUCTS_TYPE_LIST:
      state = {
        ...state,

        error: '',
        ttpeList: action.payload
      };
      break;
    case PRODUCTS_UNIT_LIST:
      state = {
        ...state,

        error: '',
        unitList: action.payload
      };
      break;
    case PRODUCT_CODE:
      state = {
        ...state,

        error: '',
        productCode: action.payload
      };
      break;

    default:
      state = {...state};
      break;
  }
  return state;
};

export default productsReducer;
