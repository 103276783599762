import {CURRENCY_FETCH_SUCCESS, CONFIGURATION_FETCH_SUCCESS, LOADER_CONFIG_START, LOADER_CONFIG_STOP} from './actionTypes';

const initialState = {
  currency: [],
  configurationData: [],
  CompanyLoader: false
};

const companyConfiguration = (state = initialState, action) => {
  switch (action.type) {
    case CURRENCY_FETCH_SUCCESS:
      state = {
        ...state,
        currency: action.payload
      };
      break;
    case CONFIGURATION_FETCH_SUCCESS:
      state = {
        ...state,
        configurationData: action.payload
      };
      break;
    case LOADER_CONFIG_START:
      state = {
        ...state,
        CompanyLoader: true
      };
      break;
    case LOADER_CONFIG_STOP:
      state = {
        ...state,
        CompanyLoader: false
      };
      break;

    default:
      state = {...state};
      break;
  }
  return state;
};

export default companyConfiguration;
