import axios from 'axios';
import {toast} from 'react-toastify';
import {GET_ACCOUNTS_GROUP} from '../apiRoutes';
import {
  ACCOUNTGROUP_FETCH_START,
  ACCOUNTGROUP_FETCH_SUCCESS,
  ACCOUNTGROUP_FETCH_ERROR,
  ACCOUNTGROUP_SAVE_START,
  ACCOUNTGROUP_SAVE_SUCCESS,
  ACCOUNTGROUP_SAVE_ERROR,
  ACCOUNTGROUP_DELETE_SUCCESS,
  ACCOUNTGROUP_DELETE_ERROR
} from './actionTypes';

import {errorHandlers} from './../../helpers/errorHandlers';
import {getAccounts, loaderStart, loaderStop} from '../actions';

export const getAccountGroup = () => async (dispatch) => {
  dispatch(loaderStart());

  axios
    .get(GET_ACCOUNTS_GROUP)
    .then((response) => {
      response.map((a) => {
        a.value = a.id;
        a.label = a.groupName;
        a.parentGroupName = a.parentGroupName == null ? 'null' : a.parentGroupName;
      });
      dispatch(loaderStop());
      dispatch({
        type: ACCOUNTGROUP_FETCH_SUCCESS,
        payload: response
      });
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
      dispatch({
        type: ACCOUNTGROUP_FETCH_ERROR,
        payload: error?.response?.data?.errorMessage || 'Login Failed Please try again'
      });
    });
};

export const saveAccountGroup = (accGroup) => async (dispatch) => {
  dispatch(loaderStart());
  dispatch({
    type: ACCOUNTGROUP_SAVE_START,
    payload: ''
  });
  const data = {
    groupName: accGroup.groupName?.trim(),
    parentGroupName: accGroup.parent.label,
    parentGroupID: accGroup.parent.value
  };
  if (accGroup.recId) {
    data.id = accGroup.recId;
  }
  axios
    .post('AccountGroup/SaveAccountGroup', data)
    .then((response) => {
      if (response == 1 && accGroup.recId) {
        toast.success('Account group updated successfully');
      } else if (response == 1) {
        toast.success('Account group added successfully');
      } else {
        toast.error("Account group action can't be performed");
      }
      dispatch(getAccounts());
      dispatch({
        type: ACCOUNTGROUP_SAVE_SUCCESS,
        payload: response
      });
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};

export const deleteAccountGroup = (recId) => async (dispatch) => {
  axios
    .post('AccountGroup/DeleteAccountGroup/' + recId)
    .then((response) => {
      dispatch(getAccountGroup());
      toast.success('Account group deleted successfully');
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};
