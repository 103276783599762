import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {Row, Col, Modal, FormFeedback, Input, Label, Form} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';
import {productCode, typeList, unitList, saveProducts} from '../../../store/MastersProducts/actions';

export default function ProductOperationModal(props) {
  const {modal_user, tog_user, setmodal_user, currentUser, tog_userClose} = props;

  const [mode, setMode] = useState();
  const [product, setProduct] = useState();
  const [unit, setUnit] = useState();

  const [isActive, setIsActive] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(productCode);
    dispatch(typeList);
    dispatch(unitList);
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      setMode({label: currentUser?.cdMode == 'C' ? 'Cr' : 'Dr', value: currentUser?.cdMode});
      setProduct({label: currentUser?.products, value: currentUser?.productType});
      setUnit({label: currentUser?.units, value: currentUser?.unitID});
      setIsActive(props?.isActive);
    }
    return () => {
      setMode();
      setUnit();
      setProduct();
    };
  }, [props]);

  const productNumber = useSelector((state) => state.productsReducer?.productCode?.value);
  const unitList1 = useSelector((state) => state.productsReducer?.unitList);
  const typeList2 = useSelector((state) => state.productsReducer?.ttpeList);

  const accountType = [
    {value: 'C', label: 'Cr'},
    {value: 'D', label: 'Dr'}
  ];

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      productCode: currentUser ? currentUser.productCode : '',
      productName: currentUser ? currentUser.productName : '',
      productType: currentUser ? currentUser.productType : '',
      products: currentUser ? currentUser.products : '',
      units: currentUser ? currentUser.units : '',
      unitID: currentUser ? currentUser.unitID : '',
      opStock: currentUser ? currentUser.opStock : '',
      rate: currentUser ? currentUser.rate : '',
      cdMode: currentUser ? currentUser.cdMode : ''
    },
    validationSchema: Yup.object().shape({
      productName: Yup.string()
        .required('Please enter product name')
        .trim('Product name cannot include leading and trailing spaces')
        .strict(true),
      productType: Yup.string().required('Please select product'),
      units: Yup.string().required('Please select unit'),
      opStock: Yup.string().required('Please enter opening stock'),
      rate: Yup.string().required('Please enter rate'),
      cdMode: Yup.string().required('Please select account type')
    }),
    onSubmit: async (values) => {
      values.isActive = isActive;
      values.productCode = currentUser ? currentUser.productCode : productNumber;

      if (currentUser?.id) {
        values.id = currentUser?.id;
      } else {
        values.id = 0;
      }
      dispatch(saveProducts(values));
      validation.resetForm();
      setMode();
      setUnit();
      setProduct();
      tog_userClose();
    }
  });
  return (
    <Modal size='l' isOpen={modal_user} className='addInvoiceModal' centered>
      <div className='modal-header'>
        <h5 className='modal-title' id='addInvoiceModalLabel'>
          {' '}
          {currentUser ? 'Edit' : 'Add'} Product{' '}
        </h5>
        <button
          onClick={() => {
            setmodal_user(false);
            setMode();
            setUnit();
            setProduct();
            validation.resetForm();
          }}
          type='button'
          className='btn-close'
          aria-label='Close'
        ></button>
      </div>
      <div className='modal-body p-4'>
        <Form className='form-horizontal' onSubmit={validation.handleSubmit}>
          <Row className='mb-4'>
            <Col md='6'>
              <Label>Product Code</Label>
              <Input
                name='productCode'
                className='form-control'
                placeholder='Enter Code '
                type='text'
                disabled
                value={currentUser ? currentUser.productCode : productNumber}
              />
            </Col>
            <Col md='6'>
              <Label>
                Product Name <span className='text-danger'>*</span>
              </Label>
              <Input
                name='productName'
                className='form-control'
                placeholder='Enter Product Name '
                type='text'
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.productName}
                invalid={validation.touched.productName && validation.errors.productName ? true : false}
              />
              {validation.touched.productName && validation.errors.productName ? (
                <FormFeedback type='invalid'>
                  <div>{validation.errors.productName}</div>
                </FormFeedback>
              ) : null}
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col md='6'>
              <Label>
                Product Type <span className='text-danger'>*</span>
              </Label>
              <Select
                placeholder='Select Product Type'
                name='productType'
                openMenuOnFocus={true}
                value={product}
                onChange={(e) => {
                  setProduct(e);
                  validation.setFieldValue('productType', e.value);
                  validation.setFieldValue('products', e.label);
                  validation.handleChange('productType');
                  validation.handleChange('products');
                }}
                options={typeList2?.map((value) => ({
                  value: value.id,
                  label: value.ptName
                }))}
              />
              {validation.touched.productType && validation.errors.productType ? (
                <div className='text-error'>Please select product type</div>
              ) : null}
            </Col>
            <Col md='6'>
              <Label>
                Unit <span className='text-danger'>*</span>
              </Label>
              <Select
                placeholder='Select Unit'
                value={unit}
                openMenuOnFocus={true}
                onChange={(e) => {
                  setUnit(e);
                  validation.setFieldValue('unitID', e.value);
                  validation.setFieldValue('units', e.label);
                  validation.handleChange('unitID');
                  validation.handleChange('units');
                }}
                options={unitList1?.map((value) => ({
                  value: value.id,
                  label: value.unitName
                }))}
                name='units'
              />
              {validation.touched.units && validation.errors.units ? <div className='text-error'>Please select unit</div> : null}
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col md='6'>
              <Label>
                Opening Stock <span className='text-danger'>*</span>
              </Label>
              <Input
                name='opStock'
                className='form-control'
                placeholder='Enter Opening Stock '
                type='number'
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.opStock}
                invalid={validation.touched.opStock && validation.errors.opStock ? true : false}
              />
              {validation.touched.opStock && validation.errors.opStock ? (
                <FormFeedback type='invalid'>
                  <div>{validation.errors.opStock}</div>
                </FormFeedback>
              ) : null}
            </Col>
            <Col md='6'>
              <Label>
                Rate <span className='text-danger'>*</span>
              </Label>
              <Input
                name='rate'
                className='form-control'
                placeholder='Enter Rate '
                type='number'
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.rate}
                invalid={validation.touched.rate && validation.errors.rate ? true : false}
              />
              {validation.touched.rate && validation.errors.rate ? (
                <FormFeedback type='invalid'>
                  <div>{validation.errors.rate}</div>
                </FormFeedback>
              ) : null}
            </Col>
          </Row>

          <Row className='mt-4'>
            <Col md='6'>
              <Label>
                Type <span className='text-danger'>*</span>
              </Label>
              <Select
                placeholder='Select Account Type'
                name='cdMode'
                openMenuOnFocus={true}
                value={mode}
                onChange={(e) => {
                  setMode(e);
                  validation.setFieldValue('cdMode', e.value);
                  validation.handleChange('cdMode');
                }}
                options={accountType?.map((value) => ({
                  value: value.value,
                  label: value.label
                }))}
              />
              {validation.touched.cdMode && validation.errors.cdMode ? <div className='text-error'>Please select account type</div> : null}
            </Col>
            <Col md='6' className='mt-5'>
              <Input
                type='checkbox'
                value={isActive}
                id='checkbox_id'
                checked={isActive}
                onChange={(event) => {
                  setIsActive(event.target.checked);
                }}
              />

              <Label for='checkbox_id' className='ms-2 cursor'>
                IsActive
              </Label>
            </Col>
          </Row>

          <div className='mt-3 text-end'>
            <button className='btn btn-primary btn-block' type='submit'>
              {currentUser ? 'Update' : 'Save'}
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
