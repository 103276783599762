import {JOURNALS_FETCH_SUCCESS, JOURNALS_FETCH_ERROR, JOURNALS_NUMBER} from './actionTypes';

const initialState = {
  error: '',
  journalsList: [],
  loading: false,
  refresh: false
};

const journalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case JOURNALS_FETCH_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: '',
        journalsList: action.payload
      };
      break;
    case JOURNALS_FETCH_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload
      };
      break;
    case JOURNALS_NUMBER:
      state = {
        ...state,
        loading: false,
        journalsNum: action.payload
      };
      break;

    default:
      state = {...state};
      break;
  }
  return state;
};

export default journalsReducer;
