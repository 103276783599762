import {GET_SALES_LIST, POST_SALES_SAVE, POST_SALES_DELETE, GET_SALES_ID, GET_SALES_PENDING_ITEMS} from './actionTypes';

const initialState = {
  error: '',
  refresh: new Date(),
  salesList: [],
  pendingItemList: []
};

const salesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SALES_LIST:
      state = {
        ...state,
        error: '',
        salesList: action.payload,
        refresh: new Date()
      };
      break;
    case POST_SALES_SAVE:
      state = {
        ...state,
        error: '',
        salesList: [],
        refresh: new Date(),
        customerInfo: action.payload
      };
      break;

    case POST_SALES_DELETE:
      state = {
        ...state,
        error: '',
        delRefresh: new Date()
      };
      break;

    case GET_SALES_PENDING_ITEMS:
      state = {
        ...state,
        error: '',
        pendingItemList: action.payload,
        delRefresh: new Date()
      };
      break;

    default:
      state = {...state};
      break;
  }
  return state;
};

export default salesReducer;
