import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form} from 'reactstrap';

//redux
import {useSelector, useDispatch} from 'react-redux';

import {Link} from 'react-router-dom';
import withRouter from '../../components/Common/withRouter';
import {forgetPassword} from '../../store/actions';

// Formik Validation
import * as Yup from 'yup';
import {useFormik} from 'formik';

// action

// import images
import logo from '../../assets/images/ACCBOOK_white.svg';
import {FORGET_USER_DONE} from '../../store/auth/login/actionTypes';

const ForgetPasswordPage = (props) => {
  document.title = 'Forget Password  | Account-Books';
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch({
        type: FORGET_USER_DONE
      });
    };
  }, []);
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please enter username')
    }),
    onSubmit: (values) => {
      dispatch(forgetPassword(values?.email, props.router.navigate));
      validation.resetForm();
    }
  });

  const {error} = useSelector((state) => ({
    error: state.login.error
  }));
  return (
    <React.Fragment>
      <div className='authentication-bg min-vh-100'>
        <div className='bg-overlay'></div>
        <Container>
          <div className='d-flex flex-column min-vh-100 px-3 pt-4'>
            <Row className='justify-content-center my-auto'>
              <Col md={8} lg={6} xl={5}>
                <div className='text-center mb-4'>
                  <Link to='/'>
                    <img src={logo} alt='' height='45' /> <span className='logo-txt text-light'></span>
                  </Link>
                </div>
                <Card>
                  <CardBody className='p-4'>
                    <div className='text-center mt-2'>
                      <h5 className='text-primary'>Forgot Password</h5>
                      {/* <p className='text-muted'>Enter email .</p> */}
                    </div>
                    <div className='p-2 mt-4'>
                      {error && error ? (
                        <Alert color='danger' style={{marginTop: '13px'}}>
                          {error}
                        </Alert>
                      ) : null}
                      <Form
                        className='form-horizontal'
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className='mb-3'>
                          <Label className='form-label '>username</Label>
                          <Input
                            name='email'
                            className='form-control'
                            placeholder='Enter username'
                            type='email'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ''}
                            invalid={validation.touched.email && validation.errors.email ? true : false}
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type='invalid'>
                              <div>{validation.errors.email}</div>
                            </FormFeedback>
                          ) : null}
                        </div>
                        <Row className='mb-3'>
                          <Col className='text-end'>
                            <button className='btn btn-primary w-md ' type='submit'>
                              Send Email
                            </button>
                          </Col>
                        </Row>
                        <div className='mt-4 text-center'>
                          <Link to='/login' className='text-muted'>
                            <i className='mdi mdi-lock me-1' />
                            Go back to Login
                          </Link>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object
};

export default withRouter(ForgetPasswordPage);
