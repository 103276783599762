import React from 'react';
import {Link} from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

//redux
import {useDispatch, useSelector} from 'react-redux';
import {changeTheme, changeSidebarType} from '../../store/actions';
import {themeModeTypes, leftSidebarTypes} from '../../constants/layout';
import {useNavigate} from 'react-router-dom';
// import Img
import logo from '../../assets/images/ACCBOOK_black.svg';
import logoSM from '../../assets/images/acc_mark.svg';
import ProfileMenu from '../../components/Common/TopbarDropdown/ProfileMenu';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {useState} from 'react';
import {getCompanyListWithoutNav, getCompanyToken} from '../../store/actions';
import {useEffect} from 'react';

const Header = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false);

  function tToggle() {
    var body = document.body;
    if (window.innerWidth <= 998) {
      body.classList.toggle('sidebar-enable');
    } else {
      body.classList.toggle('sidebar-enable');
      body.classList.toggle('vertical-collpsed');
      dispatch(changeSidebarType('A', leftSidebarTypes.DEFAULT));
    }
  }
  const data = JSON.parse(localStorage.getItem('userData'));

  // ---------------

  useEffect(() => {
    dispatch(getCompanyListWithoutNav());
  }, [dispatch]);

  const {companyError, comapny, loading} = useSelector((state) => ({
    companyError: state.login.companyError,
    comapny: state.login.company,
    loading: state.login.loading
  }));
  const onCompanyClick = (companyData) => {
    dispatch(getCompanyToken(companyData?.companyID, navigate, true));
  };

  return (
    <React.Fragment>
      <header id='page-topbar' className='isvertical-topbar'>
        <div className='navbar-header'>
          <div className='d-flex'>
            <div className='navbar-brand-box'>
              <Link to='/' className='logo logo-dark'>
                <span className='logo-sm'>
                  <img src={logoSM} alt='' height='22' />
                </span>
                <span className='logo-lg'>
                  <img src={logo} alt='' height='45' /> <span className='logo-txt'></span>
                </span>
              </Link>
              <Link to='/' className='logo logo-light'>
                <span className='logo-sm'>
                  <img src={logoSM} alt='' height='22' />
                </span>
                <span className='logo-lg'>
                  <img src={logo} alt='' height='45' /> <span className='logo-txt'></span>
                </span>
              </Link>
            </div>
            <button
              type='button'
              className='btn btn-sm px-3 font-size-16 header-item vertical-menu-btn'
              onClick={() => {
                tToggle();
              }}
            >
              <i className='fa fa-fw fa-bars'></i>
            </button>
            <h3 className='font-size-26 mt-3 ms-3 comapnyName'>hello, &nbsp;{data?.userName || 'Admin'}</h3>{' '}
          </div>
          <div className='d-flex'>
            <div className='dropdown d-inline-block'></div>
            <div className='dropdown  d-sm-inline-block mt-1'>
              <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className='d-inline-block'>
                <DropdownToggle
                  className='btn header-item user text-start '
                  id='page-header-user-dropdown'
                  tag='button'
                  data-bs-toggle='dropdown'
                  aria-haspopup={true}
                  aria-expanded={false}
                >
                  <label className='control-label'> {data?.companyName}</label>
                  <p className='control-label'> Switch company</p>
                </DropdownToggle>
                <DropdownMenu className='dropdown-menu-end pt-0 paddingTop'>
                  {comapny && comapny.length > 0
                    ? comapny.map((com) => {
                        return (
                          <DropdownItem
                            onClick={() => onCompanyClick(com)}
                            disabled={loading}
                            className={data?.companyName == com?.companyName ? 'cursor bg-light' : 'cursor '}
                            key={com?.companyName}
                          >
                            <i className='bx bx-log-out text-muted font-size-18 align-middle me-1' />
                            <span className='align-middle'>{com?.companyName}</span>
                          </DropdownItem>
                          // <DropdownItem
                          //   // button
                          //   onClick={() => onCompanyClick(com)}
                          //   type='button'
                          //   disabled={loading}
                          //   className='cursor btn btn-primary btn-lg waves-effect waves-light w-100 companyButton'
                          // >
                          //   <span className='cursor'>{com?.companyName}</span>
                          // </DropdownItem>
                        );
                      })
                    : ''}
                </DropdownMenu>
              </Dropdown>
            </div>

            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
