import axios from 'axios';
import {toast} from 'react-toastify';

import {
  GET_ACCOUNTS_BY_BANK,
  ADD_ACCOUNT_GROUP,
  DELETE_ACCOUNT_GROUP,
  GET_ACCOUNTS,
  GET_ACCOUNTS_GROUP,
  GET_ACCOUNTS_GROUP_CODE
} from '../apiRoutes';

import {
  ACCOUNT_FETCH_SUCCESS,
  ACCOUNT_GROUP_FETCH_SUCCESS,
  ACCOUNT_GROUP_FETCH_COUNT_SUCCESS,
  ADD_ACCOUNT_DATA,
  REMOVE_ACCOUNT_DATA,
  GET_ACCOUNT_BY_BANK
} from './actionTypes';

import {errorHandlers} from './../../helpers/errorHandlers';
import {loaderStart, loaderStop} from '../actions';

export const getAccByBank = () => async (dispatch) => {
  dispatch(loaderStart());

  axios
    .get(GET_ACCOUNTS_BY_BANK)
    .then(async (res) => {
      res.map((a) => {
        a.value = a.id;
        a.label = a.accountName;
      });
      await dispatch(loaderStop());

      await dispatch({
        type: GET_ACCOUNT_BY_BANK,
        payload: res
      });
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};

export const getAccounts = () => async (dispatch) => {
  dispatch(loaderStart());
  axios
    .get(GET_ACCOUNTS)
    .then(async (response) => {
      response.map((a) => {
        a.value = a.id;
        a.label = a.accountName;
      });

      await dispatch(loaderStop());
      await dispatch({
        type: ACCOUNT_FETCH_SUCCESS,
        payload: response
      });
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};

export const getAccountsGroups = () => async (dispatch) => {
  dispatch(loaderStart());
  axios
    .get(GET_ACCOUNTS_GROUP)
    .then((response) => {
      dispatch(loaderStop());
      dispatch({
        type: ACCOUNT_GROUP_FETCH_SUCCESS,
        payload: response
      });
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};
export const getAccountsCounts = () => async (dispatch) => {
  axios
    .get(GET_ACCOUNTS_GROUP_CODE)
    .then((response) => {
      dispatch({
        type: ACCOUNT_GROUP_FETCH_COUNT_SUCCESS,
        payload: response?.value
      });
    })
    .catch((error) => {
      errorHandlers(error);
    });
};

export const addAccounts = (accountData, onclear) => async (dispatch) => {
  dispatch(loaderStart());
  axios
    .post(ADD_ACCOUNT_GROUP, accountData)
    .then((response) => {
      dispatch(loaderStop());
      dispatch(getAccounts());
      dispatch(getAccountsGroups());
      dispatch(getAccountsCounts());
      if (onclear) {
        dispatch({
          type: REMOVE_ACCOUNT_DATA
        });
        onclear();
      } else {
        response.isParty = accountData.isParty;
        dispatch({
          type: ADD_ACCOUNT_DATA,
          payload: response
        });
      }
      if (accountData?.ID) {
        toast.success('Account updated successfully');
      } else {
        toast.success('Account added successfully');
      }
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};
export const deleteAccount = (id, clear) => async (dispatch) => {
  dispatch(loaderStart());

  axios
    .post(DELETE_ACCOUNT_GROUP + id)
    .then((response) => {
      dispatch(loaderStop());
      dispatch(getAccounts());
      dispatch(getAccountsGroups());
      dispatch(getAccountsCounts());
      toast.info('Account deleted successfully');
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};
