import React from 'react';
import TableContainer from '../../../components/Common/repotr';
import moment from 'moment';

const GroupTable = (props) => {
  const columns = [
    {
      Header: 'Tran Id',
      Cell: (cell) => {
        const particularValues = new Set(['Journal', 'Sales - Currency', 'Sales - Metal', 'Purchase - Metal', 'Purchase - Currency']);
        const tranID = cell?.cell?.row?.original?.tranID;
        const particular = cell?.cell?.row?.original?.particular;
        return <div>{tranID === 0 || (particular && particularValues.has(particular)) ? '' : tranID}</div>;
      },
      Filter: false
    },

    {
      Header: 'Date',
      Cell: (cell) => {
        return (
          <div
            className='cursor text-primary'
            onClick={() => {
              if (
                cell?.cell?.row?.original?.particular == 'Bank Tran Journal' ||
                cell?.cell?.row?.original?.particular == 'Bank Comm Journal'
              ) {
                return;
              } else {
                props?.handleChangeLeader(cell?.cell?.row?.original?.tranID, cell?.cell?.row?.original?.particular);
              }
            }}
          >
            {/* <div className='cursor text-primary'> */}{' '}
            {moment(cell?.cell?.row?.original.tranDate).format('DD/MM/YYYY') === '01/01/1900'
              ? ''
              : moment(cell?.cell?.row?.original.tranDate).format('DD/MM/YYYY')}
          </div>
        );
      },
      Filter: false
    },

    {
      Header: 'Particular',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.particular}</div>;
      },
      Filter: false
    },
    {
      Header: 'Narration',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.naration}</div>;
      },
      Filter: false
    },
    {
      Header: 'Brokerage',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.brokerage == 0 ? null : cell?.cell?.row?.original.brokerage}</div>;
      },
      Filter: false
    },
    {
      Header: 'Cr Amount',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.cAmount == 0 ? null : cell?.cell?.row?.original.cAmount}</div>;
      },
      Filter: false
    },

    {
      Header: 'Dr Amount',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.dAmount == 0 ? null : cell?.cell?.row?.original.dAmount}</div>;
      },
      Filter: false
    },
    {
      Header: 'Balance',
      accessor: 'balance',
      Filter: true
    }
  ];
  const columns2 = [
    {
      Header: 'Tran Id',
      Cell: (cell) => {
        const particularValues = new Set(['Journal', 'Sales - Currency', 'Sales - Metal', 'Purchase - Metal', 'Purchase - Currency']);
        const tranID = cell?.cell?.row?.original?.tranID;
        const particular = cell?.cell?.row?.original?.particular;
        return <div>{tranID === 0 || (particular && particularValues.has(particular)) ? '' : tranID}</div>;
      },
      Filter: false
    },
    {
      Header: 'Date',
      Cell: (cell) => {
        return (
          <div
            className='cursor text-primary'
            onClick={() => {
              if (cell?.cell?.row?.original?.particular == 'Bank Tran Journal') {
                return;
              } else {
                props?.handleChangeLeader(cell?.cell?.row?.original?.tranID, cell?.cell?.row?.original?.particular);
              }
            }}
          >
            {/* <div className='cursor text-primary'> */}{' '}
            {moment(cell?.cell?.row?.original.tranDate).format('DD/MM/YYYY') === '01/01/1900'
              ? ''
              : moment(cell?.cell?.row?.original.tranDate).format('DD/MM/YYYY')}
          </div>
        );
      },
      Filter: false
    },
    {
      Header: 'Particular',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.particular}</div>;
      },
      Filter: false
    },

    {
      Header: 'Brokerage',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.brokerage == 0 ? null : cell?.cell?.row?.original.brokerage}</div>;
      },
      Filter: false
    },
    {
      Header: 'Cr Amount',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.cAmount == 0 ? null : cell?.cell?.row?.original.cAmount}</div>;
      },
      Filter: false
    },
    {
      Header: 'Dr Amount',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.dAmount == 0 ? null : cell?.cell?.row?.original.dAmount}</div>;
      },
      Filter: false
    },
    {
      Header: 'Balance',
      accessor: 'balance',
      Filter: true
    }
  ];

  return (
    <>
      <TableContainer
        columns={props.handleFilter ? columns : columns2}
        data={props?.tableData}
        isFilterIs={props.handleFilter}
        tableDataExtra={props?.tableDataExtra}
        asc={true}
        isGlobalFilter={false}
        customPageSize={20}
      />
    </>
  );
};

export default GroupTable;
