import React, {useState, useEffect, memo} from 'react';
import {Col, Input, Row, Table} from 'reactstrap';
import moment from 'moment';
import {useDispatch} from 'react-redux';
import {ApproveCashbook} from '../../store/actions';
const CB_Table = ({tableData, handleEdit, handleDelete, filter, type, handleOpenUnlock, tableLock, cashLock, cashStatus}) => {
  const dispatch = useDispatch();

  const handleApprove = (row) => {
    dispatch(ApproveCashbook(row, filter, type));
  };

  return (
    <>
      <Row className='mt-4 '>
        <Col sm={12}>
          <Table
            responsive
            variant='dark'
            className='
              table-bordered'
          >
            <thead>
              <tr style={{background: '#F5F6F8'}}>
                <th className='w300'>Token</th>
                <th>Amount </th>
                <th> Account </th>
                <th> Type </th>
                {cashStatus ? <th>Status</th> : <></>}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData?.map((row, i) => (
                  <>
                    <tr key={row.id} className={row?.isDeleted === 1 && 'deleted'}>
                      <td>{row?.narration}</td>
                      <td>{Number(row?.amount)?.toFixed(3)}</td>
                      <td>{row?.accountName}</td>
                      <td>{row?.trans_type == 'I' ? 'Inward' : 'Outward'}</td>
                      {cashStatus ? (
                        <td>
                          {row?.isDeleted == 1 ? (
                            <span className='cursor badge badge-pill badge-soft-danger font-size-12'>Deleted</span>
                          ) : row?.cashStatus == 1 ? (
                            <span className='cursor badge badge-pill badge-soft-warning font-size-12' onClick={() => handleApprove(row)}>
                              Pending
                            </span>
                          ) : (
                            <span className='cursor badge badge-pill badge-soft-success font-size-12'>Ok</span>
                          )}
                        </td>
                      ) : (
                        <></>
                      )}

                      {!cashLock || !tableLock || !row.isLock ? (
                        <td>
                          {row?.isDeleted ? (
                            <></>
                          ) : (
                            <>
                              <span
                                className=' cursor text-primary mx-1'
                                onClick={() => {
                                  handleEdit(row, true);
                                }}
                              >
                                <i className='bx bx-pencil font-size-18'></i>
                              </span>
                              <span
                                className=' cursor text-danger'
                                onClick={() => {
                                  handleDelete(row);
                                }}
                              >
                                <i className='bx bx-trash-alt font-size-18'></i>
                              </span>
                            </>
                          )}
                        </td>
                      ) : (
                        <td>
                          {!row?.isDeleted && (
                            <i className='bx bx-lock  font-size-20 cursor text-primary' onClick={(e) => handleOpenUnlock(e, 'both')}></i>
                          )}
                        </td>
                      )}
                    </tr>
                  </>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};
export default memo(CB_Table);
