import React from 'react';
import {Route, Routes} from 'react-router-dom';

// redux
import {useSelector} from 'react-redux';

//constants
import {layoutTypes} from '../constants/layout';

// layouts
import NonAuthLayout from '../Layout/NonAuthLayout';
import VerticalLayout from '../Layout/VerticalLayout/index';
import HorizontalLayout from '../Layout/HorizontalLayout/index';
import {AuthProtected} from './AuthProtected';

import {authProtectedRoutes, publicRoutes, extraAuthRoutes} from './routes';

const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};

const Index = () => {
  const {layoutType} = useSelector((state) => ({
    layoutType: state.Layout.layoutType
  }));
  const Layout = getLayout(layoutType);

  const menuList = useSelector((state) => state.login.menuList);
  const m1 = localStorage.getItem('menuList');
  let localMenuList = [];
  if (m1) {
    localMenuList = JSON.parse(m1);
  }
  let arr = [];
  authProtectedRoutes &&
    authProtectedRoutes?.map((a) => {
      localMenuList &&
        localMenuList?.map((b) => {
          if (a?.path.includes(b?.path) && b?.isAllowed) {
            arr.push(a);
          }
        });
    });
  arr.push(...extraAuthRoutes);
  return (
    <Routes>
      <Route>
        {publicRoutes.map((route, idx) => (
          <Route path={route.path} element={<NonAuthLayout>{route.component}</NonAuthLayout>} key={idx} exact={true} />
        ))}
      </Route>

      <Route>
        {arr.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <AuthProtected>
                <Layout>{route.component}</Layout>
              </AuthProtected>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Route>
    </Routes>
  );
};

export default Index;
