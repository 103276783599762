import React from 'react';
import {Row, Col, Container, Card, CardBody} from 'reactstrap';

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import GroupTable from './StockTable';
import {useSelector} from 'react-redux';
import StockOpeation from './stockOperation';
const Stock = () => {
  document.title = 'Stock - account-books';

  const stockResult = useSelector((state) => state.stockReducer);

  return (
    <>
      <div className='page-content'>
        <Container fluid={true}>
          <Breadcrumbs title='Stock Report' breadcrumbItem='Stock Report' />

          <Row>
            <Col lg={12}>
              <Card style={{minHeight: '350px'}}>
                <CardBody>
                  <StockOpeation />
                  <div id='table-invoices-list'>
                    <GroupTable tableData={stockResult?.stockList?.stocks} tableDataExtra={stockResult?.stockList} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Stock;
