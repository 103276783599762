/* eslint-disable array-callback-return */
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {Row, Col, Container, Modal, Card, CardBody, FormFeedback, Input, Label, Form} from 'reactstrap';

//Import Flatepicker

// Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

import UsersListTable from './cityTable';
import {useDispatch, useSelector} from 'react-redux';
import {deleteCity, getCityList} from '../../../store/actions';
import AddUpdateCity from './addUpdateCity';
import DeleteCity from './deleteCity';
const Cities = (args) => {
  document.title = 'City - account-books';

  const [modal_user, setmodal_user] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [currentUser, setCurrentUser] = useState('');
  const [deleteUser, setDeleteUser] = useState('');
  const [isActive, setIsActive] = useState(true);

  const onclearValue = () => {
    setCurrentUser('');
  };

  function tog_user() {
    setmodal_user(!modal_user);
    onclearValue();
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCityList());
  }, [dispatch]);

  const {city} = useSelector((state) => ({
    city: state.city.city
  }));

  const editClick = async (data) => {
    setCurrentUser(data);
    setmodal_user(true);
    setIsActive(data?.isActive);
  };
  const handleConfirmDelete = () => {
    dispatch(deleteCity(deleteUser));
    setDeleteModal(false);
    setDeleteUser('');
  };
  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleDelete = (row) => {
    setDeleteModal(true);
    setDeleteUser(row);
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid={true}>
          <Breadcrumbs title='Account Users' breadcrumbItem='City' />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className='position-relative'>
                    <div className='modal-button mt-2'>
                      <Row className='align-items-start'>
                        <Col className='col-sm'>
                          <div>
                            <button
                              type='button'
                              className='btn btn-success mb-4'
                              data-bs-toggle='modal'
                              data-bs-target='#addInvoiceModal'
                              onClick={() => {
                                tog_user();
                              }}
                            >
                              <i className='mdi mdi-plus me-1'></i> Add New City
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div id='table-invoices-list'>
                    <UsersListTable UsersData={city} onEditClick={(data) => editClick(data)} handleDelete={(id) => handleDelete(id)} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <AddUpdateCity
        modal_user={modal_user}
        tog_user={() => tog_user()}
        setmodal_user={() => setmodal_user()}
        currentUser={currentUser}
        isActive={isActive}
      />
      <DeleteCity
        deleteModal={deleteModal}
        toggleDeleteModal={() => toggleDeleteModal()}
        setDeleteModal={() => setDeleteModal(false)}
        handleConfirmDelete={(id) => handleConfirmDelete(id)}
      />
    </React.Fragment>
  );
};

export default Cities;
