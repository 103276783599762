/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Table,
  Row
} from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import moment from 'moment';
import {REMOVE_ACCOUNT_DATA} from '../../store/Accounts/actionTypes';

import Select from 'react-select';
import {useDispatch, useSelector} from 'react-redux';
import Flatpickr from 'react-flatpickr';
import {createRef} from 'react';
import {useScreenshot, createFileName} from 'use-react-screenshot';
import {
  getAccByBank,
  getBankBookList,
  saveBankBook,
  deleteBankBook,
  getAccounts,
  downloadBankBookList,
  getBankCommList,
  getCompanyConfigurtion,
  saveComm,
  deleteComm
} from '../../store/actions';
import AccountModal from '../Transactions/accountModal';
import BB_Table from './BBTable';
import Comm from './Comm';
import TransactionPassword from '../Transactions/transactionPassword';

const BankBook = (args) => {
  document.title = 'BankBook' + ' - account-books';
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);
  const [filteredData2, setFilteredData2] = useState([]);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(0);

  const [modal_invoice, setmodal_invoice] = useState(false);
  const [modalConfig, setModalConfig] = useState(false);
  const [party, setParty] = useState(false);
  const [party2, setParty2] = useState(false);

  let prevDate = moment().subtract(2, 'day');
  const [unlockModal, setUnlockModal] = useState(false);
  const [rowLock, setRowLock] = useState(false);
  const [tempUnlock, setTempUnlock] = useState(false);
  const [tableLock, setTableLock] = useState(false);
  const [lockType, setLockType] = useState('both');
  const [bankLock, setBankLock] = useState(false);
  const [bankStatus, setBankStatus] = useState(false);

  const ref = useRef();
  const [image, takeScreenShot] = useScreenshot({
    type: 'image/jpeg',
    quality: 1.0
  });

  let defaultValue = {
    amount: '',
    sender: '',
    party: '',
    remark: '',
    errShow: false
  };
  const [bookInfo, setBookInfo] = useState(defaultValue);
  const [errorMsg, setErrorMsg] = useState({
    sender: '',
    party: '',
    amount: '',
    error: true
  });
  const [date, setDate] = useState([new Date()]);
  const [modal, setModal] = useState(false);
  const [type, setType] = useState('I');
  const [isImage, setIsimage] = useState(false);
  const fp = useRef(null);
  const accResult = useSelector((state) => state.accounts);
  const currentAccountData = useSelector((state) => state.accounts.currentAccountData);
  const bookReducer = useSelector((state) => state.bankBookReducer);
  const configList = useSelector((state) => state.companyConfiguration.configurationData);

  // lock unlock-----------------------------------------

  useEffect(() => {
    configList.length > 0 &&
      configList?.map((a) => {
        if (a.code == 'BankLock') {
          a.value == 'true' ? setBankLock(true) : setBankLock(false);
        }
        if (a.code == 'BankStatus') {
          a.value == 'true' ? setBankStatus(true) : setBankStatus(false);
        }
      });
  }, [configList]);

  const toggleUnlockModal = () => {
    setUnlockModal(!unlockModal);
  };

  useEffect(() => {
    setLockType('both');
    setTempUnlock(false);
    if (new Date(date[0]) < new Date(prevDate._d)) {
      setRowLock(true);
      setTableLock(true);
    } else {
      setRowLock(false);
      setTableLock(false);
    }
  }, [date]);

  const handleOpenUnlock = useCallback((e, lockType) => {
    e.preventDefault();
    setUnlockModal(true);
    setLockType(lockType);
  }, []);

  const handleUnlock = (lockType) => {
    if (lockType == 'both') {
      setRowLock(false);
      setTableLock(false);
    } else {
      setRowLock(false);
      setTableLock(true);
    }
  };

  const toggleTempUnlock = () => {
    setTempUnlock(false);
  };

  //--------------------------------------------------------------------

  useEffect(() => {
    setFilteredData(bookReducer?.bankBookList?.bankBookList);
    setFilteredData2(bookReducer?.bankBookList?.bankBookList);
  }, [bookReducer?.bankBookList]);

  useEffect(() => {
    if (currentAccountData?.isParty) {
      setBookInfo({
        ...bookInfo,
        party: {
          value: currentAccountData.id,
          label: currentAccountData.accountName
        }
      });
    } else if (currentAccountData?.isParty == false) {
      setBookInfo({
        ...bookInfo,
        sender: {
          value: currentAccountData.id,
          label: currentAccountData.accountName
        }
      });
    }
  }, [currentAccountData]);

  const onclearValue = (type1) => {
    setModal(false);
    if (type1) {
      setType(type1);
    }
    setErrorMsg({error: true});
    setBookInfo(defaultValue);
    setParty2('');
    setSearch('');
  };

  const tog_invoice = (isParty) => {
    setmodal_invoice(!modal_invoice);
    setParty(isParty);
  };

  const toggle = () => {
    onclearValue();
    setModal(!modal);
  };

  useEffect(() => {
    dispatch(getBankBookList(date, type, party2, filter));
    setSearch('');
  }, [date, type, party2, filter]);

  useEffect(() => {
    dispatch({
      type: REMOVE_ACCOUNT_DATA
    });
    dispatch(getCompanyConfigurtion());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAccounts());
    dispatch(getAccByBank());
  }, [currentAccountData]);

  const handleBlurAmt = () => {
    if (bookInfo.amount.length <= 3) {
      let amt = Number(bookInfo.amount / 1000).toFixed(3);
      setBookInfo({...bookInfo, amount: amt});
    } else {
      if (bookInfo.amount.toString().includes('.')) {
        setBookInfo({...bookInfo, amount: (+bookInfo.amount)?.toFixed(3)});
      } else {
        setBookInfo({...bookInfo, amount: (+bookInfo.amount / 1000).toFixed(3)});
      }
    }
  };

  const handleAmount = (value) => {
    if (value == '') {
      setBookInfo({...bookInfo, amount: ''});
    } else if (value >= 0) {
      const val = ('' + value).replace('.', '');
      if (val?.length > 3) {
        const suppressedValue = Number(+val / 1000).toFixed(3);
        setBookInfo({...bookInfo, amount: suppressedValue});
      } else {
        setBookInfo({...bookInfo, amount: Number(value)});
      }
    }
  };
  const handleDate = (e) => {
    if (e.length == 0) {
      setDate([new Date()]);
    } else {
      setDate(e);
    }
  };

  const ondownloadClick = (typedoc) => {
    dispatch(downloadBankBookList(moment(date[0]).format('MM/DD/YYYY'), typedoc, type, party2,bankStatus));
  };

  const handleConfirmDelete = () => {
    dispatch(deleteBankBook(bookInfo.id, date, type, onclearValue, filter));
    setModal(false);
  };

  const handleSaveBook = () => {
    setBookInfo({...bookInfo, errShow: true});
    handleValidate(true);
  };

  useEffect(() => {
    handleValidate();
  }, [bookInfo]);

  const handleValidate = (isSave) => {
    let msg = 'Please select';
    let obj = {...errorMsg};

    if (!bookInfo?.sender?.value) {
      obj.sender = msg + ' sender';
    } else {
      obj.sender = '';
    }

    if (!bookInfo?.party?.value) {
      obj.party = msg + ' party';
    } else {
      obj.party = '';
    }

    if (bookInfo?.party?.value && bookInfo?.sender?.value) {
      obj.error = false;
    } else {
      obj.error = true;
    }

    if (isSave && !obj.error) {
      bookInfo.date = date[0];
      bookInfo.type = type;
      bookInfo.bankStatus = bankStatus;

      dispatch(saveBankBook(bookInfo, onclearValue, fp, filter, toggleTempUnlock));
    }
    setErrorMsg(obj);
  };
  const download = (image, {name = 'img', extension = 'jpg'} = {}) => {
    const a = document.createElement('a');
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const downloadScreenshot = async () => {
    setIsimage(true);
    setTimeout(() => {
      takeScreenShot(ref.current).then(download);
      setIsimage(false);
    }, 500);
  };

  const handleSearch = (key) => {
    const result = filteredData2.filter((a) => {
      if (a.trans_type == 'I') {
        a.type = 'inward';
      } else if (a.trans_type == 'O') {
        a.type = 'outward';
      }
      return (
        a.date?.toString()?.includes(key) ||
        a.amount?.toString()?.includes(key) ||
        a.bankAccountName?.toString()?.includes(key) ||
        a.partyAccountName?.toString()?.includes(key.toLowerCase()) ||
        a.type?.toString()?.includes(key.toLowerCase()) ||
        a.narration?.toString().toLowerCase()?.includes(key.toLowerCase()) ||
        a.supplierName?.toLowerCase()?.includes(key.toLowerCase()) ||
        a.trans_type?.includes(key)
      );
    });

    setFilteredData(result);
    setSearch(key);
  };

  const handleConfig = () => {
    dispatch(getBankCommList(date, type));
    setModalConfig(true);
  };

  const togModalConfig = () => {
    setModalConfig(!modalConfig);
  };

  let br = useMemo(() => bookReducer.bankBookList, [date, party2, bookReducer.bankBookList]);
  let fd = useMemo(() => filteredData, [date, party2, filteredData]);
  const handleEdit = useCallback((row, tempUnl) => {
    // setDate([new Date(row.date)]);
    setTempUnlock(tempUnl);

    setType(row?.trans_type);

    setBookInfo({
      id: row.id,
      amount: Number(row.amount)?.toFixed(3),
      sender: {label: row.bankAccountName, value: row.bankAccountID},
      party: {label: row.partyAccountName, value: row.partyAccountID},
      remark: row.narration,
      errShow: true
    });
  }, []);

  const handleDelete = useCallback((row) => {
    setModal(true);
    setBookInfo({...bookInfo, id: row.id});
  }, []);

  return (
    <>
      <div className='page-content transactions-table-width'>
        <Container fluid={true}>
          <Breadcrumbs
            title='Transactions - account-books'
            breadcrumbItem='Bankbook
'
          />

          <Card>
            <CardBody>
              <Row className='mb-4 '>
                <Col sm='auto' className='mb-3'>
                  <Label> Type</Label>

                  <Nav pills className='navtab-bg nav-justified border mx-1 '>
                    <NavItem className={type == 0 ? 'bg-primary rounded ' : 'rounded'} onClick={() => setType(0)}>
                      <NavLink>
                        <span className={type == 0 ? 'text-white cursor' : 'cursor'}>All</span>
                      </NavLink>
                    </NavItem>

                    <NavItem className={type == 'I' ? 'bg-warning rounded ' : 'rounded'} onClick={() => setType('I')}>
                      <NavLink>
                        <span className={type == 'I' ? 'text-white cursor' : 'cursor'}>Inward</span>
                      </NavLink>
                    </NavItem>
                    <NavItem className={type == 'O' ? 'bg-info rounded ' : 'rounded'} onClick={() => setType('O')}>
                      <NavLink>
                        <span className={type == 'O' ? 'text-white cursor' : 'cursor'}>Outward</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
                <Col sm='auto' className='mb-3'>
                  <Label> Date</Label>

                  <Flatpickr
                    className='form-control customWidthBank'
                    options={{
                      mode: 'single',
                      disableMobile: true,
                      dateFormat: 'd/m/Y'
                    }}
                    value={date}
                    onChange={(e) => handleDate(e)}
                  />
                </Col>
                <Col sm='auto' className='mb-3'>
                  <Label>Amount</Label>
                  <input
                    className='form-control customWidthBank'
                    type='number'
                    placeholder='Amount'
                    ref={fp}
                    value={bookInfo.amount}
                    onBlur={handleBlurAmt}
                    onChange={(e) => handleAmount(e.target.value)}
                  />
                  {bookInfo.errShow ? <span className='text-error'> {errorMsg?.amount}</span> : <></>}
                </Col>
                <Col sm='auto' className='mb-3'>
                  <Label>Bank Account</Label>
                  <Select
                    placeholder='Bank Account'
                    className='w185'
                    // options={accResult?.accByBank}
                    options={accResult?.accounts}
                    defaultValue={bookInfo?.sender}
                    value={
                      accResult?.accounts && bookInfo?.sender?.value
                        ? accResult?.accounts.find((option) => option.id == bookInfo?.sender?.value)
                        : bookInfo?.sender
                    }
                    openMenuOnFocus={true}
                    noOptionsMessage={() => {
                      return (
                        <Button color='primary' onClick={() => tog_invoice(false)}>
                          <div className='row0'>
                            <i className='bx bx-plus  font-size-18 ' /> Bank Account
                          </div>{' '}
                        </Button>
                      );
                    }}
                    onChange={(e) => {
                      setBookInfo({...bookInfo, sender: e, amount: bookInfo.amount ? bookInfo.amount : '0.000'});
                    }}
                  />
                  {bookInfo.errShow ? <span className='text-error'> {errorMsg?.sender}</span> : <></>}
                </Col>
                <Col sm='auto' className='mb-3'>
                  <Label>Party Account</Label>
                  <Select
                    placeholder='Party Account'
                    className=' w185'
                    options={accResult?.accounts}
                    openMenuOnFocus={true}
                    defaultValue={bookInfo?.party}
                    value={
                      accResult?.accounts && bookInfo?.party?.value
                        ? accResult?.accounts.find((option) => option.id == bookInfo?.party?.value)
                        : bookInfo?.party
                    }
                    noOptionsMessage={() => {
                      return (
                        <Button color='primary' onClick={() => tog_invoice(true)}>
                          <div className='row0'>
                            <i className='bx bx-plus  font-size-18 ' /> Party Account
                          </div>
                        </Button>
                      );
                    }}
                    onChange={(e) => setBookInfo({...bookInfo, party: e})}
                  />
                  {bookInfo.errShow ? <span className='text-error'> {errorMsg?.party}</span> : <></>}
                </Col>

                <Col sm='auto' className='mb-3'>
                  <Label>UTR</Label>
                  <Input
                    placeholder='UTR'
                    className='customWidthBank'
                    value={bookInfo.remark}
                    onChange={(e) => setBookInfo({...bookInfo, remark: e.target.value})}
                  />
                </Col>

                <Col lg='auto' className='mb-3'>
                  <div className='mt30'>
                    {bankLock && rowLock && !tempUnlock && type != 0 ? (
                      <Button color='primary' className='mx-1 ' onClick={(e) => handleOpenUnlock(e, 'row')}>
                        <i className='bx bx-lock  font-size-20'></i>{' '}
                      </Button>
                    ) : (
                      type != 0 && (
                        <Button color='primary' onClick={handleSaveBook} className='mx-1 '>
                          {bookInfo.id ? <i className='bx bx-pencil  font-size-20'></i> : <i className='bx bx-plus  font-size-20 '></i>}
                        </Button>
                      )
                    )}

                    {/* {type == 0 ? (
                      <></>
                    ) : (
                      <>
                        {' '}
                        <Button color='primary' onClick={handleSaveBook} className='mx-1 '>
                          {bookInfo.id ? <i className='bx bx-pencil  font-size-20'></i> : <i className='bx bx-plus  font-size-20 '></i>}
                        </Button>{' '}
                      </>
                    )} */}

                    <Button color='primary' className='mx-1' onClick={() => onclearValue()}>
                      <i className='bx bx-reset font-size-20'></i>
                    </Button>

                    {type != 0 && (
                      <Button color='primary' className='mx-1' onClick={handleConfig}>
                        Commission
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>

              <div className='row2 mb-3'>
                <div className='row0'>
                  <Select
                    className='mx-1 w150'
                    placeholder='Party Account'
                    options={[{label: 'All', value: 0}, ...accResult?.accounts]}
                    openMenuOnFocus={true}
                    value={party2}
                    onChange={(e) => setParty2(e)}
                    // noOptionsMessage={() => {
                    //   return (
                    //     <Button color='primary' onClick={() => tog_invoice(true)}>
                    //       <div className='row0'>
                    //         <i className='bx bx-plus  font-size-18 ' /> Party Account
                    //       </div>{' '}
                    //     </Button>
                    //   );
                    // }}
                  />
                  <div className=' mx-1'>
                    <input
                      placeholder={`(${filteredData?.length}) Records...`}
                      className='form-control searchBar'
                      value={search}
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                  </div>
                  {filteredData?.length > 0 ? (
                    <div className='ms-auto '>
                      {' '}
                      <Button
                        className='mx-1'
                        onClick={() => {
                          ondownloadClick('pdf');
                        }}
                      >
                        {' '}
                        PDF
                      </Button>
                      <Button
                        className='mx-1'
                        onClick={() => {
                          ondownloadClick('csv');
                        }}
                      >
                        {' '}
                        CSV
                      </Button>
                      <Button className='mx-1' onClick={downloadScreenshot}>
                        <i className='bx bx-screenshot font-size-20'></i>
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {bankStatus ? (
                  <div className='row0 '>
                    <Nav pills className='navtab-bg nav-justified border mx-1 '>
                      <NavItem className={filter == 0 ? 'bg-primary rounded ' : 'rounded'} onClick={() => setFilter(0)}>
                        <NavLink>
                          <span className={filter == 0 ? 'text-white cursor' : 'cursor'}>All</span>
                        </NavLink>
                      </NavItem>
                      <NavItem className={filter == 2 ? 'bg-success  rounded ' : 'rounded'} onClick={() => setFilter(2)}>
                        <NavLink>
                          <span className={filter == 2 ? 'text-white cursor' : 'cursor'}>Approved</span>
                        </NavLink>
                      </NavItem>
                      <NavItem className={filter == 1 ? 'bg-warning rounded ' : 'rounded'} onClick={() => setFilter(1)}>
                        <NavLink>
                          <span className={filter == 1 ? 'text-white cursor' : 'cursor'}>Pending</span>
                        </NavLink>
                      </NavItem>

                      <NavItem className={filter == 3 ? 'bg-danger rounded ' : 'rounded'} onClick={() => setFilter(3)}>
                        <NavLink>
                          <span className={filter == 3 ? 'text-white cursor' : 'cursor'}>Deleted</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>

              <div id='table-invoices-list' ref={ref}>
                {filteredData?.length > 0 ? (
                  <BB_Table
                    filter={filter}
                    type={type}
                    bbres={br}
                    tableData={fd}
                    isImage={isImage}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    handleOpenUnlock={handleOpenUnlock}
                    tableLock={tableLock}
                    bankLock={bankLock}
                    bankStatus={bankStatus}
                  />
                ) : (
                  <div className='text-center mt-5 mb-4 noDAtaA'>No Data Available</div>
                )}
              </div>
              <div className='mt-3 text-end'></div>
            </CardBody>
          </Card>
        </Container>
        <div className='whiteBox2'></div>
      </div>

      <>
        <Modal isOpen={modal} toggle={toggle} {...args}>
          <ModalHeader toggle={toggle}>Delete Bankbook </ModalHeader>
          <ModalBody>Are you sure to delete this Bankbook ?</ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={() => handleConfirmDelete()}>
              Delete
            </Button>
            <Button color='secondary' onClick={() => toggle()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <TransactionPassword
          modalpassword={unlockModal}
          togglePassword={(val) => toggleUnlockModal(val)}
          bankbook={true}
          compType='BankBook'
          lockType={lockType}
          handleUnlock={handleUnlock}
        />

        <AccountModal
          isParty={party}
          modal_invoice={modal_invoice}
          tog_invoice={() => tog_invoice()}
          setmodal_invoice={() => setmodal_invoice()}
        />

        <Comm
          party2={party2}
          modalConfig={modalConfig}
          togModalConfig={togModalConfig}
          onclearValue={onclearValue}
          date={date}
          bookInfo={bookInfo}
          type={type}
        />
      </>
    </>
  );
};
export default BankBook;
