import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

function DeleteCity(props) {
  return (
    <>
      <Modal isOpen={props?.deleteModal} onClosed={props.setDeleteModal} toggle={props.toggleDeleteModal}>
        <ModalHeader toggle={props?.setDeleteModal}>Delete City </ModalHeader>

        <ModalBody>Are you sure want to delete this city ?</ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={props?.handleConfirmDelete}>
            Delete
          </Button>{' '}
          <Button color='secondary' onClick={props?.setDeleteModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default DeleteCity;
