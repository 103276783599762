import React, {useEffect, useState} from 'react';
import {Modal} from 'reactstrap';
import TableContainer from '../../components/Common/ReportWithoutPagination';
import {useDispatch, useSelector} from 'react-redux';
import {getLeaderDAta} from '../../store/actions';
import moment from 'moment';

const LeaderReport = (args) => {
  const dispatch = useDispatch();

  const {leaderData} = useSelector((state) => ({
    leaderData: state.transjaction.leaderData
  }));

  const columns = [
    {
      Header: 'Tran No',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.tranNo}</div>;
      },
      Filter: false
    },
    {
      Header: 'Date',
      Cell: (cell) => {
        return <div>{moment(cell?.cell?.row?.original.tranDate).format('DD/MM/YYYY')}</div>;
      },
      Filter: false
    },
    {
      Header: 'Account',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.account}</div>;
      },
      Filter: false
    },
    {
      Header: 'Reference Account',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.refAccount}</div>;
      },
      Filter: false
    },
    {
      Header: 'Commission Amount',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.commAmount}</div>;
      },
      Filter: false
    },
    {
      Header: 'Debit Amount',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.drAmount}</div>;
      },
      Filter: false
    },
    {
      Header: 'Credit Amount',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.crAmount}</div>;
      },
      Filter: false
    },
    {
      Header: 'Narration',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.naration}</div>;
      },
      Filter: false
    }
  ];
  useEffect(() => {
    dispatch(getLeaderDAta(args.leaderId, 'TJV'));
  }, [args?.leaderId]);

  return (
    <Modal
      size='xl'
      isOpen={args?.leaderReport}
      toggle={() => {
        args?.handleChangeLeaderClose();
      }}
      className='addInvoiceModal'
      centered
    >
      <div className='modal-header'>
        <h5 className='modal-title' id='addInvoiceModalLabel'>
          Ledger Entries
        </h5>
        <button
          onClick={() => {
            args?.handleChangeLeaderClose();
          }}
          type='button'
          className='btn-close'
          aria-label='Close'
        ></button>
      </div>
      <div className='modal-body p-4'>
        <TableContainer
          columns={columns}
          data={leaderData.length ? leaderData : []}
          isGlobalFilter={true}
          customPageSize={20}
          className='Invoice table'
        />
      </div>
    </Modal>
  );
};

export default LeaderReport;
