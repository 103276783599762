import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import rootSaga from './sagas';

import rootReducer from './RootReducer';
import createSagaMiddleware from 'redux-saga';
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = [thunk, sagaMiddleware];

export function configureStore(initialState) {
  const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)));
  sagaMiddleware.run(rootSaga);

  return store;
}
