import axios from 'axios';
import {toast} from 'react-toastify';
import {PRODUCTS} from '../apiRoutes';
import {
  POST_PRODUCT_SAVE,
  POST_PRODUCT_DELETE,
  GET_PRODUCT_LIST,
  GET_PRODUCT_LIST_ACTIVE,
  GET_PRODUCT_RATE_PRODID,
  PRODUCT_ERROR
} from './actionTypes';

import {errorHandlers} from './../../helpers/errorHandlers';
import {loaderStart, loaderStop} from '../actions';

export const getProductListActive = () => async (dispatch) => {
  dispatch(loaderStart());
  axios
    .get(PRODUCTS + 'GetActiveList')
    .then((response) => {
      response.map((a) => {
        a.id = a?.id;
        a.value = a?.productName;
        a.label = (
          <>
            <div>
              {a.productName} ({a.availStock})
            </div>
          </>
        );
      });
      dispatch(loaderStop());
      dispatch({
        type: GET_PRODUCT_LIST_ACTIVE,
        payload: response
      });
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
      dispatch({
        type: PRODUCT_ERROR,
        payload: error?.response?.data?.errorMessage || 'Login Failed Please try again'
      });
    });
};

export const getRateByProdId = (prodId) => async (dispatch) => {
  dispatch(loaderStart());
  axios
    .get(PRODUCTS + '/GetRateByProductID/' + '?productID=' + prodId)
    .then((response) => {
      dispatch(loaderStop());
      dispatch({
        type: GET_PRODUCT_RATE_PRODID,
        payload: response
      });
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
      dispatch({
        type: PRODUCT_ERROR,
        payload: error?.response?.data?.errorMessage || 'Login Failed Please try again'
      });
    });
};

// export const saveAccountGroup = (accGroup) => async (dispatch) => {
//   dispatch(loaderStart());
//   dispatch({
//     type: ACCOUNTGROUP_SAVE_START,
//     payload: "",
//   });
//   const data = {
//     groupName: accGroup.groupName,
//     parentGroupName: accGroup.parent.label,
//     parentGroupID: accGroup.parent.value,
//   };
//   if (accGroup.recId) {
//     data.id = accGroup.recId;
//   }
//   axios
//     .post("AccountGroup/SaveAccountGroup", data)
//     .then((response) => {
//       if (response == 1 && accGroup.recId) {
//         toast.success("Account Edited Successfully");
//       } else if (response == 1) {
//         toast.success("Account Added Successfully");
//       } else {
//         toast.error("Action can't be performed");
//       }

//       dispatch(loaderStop());
//       dispatch({
//         type: ACCOUNTGROUP_SAVE_SUCCESS,
//         payload: response,
//       });
//     })
//     .catch((error) => {
//       errorHandlers(error);
//       dispatch(loaderStop());
//       dispatch({
//         type: ACCOUNTGROUP_SAVE_ERROR,
//         payload:
//           error?.response?.data?.errorMessage ||
//           "Login Failed Please try again",
//       });
//     });
// };

// export const deleteAccountGroup = (recId) => async (dispatch) => {
//   axios
//     .post("AccountGroup/DeleteAccountGroup/" + recId)
//     .then((response) => {

//       dispatch({
//         type: ACCOUNTGROUP_DELETE_SUCCESS,
//         payload: response,
//       });
//     })
//     .catch((error) => {
//       errorHandlers(error);
//       dispatch(loaderStop());
//       dispatch({
//         type: ACCOUNTGROUP_DELETE_ERROR,
//         payload:
//           error?.response?.data?.errorMessage ||
//           "Login Failed Please try again",
//       });
//     });
// };
