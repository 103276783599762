import React, {useEffect, useState} from 'react';
import {Row, Col, Input, Button, Nav, NavItem, NavLink} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';
import {useDispatch, useSelector} from 'react-redux';
import {getPartyStockList, downloadPartyStockList} from '../../../store/PartyStock/actions';
import {getAccounts} from '../../../store/Accounts/actions';
import {getProductListActive} from '../../../store/Products/actions';
import moment from 'moment';
import {toast} from 'react-toastify';
import {PARTYSTOCK_REMOVE_DATA} from '../../../store/PartyStock/actionTypes';
const PartyStockOperations = () => {
  const dispatch = useDispatch();
  const productListResult = useSelector((state) => state.productReducer);
  const accResult = useSelector((state) => state.accounts);
  const [activeTab2, setactiveTab2] = useState('');

  const [account, setAccount] = useState([]);
  const [product, setProduct] = useState([]);
  const [fileName, setFileName] = useState();
  document.title = 'Party stock - account-books';

  let prevDate = moment().subtract(6, 'day');
  const [date, setDate] = useState([prevDate._d, new Date()]);

  useEffect(() => {
    handleReload();
    return () => {
      dispatch({
        type: PARTYSTOCK_REMOVE_DATA
      });
    };
  }, []);
  const toggle2 = async (tab) => {
    if (!account?.value) {
      toast.info('Please Select Account');
      return;
    }
    if (!product?.value) {
      toast.info('Please Select product');
      return;
    }
    await setactiveTab2(tab);
    handleGetReport(date, product, account, tab);
  };
  const handleReload = (getList) => {
    dispatch(getAccounts());
    dispatch(getProductListActive());

    if (getList) {
      handleGetReport(date, product, account, activeTab2);
    }
  };

  const handleAccount = (e) => {
    setAccount(e);
    if (!product?.id) {
      toast.info('please select the product');
      return;
    }
    handleGetReport(date, product, e, activeTab2);
  };

  const handleProduct = (e) => {
    setProduct(e);
    if (!account?.value) {
      toast.info('Please Select Account');
      return;
    }
    handleGetReport(date, e, account, activeTab2);
  };

  const handleDate = (e) => {
    setDate(e);
    if (e.length > 1) {
      if (!account?.value) {
        toast.info('Please Select Account');
        return;
      }
      handleGetReport(e, product, account, activeTab2);
    }
  };

  const handleDateClose = (e) => {
    if (date.length != 2) {
      let defDate = [e[0], e[0]];
      setDate(defDate);
      if (!product?.value) {
        toast.info('Please select the product');
        return;
      }
      if (!account?.value) {
        toast.info('Please select the account');
        return;
      }
      handleGetReport(defDate, product, account, activeTab2);
    }
  };
  const handleGetReport = (date, product, account, tab) => {
    dispatch(getPartyStockList(date, product.id, account.value, tab));
  };

  const ondownloadClick = (type) => {
    if (!product?.productName) {
      toast.info('Please select product');
    } else if (!account?.accountName) {
      toast.info('Please select account');
    } else {
      dispatch(
        downloadPartyStockList(date, account?.id, fileName, type, product.id, activeTab2, account?.accountName, product?.productName)
      );
      setFileName('');
    }
  };

  return (
    <>
      <div className='position-relative'>
        <div className='mt-2'>
          <Row>
            <Col md='3' className='mb-3'>
              <div className='d-flex'>
                <Input
                  className='me-2 w-75'
                  type='text'
                  value={fileName}
                  placeholder='Enter export file name...'
                  onChange={(e) => setFileName(e?.target?.value)}
                />
                <Button onClick={() => ondownloadClick('pdf')}>PDF</Button> &nbsp;
                <Button onClick={() => ondownloadClick('csv')}>CSV</Button> &nbsp;
                <Button
                  style={{
                    background: 'none',
                    color: '#495057',
                    border: '1px solid #eff0f2'
                  }}
                  onClick={() => handleReload(true)}
                  disabled={product?.id && account?.value ? false : true}
                >
                  <span className='bx bx-revision font-size-18'></span>
                </Button>{' '}
              </div>
            </Col>
            <Col md='4'>
              <div className='row'>
                <div className='col-md-7'>
                  <Flatpickr
                    className='form-control d-block mb-3'
                    placeholder='Date Range'
                    value={date}
                    options={{
                      mode: 'range',
                      disableMobile: true,
                      dateFormat: 'd/m/Y '
                    }}
                    onChange={(e) => handleDate(e)}
                    onClose={handleDateClose}
                  />
                </div>
                <div className='col-md-5'>
                  <Select
                    className='mb-3'
                    placeholder='Product'
                    openMenuOnFocus={true}
                    options={productListResult?.productList}
                    value={product}
                    onChange={(e) => handleProduct(e)}
                  />
                </div>
              </div>
            </Col>
            <Col md='2' className='mb-3'>
              <Select
                placeholder='Account'
                options={accResult?.accounts}
                openMenuOnFocus={true}
                value={account}
                onChange={(e) => handleAccount(e)}
              />
            </Col>
            <Col>
              <div className='transactions-pdf-right'>
                <Nav pills className='navtab-bg nav-justified'>
                  <NavItem className={activeTab2 == '' && 'bg-primary rounded'}>
                    <NavLink
                      className='cursor'
                      // className={classnames({
                      // 	active: activeTab2 === "1",
                      // })}
                      onClick={() => {
                        toggle2('');
                      }}
                    >
                      {/* <span className='d-block d-sm-none'>
                        <i className='fas fa-home'></i>
                      </span> */}
                      <span className={activeTab2 == '' && 'text-white'}>Both</span>
                    </NavLink>
                  </NavItem>
                  <NavItem className={activeTab2 === '0' && 'bg-success rounded'}>
                    <NavLink
                      className='cursor'
                      // className={classnames({
                      // 	active: activeTab2 === "2",
                      // })}
                      onClick={() => {
                        toggle2('0');
                      }}
                    >
                      {/* <span className='d-block d-sm-none'>
                        <i className='far fa-user'></i>
                      </span> */}
                      <span className={activeTab2 == '0' && 'text-white'}>Fixed</span>
                    </NavLink>
                  </NavItem>
                  <NavItem className={activeTab2 === '1' && 'bg-warning rounded'}>
                    <NavLink
                      className='cursor'
                      // className={classnames({
                      // 	active: activeTab2 === "3",
                      // })}
                      onClick={() => {
                        toggle2('1');
                      }}
                    >
                      {/* <span className='d-block d-sm-none'>
                        <i className='far fa-envelope'></i>
                      </span> */}
                      <span className={activeTab2 == '1' && 'text-white'}>UnFixed</span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default PartyStockOperations;
