import axios from 'axios';
import {toast} from 'react-toastify';
import {SALES} from '../apiRoutes';
import {GET_SALES_LIST, POST_SALES_SAVE, POST_SALES_DELETE, GET_SALES_ID, GET_SALES_PENDING_ITEMS, SALES_ERROR} from './actionTypes';
import moment from 'moment';

import {errorHandlers} from './../../helpers/errorHandlers';
import {loaderStart, loaderStop} from '../actions';
import {useNavigate} from 'react-router-dom';

export const getSalesList = (date, customerId, heading) => async (dispatch) => {
  dispatch(loaderStart());

  let customer = '';

  if (customerId) {
    customer = customerId;
  } else {
    customer = '';
  }

  const date1 = moment(date[0]).format('YYYY-MM-DD');
  const date2 = moment(date[1]).format('YYYY-MM-DD');
  let key = '';
  let sType = 0;

  if (heading?.includes('Currency')) {
    sType = 2;
  } else {
    sType = 1;
  }

  if (heading?.includes('Invoice')) {
    key = 'Invoice';
  } else {
    key = 'Order';
  }

  axios
    .get(SALES + key + '/' + 'GetList' + '?fromDate=' + date1 + '&toDate=' + date2 + '&customerID=' + customer + '&saleType=' + sType)
    .then((res) => {
      let productList = [];

      res.map((a) => {
        if (a.salesInvoiceList) {
          productList = a.salesInvoiceList.map((b) => {
            b.product = b.product;
            return b;
          });
        }

        if (a.salesOrderDetailList) {
          productList = a.salesOrderDetailList;
        }
        a.customer = a.customer;
        a.heading = heading;
        a.date = moment(a.soDate || a.siDate).format('DD/MM/YYYY');
        a.orderNo = a?.siNumber || a?.soNumber;
        a.productList = productList;
      });
      dispatch(loaderStop());
      dispatch({
        type: GET_SALES_LIST,
        payload: res
      });
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
      dispatch({
        type: SALES_ERROR,
        payload: error?.response?.data?.errorMessage || 'Login Failed Please try again'
      });
    });
};

export const saveSales = (rows, salesInfo, navigate, delList, heading) => async (dispatch) => {
  dispatch(loaderStart());
  let sType = 0;

  if (heading?.includes('Currency')) {
    sType = 2;
  } else {
    sType = 1;
  }

  let key = '',
    path = '',
    data = [],
    productList = [];

  let del = delList.map((a) => {
    let obj = {
      id: a.id,
      productID: a.product?.id,
      qty: a.qty,
      rate: a.rate,
      amount: a.amount,
      isHold: a.fix,
      operationToPerform: 3
    };

    if (heading?.includes('Invoice')) {
      obj.oldQty = 0;
      obj.product = a.product?.label;
    } else {
      obj.productName = a.product?.label;
    }

    if (a.soid || a.soid == 0) {
      obj.soid = a.soid;
    }
    if (a.siid || a.siid == 0) {
      obj.siid = a.siid;
    }
    if (a.sodid || a.sodid == 0) {
      obj.sodid = a.sodid;
    }
    return obj;
  });

  productList = rows.map((a) => {
    let obj = {
      qty: a.qty,
      rate: Number(a.rate),
      amount: Number(a.amount),
      isHold: a.fix,
      productID: a.product?.id
    };

    if (heading?.includes('Invoice')) {
      obj.oldQty = a.prevQty || a.oldQty || 0;
      obj.product = a.product?.label;
    }

    if (heading?.includes('Order')) {
      obj.productName = a.product?.label;
    }
    if (a.id || a.id == 0) {
      obj.id = a.id;
    }
    if (a.newItem || a.isPull) {
      obj.operationToPerform = 1;
    } else {
      if (a.amount == a.amount2) {
        obj.operationToPerform = 0;
      } else {
        obj.operationToPerform = 2;
      }
    }

    if (a.soid || a.soid == 0) {
      obj.soid = a.soid;
    }
    if (a.siid || a.siid == 0) {
      obj.siid = a.siid;
    }
    if (a.sodid || a.sodid == 0) {
      obj.sodid = a.sodid;
    }

    return obj;
  });
  productList.push(...del);

  if (salesInfo.id) {
    productList.map((a) => {
      if (heading?.includes('Order') && a.id != 0) {
        a.soid = salesInfo.id;
      }
    });
  }

  data = {
    id: salesInfo.id,
    saleType: sType,
    customerID: salesInfo?.customer?.value,
    customer: salesInfo.customer?.label,
    totalAmount: salesInfo.total,
    salesAccountID: salesInfo.account?.value,
    salesAccountName: salesInfo.account?.label,
    operationToPerform: salesInfo.id ? 2 : 1
  };

  if (heading?.includes('Invoice')) {
    data.salesInvoiceList = productList;
    data.siDate = salesInfo.date1;
    data.siNumber = salesInfo.nextSO;

    key = 'SalesInvoice';

    if (sType == 2) {
      path = '/sales/currency/invoice';
    } else {
      path = '/sales/metal/invoice';
    }
  } else if (heading?.includes('Order')) {
    data.salesOrderDetailList = productList;
    data.soDate = salesInfo.date1;
    data.soNumber = salesInfo.nextSO;

    key = 'SalesOrder';
    if (sType == 2) {
      path = '/sales/currency/order';
    } else {
      path = '/sales/metal/order';
    }
  }

  const url = '/' + key + '/Save' + key;

  axios
    .post(url, data)
    .then((response) => {
      if (salesInfo.id) {
        toast.success(heading?.split('Sales')[1] + ' updated successfully');
        navigate(path);
      } else {
        toast.success(response?.errorMessage, {autoClose: 2000});
        navigate(path);
      }

      dispatch(loaderStop());
      dispatch({
        type: POST_SALES_SAVE,
        payload: {value: salesInfo.customer?.value, label: salesInfo.customer?.label}
      });
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};

export const deleteSales = (salesInfo, heading) => async (dispatch) => {
  dispatch(loaderStart());
  let key = '';
  if (heading?.includes('Invoice')) {
    key = 'Invoice';
  } else {
    key = 'Order';
  }

  const url = SALES + key + '/Delete' + SALES + key + '/' + salesInfo.id;

  axios
    .post(url)
    .then((response) => {
      if (response == 1) {
        toast.success(heading?.split('Sales')[1] + ' deleted successfully');
      } else {
        toast.error("Action Can't be Performed");
      }

      dispatch(loaderStop());
      dispatch({
        type: POST_SALES_DELETE,
        payload: response
      });
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};

export const getPendingSalesItems = (custId, heading) => async (dispatch) => {
  dispatch(loaderStart());

  let sType = 0;

  if (heading?.includes('Currency')) {
    sType = 2;
  } else {
    sType = 1;
  }

  const url = '/SalesInvoice/GetPendingForSalesInvoiceItems' + '?CustomerID=' + custId + '&SaleType=' + sType;

  axios
    .get(url)
    .then((res) => {
      dispatch(loaderStop());

      res.map((a) => {
        a.date = moment(a.soDate).format('DD/MM/YYYY');
        a.isCheck = false;
        a.added = false;

        if (!a.isHold) {
          a.amount = (a.penForInvQty * a.soRate) / 1000;
        }
      });

      dispatch({
        type: GET_SALES_PENDING_ITEMS,
        payload: res
      });
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};
