import {GET_ALL_CITY} from './actionTypes';

const initialState = {
  city: ''
};

const ledgerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CITY:
      state = {
        ...state,
        city: action.payload
      };
      break;

    default:
      state = {...state};
      break;
  }
  return state;
};

export default ledgerReducer;
