import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {Col, Input, Row, Table} from 'reactstrap';

const JournalsList = (props) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandState, setExpandState] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(props.listData);
    setSearchTerm('');
  }, [props.listData]);

  const handleEpandRow = (event, userId) => {
    const isRowExpanded = expandedRows.includes(userId);

    let obj = {};
    if (isRowExpanded) {
      obj[userId] = false;
      setExpandState(obj);

      const newExpandedRows = expandedRows.filter((id) => id !== userId);
      setExpandedRows(newExpandedRows);
    } else {
      obj[userId] = true;
      setExpandState(obj);

      const newExpandedRows = [userId];
      setExpandedRows(newExpandedRows);
    }
  };
  const data = props.listData;

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);

    const filteredData = data.filter((item) => {
      return (
        item.jvNo.toString()?.includes(searchTerm) ||
        item.tcAmount.toString()?.includes(searchTerm) ||
        item.jvDate?.includes(searchTerm) ||
        item.narration.toLowerCase()?.includes(searchTerm.toLowerCase())
      );
    });

    setFilteredData(filteredData);
  };

  return (
    <React.Fragment>
      {props?.listData && props?.listData.length > 0 ? (
        <div className='mt-2'>
          <Row>
            <Col sm={2}>
              <Input
                type='text'
                placeholder={`${data.length} Search...`}
                value={searchTerm}
                onChange={handleSearch}
                className='list-search-input'
              />
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col sm={12}>
              <Table
                responsive
                variant='dark'
                className='
              table-bordered'
              >
                <thead>
                  <tr style={{background: '#f5f6f8'}}>
                    <th></th>
                    <th>Voucher No.</th>
                    <th>Date </th>
                    <th>Amount </th>
                    <th>Narration </th>
                    <th>Actions </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 ? (
                    filteredData.map((user) => (
                      <>
                        <tr key={user.id}>
                          <td style={{width: '5px'}}>
                            <div className='cursor' onClick={(event) => handleEpandRow(event, user.id)}>
                              {expandState[user.id] ? (
                                <i className='fas fa-chevron-circle-down font-size-18'></i>
                              ) : (
                                <i className='fas fa-chevron-circle-right font-size-18'></i>
                              )}
                            </div>
                          </td>

                          <td>{user.jvNo}</td>
                          <td>{moment(user.jvDate).format('DD/MM/YYYY')}</td>
                          <td>{user.tcAmount}</td>
                          <td>{user.narration}</td>
                          <td className='jornalTable'>
                            <span
                              className='cursor text-primary'
                              onClick={() => {
                                props?.onEditClick(user);
                              }}
                            >
                              <i className='bx bx-pencil font-size-18 me-1'></i>
                            </span>
                            <span
                              className='cursor text-danger'
                              onClick={() => {
                                props?.ondeleteClick(user);
                              }}
                            >
                              {' '}
                              <i className='bx bx-trash-alt font-size-18'></i>
                            </span>
                          </td>
                        </tr>
                        <>
                          {expandedRows.includes(user.id) ? (
                            <tr>
                              <td colspan='6'>
                                <div
                                  style={{
                                    backgroundColor: 'white',
                                    color: '#FFF'
                                  }}
                                >
                                  <Table bordered size='sm' className='m-0'>
                                    <thead style={{background: '#f5f6f8'}}>
                                      <tr>
                                        <td>Account Name</td>
                                        <th>Amount</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {user.journalDetailsList.map((data) => (
                                        <tr key={data.id}>
                                          <td> {data.accountName} </td>
                                          <td>
                                            {' '}
                                            {data.amount} {data.cdMode == 'C' ? 'Cr' : 'Dr'}{' '}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </div>
                              </td>
                            </tr>
                          ) : null}
                        </>
                      </>
                    ))
                  ) : (
                    <>
                      <tr>
                        <td className='text-center m-5' colSpan={6}>
                          No Data Available
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      ) : (
        <>
          <div className='text-center mt-5 pt-3'>No Data Available</div>
        </>
      )}
    </React.Fragment>
  );
};

export default JournalsList;
