import React from 'react';

import TableContainer from '../../../../components/Common/TableContainer';

const AccountGroupsTable = (props) => {
  const columns = [
    {
      Header: 'Account Code',
      accessor: 'accountCode',
      Filter: true
    },
    {
      Header: 'Account Name',
      accessor: 'accountName',
      Filter: true
    },
    {
      Header: 'Group Name ',
      accessor: 'groupName',
      Filter: true
    },
    {
      Header: 'Opening Balance',
      Cell: (cell) => {
        return (
          <>
            <span>
              {cell?.cell?.row?.original?.openingBalance} {cell?.cell?.row?.original?.tranType === 'C' ? 'Cr' : 'Dr'}
            </span>
          </>
        );
      }
    },
    {
      Header: 'Commission',
      accessor: 'commission',
      Filter: true
    },
    {
      Header: 'Notes',
      accessor: 'notes',
      Filter: true
    },
    {
      Header: 'Action',
      Cell: (cell) => {
        return (
          <>
            <span
              className='cursor text-primary'
              onClick={() => {
                props?.onEditClick(cell?.cell?.row?.original);
              }}
            >
              <i className='bx bx-pencil  text-primary  font-size-18'></i>
            </span>
            <span
              className='cursor text-danger'
              onClick={() => {
                props?.ondeleteClick(cell?.cell?.row?.original);
              }}
            >
              {' '}
              <i className='bx bx-trash-alt text-danger font-size-18'></i>
            </span>
          </>
        );
      }
    }
  ];
  return (
    <React.Fragment>
      <TableContainer columns={columns} data={props.tableData} isGlobalFilter={true} customPageSize={20} className='Invoice table' />
    </React.Fragment>
  );
};

export default AccountGroupsTable;
