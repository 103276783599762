import {combineReducers} from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication
import login from './auth/login/reducer';
import profile from './auth/profile/reducer';
import accounts from './Accounts/reducer';
import usersList from './users/userTable/reducer';
import accountsGroupReducer from './AccountGroup/reducer';
import trialBalReducer from './TrialBalance/reducer';
import ledgerReducer from './Ledger/reducer';
import common from './Common/reducer';
import companyConfiguration from './CompanyConfiguration/reducer';
import stockReducer from './Stock/reducer';
import partyStockReducer from './PartyStock/reducer';
import productReducer from './Products/reducer';
import transjaction from './Transjaction/reducer';
import journalsReducer from './journals/reducers';
import cashbook from './Cashbook/reducer';
import purchaseReducer from './Purchase/reducer';
import productsReducer from './MastersProducts/reducer';
import salesReducer from './Sales/reducer';
import city from './City/reducer';
import bankBookReducer from './BankBook/reducer';
import PLReducer from './ProfitLoss/reducer';

const rootReducer = combineReducers({
  // public
  Layout,
  login,
  profile,
  accounts,
  usersList,
  common,
  accountsGroupReducer,
  trialBalReducer,
  ledgerReducer,
  companyConfiguration,
  stockReducer,
  productReducer,
  partyStockReducer,
  transjaction,
  journalsReducer,
  cashbook,
  purchaseReducer,
  productsReducer,
  salesReducer,
  city,
  bankBookReducer,
  PLReducer,
});

export default rootReducer;
