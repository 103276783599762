import axios from 'axios';
import {loaderStart, loaderStop} from '../actions';
import {
  GET_CASHBOOK_DATA,
  ADD_CASHBOOK_DATA,
  DELETE_ACCOUNT_GROUP,
  DELETE_CASHBOOK_DATA,
  DOWNLOAD_CASHBOOK_DATA,
  APPROVE_CASHBOOK,
  DOWNLOAD_CASHBOOK_DATA_CSV
} from '../apiRoutes';
import {GET_CASHBOOK} from './actionTypes';
import {errorHandlers} from '../../helpers/errorHandlers';
import {toast} from 'react-toastify';
import moment from 'moment';

export const getCashbookData = (date, filter, type) => async (dispatch) => {
  let isDeleted = 0;
  let cashStatus = 0;
  if (filter == 0) {
    cashStatus = 0;
  } else if (filter == 1) {
    cashStatus = 1;
  } else if (filter == 2) {
    cashStatus = 2;
  } else if (filter == 3) {
    cashStatus = 0;
    isDeleted = 1;
  }
  let transType = 0;
  if (type == 'I') {
    transType = 1;
  } else if (type == 'O') {
    transType = 2;
  }

  dispatch(loaderStart());
  axios
    .post(GET_CASHBOOK_DATA + '?date=' + date + '&isDeleted=' + isDeleted + '&cashStatus=' + cashStatus + '&transType=' + transType, '')
    .then(async (response) => {
      await dispatch(loaderStop());
      await dispatch({
        type: GET_CASHBOOK,
        payload: response
      });
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};

export const addCashbook = (cashbookData, filter, onclearValue, type, fp, toggleTempUnlock) => async (dispatch) => {
  dispatch(loaderStart());
  axios
    .post(ADD_CASHBOOK_DATA, cashbookData)
    .then((response) => {
      dispatch(loaderStop());
      dispatch(getCashbookData(moment(cashbookData?.date).format('MM/DD/YYYY'), filter, type));
      if (onclearValue) onclearValue();
      fp?.current?.flatpickr?.altInput.focus();
      fp?.current?.flatpickr?.close();
      if (cashbookData?.id) {
        toggleTempUnlock();
        toast.success('Cashbook updated successfully');
      } else {
        toast.success('Cashbook added successfully');
      }
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};

export const ApproveCashbook = (row, filter, type) => async (dispatch) => {
  dispatch(loaderStart());
  axios
    .post(APPROVE_CASHBOOK + '?ID=' + row?.id)
    .then((res) => {
      dispatch(loaderStop());
      toast.success('Cashbook status changed successfully');

      dispatch(getCashbookData(moment(row?.date).format('MM/DD/YYYY'), filter, type));
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};

export const deleteCashBook = (data, filter, type) => async (dispatch) => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  dispatch(loaderStart());
  axios
    .post(DELETE_CASHBOOK_DATA + data?.id + '?userId=' + userData?.id)
    .then((res) => {
      dispatch(getCashbookData(moment(data?.date).format('MM/DD/YYYY'), filter, type));
      toast.info('Cashbook deleted successfully');
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};

export const downloadCashBookList = (date, type, filter, typedoc, cStatus) => async (dispatch) => {
  dispatch(loaderStart());
  let isDeleted = 0;
  let cashStatus = 0;
  if (filter == 0) {
    cashStatus = 0;
  } else if (filter == 1) {
    cashStatus = 1;
  } else if (filter == 2) {
    cashStatus = 2;
  } else if (filter == 3) {
    cashStatus = 0;
    isDeleted = 1;
  }

  let name = 'CashbookAccount';
  let types = 0;
  if (type == 'I') {
    name = 'InwardCashAccount';
    types = 1;
  } else if (type == 'O') {
    name = 'OutwardCashAccount';
    types = 2;
  }
  let url = DOWNLOAD_CASHBOOK_DATA;
  if (typedoc == 'csv') {
    url = DOWNLOAD_CASHBOOK_DATA_CSV;
  }
  let isStatusDis = cStatus ? 0 : 1;

  // let type1 = type == 'I' ? 1 : 0;
  axios
    .get(
      url +
        '?date=' +
        date +
        '&isDeleted=' +
        isDeleted +
        '&cashStatus=' +
        cashStatus +
        '&tranType=' +
        types +
        '&isStatusDisabled=' +
        isStatusDis,
      {
        responseType: 'blob'
      }
    )
    .then((response) => {
      dispatch(loaderStop());
      const url = URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = url;
      link.download = name + '.' + typedoc;
      link.click();
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};
