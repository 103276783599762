import React, {useEffect, useState} from 'react';
import {Row, Col, Container, Card, CardBody, Button, Input} from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import GroupTable from './TrialTable';
import {useDispatch, useSelector} from 'react-redux';
import TrialBalanceOperation from './trialBalanceOperation';

import LoaderDisplay from '../../../components/Common/loader';
const TrialBalance = () => {
  const dispatch = useDispatch();
  const result = useSelector((state) => state.trialBalReducer);

  document.title = 'Trial balance - account-books';

  return (
    <>
      <div className='page-content abcCdd'>
        <Container fluid={true}>
          <Breadcrumbs title='Trial Balance' breadcrumbItem='Trial Balance Report' />
          {result?.loading && <LoaderDisplay />}
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <TrialBalanceOperation />
                  <div id='table-invoices-list '>
                    <GroupTable tableData={result?.trialBal} />
                  </div>
                  {!result?.trialBal?.length && <div className='whiteBox'></div>}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TrialBalance;
