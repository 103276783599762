import axios from 'axios';
import {toast} from 'react-toastify';
import {JOURNALS_FETCH_SUCCESS, JOURNALS_FETCH_ERROR, JOURNALS_NUMBER, DELETE_JOURNAL} from './actionTypes';

import {errorHandlers} from './../../helpers/errorHandlers';
import {loaderStart, loaderStop} from '../actions';
import moment from 'moment';

export const getJournalsList = (date) => async (dispatch) => {
  dispatch(loaderStart());

  const date1 = moment(date[0]).format('YYYY-MM-DD');
  const date2 = moment(date[1]).format('YYYY-MM-DD');

  let q = '';
  if (date) {
    if (date?.length == 1) {
      q = `?fromDate=${date1}&toDate=${date1}`;
    } else {
      q = `?fromDate=${date1}&toDate=${date2}`;
    }
  }

  axios
    .get(`/journals/GetList${q}`)
    .then((response) => {
      dispatch(loaderStop());
      dispatch({
        type: JOURNALS_FETCH_SUCCESS,
        payload: response
      });
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
      dispatch({
        type: JOURNALS_FETCH_ERROR,
        payload: error?.response?.data?.errorMessage || 'Login Failed Please try again'
      });
    });
};

export const getNextJournalNumber = () => async (dispatch) => {
  axios
    .get(`/CompanyConfiguration/GetNextJVNo`)
    .then((response) => {
      dispatch({
        type: JOURNALS_NUMBER,
        payload: response
      });
    })
    .catch((error) => {
      errorHandlers(error);
    });
};

export const addJournals = (journalData, date) => async (dispatch) => {
  dispatch(loaderStart());
  axios
    .post('/Journals/SaveJournal', journalData)
    .then((response) => {
      dispatch(loaderStop());
      dispatch(getJournalsList(date));
      dispatch(getNextJournalNumber());
      if (journalData?.id) {
        toast.success('Journal Updated Successfully');
      } else {
        toast.success(response?.errorMessage, {autoClose: 2000});
      }
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};

export const deleteJournal = (id, date) => async (dispatch) => {
  dispatch(loaderStart());

  axios
    .post(`Journals/DeleteJournals/${id}`)
    .then((response) => {
      dispatch(loaderStop());
      dispatch(getJournalsList(date));
      toast.success('Journal Deleted Successfully');
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};
