import React, {useEffect, useState} from 'react';
import {Row, Col, Container, Modal, Card, CardBody, ModalBody, ModalFooter, Button, ModalHeader, Input, Table} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import axios from 'axios';

import Select from 'react-select';
import 'flatpickr/dist/themes/material_blue.css';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';
import moment from 'moment';

import {getDetailPL, getListPL} from '../../../store/ProfitLoss/actions';
import {loaderStart, loaderStop} from '../../../store/actions';

const ProfitLoss = () => {
  document.title = 'ProfitLoss' + ' - account-books';

  const navigate = useNavigate();
  const dispatch = useDispatch();
  let prevDate = moment().subtract(6, 'day');
  const [date, setDate] = useState([prevDate._d, new Date()]);
  const [leftList, setLeftList] = useState([]);
  const [rightList, setRightList] = useState([]);
  const [currindex, setindex] = useState([]);
  const [currindex2, setindex2] = useState([]);

  const PLList = useSelector((state) => state?.PLReducer?.PLList);

  useEffect(() => {
    date.length > 1 && dispatch(getListPL(date));
  }, [date]);

  useEffect(() => {
    if (PLList) {
      PLList?.profitLossLList?.map((a) => {
        a.groupName = a.names;
        a.isMain = true;

        if (a.names == 'Purchase Accounts') {
          a.accKey = 1;
        } else if (a.names == 'Direct Expenses') {
          a.accKey = 2;
        } else if (a.names == 'Indirect Expenses') {
          a.accKey = 3;
        } else if (a.names == 'Sales Accounts') {
          a.accKey = 4;
        } else if (a.names == 'Direct Incomes') {
          a.accKey = 5;
        } else if (a.names == 'Indirect Incomes') {
          a.accKey = 6;
        } else if (a.names == 'Opening Stock Value') {
          a.nonClick = true;
        } else if (a.names == 'Total') {
          a.nonClick = true;
        }
      });

      PLList?.profitLossRList?.map((a) => {
        a.groupName = a.names;
        a.isMain = true;

        if (a.names == 'Purchase Accounts') {
          a.accKey = 1;
        } else if (a.names == 'Direct Expenses') {
          a.accKey = 2;
        } else if (a.names == 'Indirect Expenses') {
          a.accKey = 3;
        } else if (a.names == 'Sales Accounts') {
          a.accKey = 4;
        } else if (a.names == 'Direct Incomes') {
          a.accKey = 5;
        } else if (a.names == 'Indirect Incomes') {
          a.accKey = 6;
        } else if (a.names == 'Opening Stock Value') {
          a.nonClick = true;
        } else if (a.names == 'Total') {
          a.nonClick = true;
        }
      });

      setLeftList(PLList?.profitLossLList);
      setRightList(PLList?.profitLossRList);
    }
  }, [PLList]);

  const handleDate = (e) => {
    let defDate = [prevDate._d, new Date()];
    if (e.length == 0) {
      setDate(defDate);
    } else {
      setDate(e);
    }
  };

  const handleDateClose = (e) => {
    if (date.length != 2) {
      let defDate = [e[0], e[0]];
      setDate(defDate);
    }
  };

  const TreeNode = ({node, index}) => {
    const {children, groupName, totalSumSales, amount, parentSumSales, level} = node;
    const [showChildren, setShowChildren] = useState(index == currindex && node?.isMain ? true : false);

    const handleClick = () => {
      let obj;
      if (node?.isMain) {
        obj = [...leftList];

        let PL_DETAIL = '/ProfitLoss/GetProfitLossDetailView';
        const date1 = moment(date[0]).format('YYYY-MM-DD');
        const date2 = moment(date[1]).format('YYYY-MM-DD');
        let url = PL_DETAIL + '?account=' + obj[index].accKey + '&fromDate=' + date1 + '&toDate=' + date2;
        if (node?.isMain && node?.accKey) {
          setShowChildren(!showChildren);
          !node.children && dispatch(loaderStart());

          !node.children &&
            axios
              .post(url)
              .then((res) => {
                dispatch(loaderStop());
                obj[index].children = res;
                setindex(index);
                setLeftList(obj);
              })
              .catch((error) => {});
        } else {
          setShowChildren(!showChildren);
          setLeftList(obj);
        }
      } else {
        setShowChildren(!showChildren);
      }
    };

    return (
      <>
        <div onClick={() => handleClick()} className='cursor profit-loss-row d-flex flex-wrap align-items-center' data-id={groupName}>
          <span
            className={`d-flex flex-wrap align-items-center ${children?.length > 0 ? 'has-children-start' : 'has-children-inner-start'}`}
          >
            {node?.nonClick || node?.children?.length == 0 ? (
              <></>
            ) : showChildren ? (
              <i className='fas fa-chevron-circle-down font-size-14 cursor pe-2'></i>
            ) : (
              <i className='fas fa-chevron-circle-right font-size-14 cursor pe-2 '></i>
            )}
            {groupName}
          </span>

          <label>{amount || (level != 1 ? parentSumSales : totalSumSales)}</label>
        </div>
        <ul className='mb-0 profit-loss-ul'>{showChildren && <Tree treeData={children} />}</ul>
      </>
    );
  };
  const Tree = ({treeData, captions}) => {
    return (
      <div className='w-100'>
        {treeData?.map((node, i) => (
          <TreeNode node={node} key={node.id} index={i} />
        ))}
      </div>
    );
  };

  const TreeNode2 = ({node, index}) => {
    const {children, groupName, totalSumSales, amount, parentSumSales, level} = node;
    const [showChildren, setShowChildren] = useState(index == currindex2 && node?.isMain ? true : false);

    const handleClick = () => {
      let obj;
      if (node?.isMain) {
        obj = [...rightList];
        let PL_DETAIL = '/ProfitLoss/GetProfitLossDetailView';
        const date1 = moment(date[0]).format('YYYY-MM-DD');
        const date2 = moment(date[1]).format('YYYY-MM-DD');
        let url = PL_DETAIL + '?account=' + obj[index].accKey + '&fromDate=' + date1 + '&toDate=' + date2;
        if (node?.isMain && node?.accKey) {
          setShowChildren(!showChildren);
          !node.children && dispatch(loaderStart());

          !node.children &&
            axios
              .post(url)
              .then((res) => {
                dispatch(loaderStop());
                obj[index].children = res;
                setindex2(index);
                setRightList(obj);
              })
              .catch((error) => {});
        } else {
          setShowChildren(!showChildren);
          setRightList(obj);
        }
      } else {
        setShowChildren(!showChildren);
      }
    };

    return (
      <>
        <div onClick={() => handleClick()} className='cursor profit-loss-row d-flex flex-wrap align-items-center' data-id={groupName}>
          <span className={`d-flex flex-wrap align-items-center ${children?.length > 0 ? '' : 'has-no-children-end'}`}>
            {node?.nonClick || node?.children?.length == 0 ? (
              <></>
            ) : showChildren ? (
              <i className='fas fa-chevron-circle-down font-size-14 cursor pe-2'></i>
            ) : (
              <i className='fas fa-chevron-circle-right font-size-14 cursor pe-2'></i>
            )}
            {groupName}
          </span>
          <label>{amount || (level != 1 ? parentSumSales : totalSumSales)}</label>
        </div>
        <ul className='mb-0 profit-loss-ul'>{showChildren && <Tree2 treeData={children} />}</ul>
      </>
    );
  };

  const Tree2 = ({treeData}) => {
    return (
      <div className='w-100'>
        {treeData?.map((node, i) => (
          <TreeNode2 node={node} key={node.id} index={i} />
        ))}
      </div>
    );
  };

  return (
    <div className='page-content'>
      <Container fluid={true}>
        <Breadcrumbs breadcrumbItem='Profit & Loss Report' />
        <Card>
          <CardBody>
            <Row>
              <Col lg={12}>
                <div className='row0'>
                  <div className='w220 '>
                    <Flatpickr
                      className='form-control '
                      placeholder='Date Range'
                      value={date}
                      options={{
                        mode: 'range',
                        disableMobile: true,
                        dateFormat: 'd/m/Y '
                      }}
                      onChange={(e) => handleDate(e)}
                      onClose={(e) => handleDateClose(e)}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <br />

            <Row>
              <Col md={6} lg={6} className='p-0'>
                <div className='row1 profit-loss-table'>
                  <Tree treeData={leftList} />
                </div>
              </Col>
              <Col md={6} lg={6} className='p-0'>
                <div className='row1 profit-loss-table'>
                  <Tree2 treeData={rightList} />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default ProfitLoss;
