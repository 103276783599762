import React, {useEffect, useState} from 'react';
import {Card, CardBody, Col, Container, InputGroup, Row} from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import JournalsList from './journalList/journalList';
import {useDispatch, useSelector} from 'react-redux';
import {deleteJournal, getJournalsList, getNextJournalNumber} from '../../store/journals/actions';
import Flatpickr from 'react-flatpickr';
import {getAccounts} from '../../store/actions';
import moment from 'moment';
import AccountModal from '../Transactions/accountModal';
import DeleteModal from './deleteModal';
import OperationsModal from './operationsModal';

const Journals = () => {
  document.title = 'journals - account-books';

  const [modal_journals, setModal_journals] = useState(false);
  const [modal, setModal] = useState(false);
  let prevDate = moment().subtract(6, 'day');
  const [date, setDate] = useState([prevDate._d, new Date()]);
  const [accId, setAccId] = useState();
  const [currentUser, setCurrentUser] = useState('');
  const [inputFields, setInputFields] = useState([
    {
      id: '',
      accountName: '',
      cdMode: '',
      amount: ''
    }
  ]);
  const [jvNumber, setJvNumber] = useState(1);
  const [deleteJournalData, setdeleteJournalData] = useState('');
  const [crAmount, setCrAmont] = useState('');
  const [drAmount, setDrAmount] = useState();
  const [formDate, setFormDate] = useState([new Date()]);
  const [modal_invoice, setmodal_invoice] = useState(false);

  const onclearValue = () => {
    setCurrentUser('');
    setCrAmont(null);
    setDrAmount(null);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getJournalsList(date));
    dispatch(getAccounts());
    dispatch(getNextJournalNumber());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getJournalsList(date));
  }, [date]);

  const deleteUserAccount = (id) => {
    dispatch(deleteJournal(id, date));
    setModal(false);
    dispatch(getJournalsList());
  };

  const journalsListData = useSelector((state) => state.journalsReducer?.journalsList);

  const NextJournalNum = useSelector((state) => state.journalsReducer.journalsNum);

  const accountsList = useSelector((state) => state.accounts.accounts);

  const editClick = async (data) => {
    setCurrentUser(data);
    setInputFields(data?.journalDetailsList);
    setModal_journals(true);
    setAccId(data.id);
    setFormDate(new Date(data.jvDate));
  };
  const onDelteClick = (data) => {
    setModal(true);
    setdeleteJournalData(data?.id);
  };

  // Modal Actions
  const toggleModal = () => {
    setModal_journals(!modal_journals);
  };
  const handleModalClose = () => {
    setModal_journals(false);
    onclearValue();
    setJvNumber('');
    setAccId();
    setFormDate([new Date()]);
  };
  const toggle = () => {
    setModal(!modal);
  };

  const tog_invoice = (type) => {
    setmodal_invoice(!modal_invoice);
  };
  const handleDateClose = (e) => {
    if (date.length != 2) {
      let defDate = [e[0], e[0]];
      setDate(defDate);
    }
  };
  return (
    <>
      {' '}
      <div className='page-content'>
        <Container fluid={true}>
          <Breadcrumbs title='Account Group' breadcrumbItem='Journals' />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className='position-relative'>
                    <div className='modal-button mt-2 '>
                      <Row className='d-flex justify-content-center'>
                        <Col className='auto '>
                          <InputGroup>
                            <Flatpickr
                              className='form-control d-block jouranaldate'
                              placeholder='Select date... '
                              value={date}
                              options={{
                                mode: 'range',
                                disableMobile: true,
                                dateFormat: 'd/m/Y'
                              }}
                              onChange={(e) => {
                                if (e.length) {
                                  setDate(e);
                                } else {
                                  setDate([prevDate._d, new Date()]);
                                }
                              }}
                              onClose={handleDateClose}
                            />
                          </InputGroup>
                        </Col>
                        <Col className='col-sm'>
                          <div>
                            <button
                              type='button'
                              className='btn btn-success mb-4'
                              data-bs-toggle='modal'
                              data-bs-target='#addInvoiceModal'
                              onClick={() => {
                                setJvNumber(NextJournalNum?.value);
                                setModal_journals(true);
                                dispatch(getNextJournalNumber());
                              }}
                            >
                              <i className='mdi mdi-plus me-1'></i> Add New JV
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div id='table-invoices-list'>
                    {journalsListData && journalsListData ? (
                      <JournalsList
                        listData={journalsListData}
                        onEditClick={(data) => editClick(data)}
                        ondeleteClick={(data) => onDelteClick(data)}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <OperationsModal
        modal_journals={modal_journals}
        handleModalClose={() => handleModalClose()}
        toggleModal={() => toggleModal()}
        tog_invoice={() => tog_invoice()}
        currentUser={currentUser}
        jvNumber={jvNumber}
        accId={accId}
        accountsList={accountsList}
        date={date}
      />
      <DeleteModal
        modal={modal}
        toggle={toggle}
        deleteUserAccount={() => deleteUserAccount(deleteJournalData)}
        deleteJournalData={deleteJournalData}
      />
      <AccountModal modal_invoice={modal_invoice} tog_invoice={() => tog_invoice()} setmodal_invoice={() => setmodal_invoice()} />
    </>
  );
};

export default Journals;
