import React, {useEffect, useState} from 'react';
import {Row, Col, Container, Card, CardBody} from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import GroupTable from './AccountGroupTable';
import {useDispatch, useSelector} from 'react-redux';
import {getAccountGroup, deleteAccountGroup} from '../../../store/AccountGroup/actions';
import AccountGroupModal from './DeleteAccountGroupModal';
import AddAccountGroupModal from './AddAccountGroupModal';
const AccountGroup = () => {
  const dispatch = useDispatch();
  const result = useSelector((state) => state.accountsGroupReducer);

  document.title = 'Accounts group - account-books';

  let defaultValue = {
    groupName: '',
    parent: '',
    error: false
  };
  const [firstClick, setFirstClick] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [modal_invoice, setModal_invoice] = useState(false);
  const [accGroup, setAccGroup] = useState(defaultValue);
  const [errorMsg, setErrorMsg] = useState(defaultValue);

  const toggleModal = () => {
    setModal_invoice(!modal_invoice);
  };

  const handleEdit = (row) => {
    setModal_invoice(true);
    setAccGroup({
      parent: {value: row.parentGroupID, label: row.parentGroupName},
      recId: row.id,
      groupName: row.groupName
    });
  };

  const handleModalClose = () => {
    setFirstClick(false)
    setAccGroup([]);
    setModal_invoice(false);
    setErrorMsg({groupName: '', parent: '', error: false});
  };
  const handleDeleteModalClose = () => {
    setDeleteModal(false);
  };
  useEffect(() => {
    dispatch(getAccountGroup());
    handleModalClose();
    handleDeleteModalClose();
  }, [dispatch, result?.refresh]);

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleDelete = (row) => {
    setDeleteModal(true);
    setAccGroup({...accGroup, recId: row.id});
  };

  const handleConfirmDelete = () => {
    dispatch(deleteAccountGroup(accGroup.recId));
    setDeleteModal(false);
    setAccGroup('');
  };

  return (
    <>
      <div className='page-content'>
        <Container fluid={true}>
          <Breadcrumbs
            title='Account Group'
            breadcrumbItem='Account Groups
'
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className='position-relative'>
                    <div className='modal-button mt-2'>
                      <Row className='align-items-start'>
                        <Col className='col-sm'>
                          <div>
                            <button
                              type='button'
                              className='btn btn-success mb-4'
                              data-bs-toggle='modal'
                              data-bs-target='#addInvoiceModal'
                              onClick={() => {
                                setModal_invoice(true);
                              }}
                            >
                              <i className='mdi mdi-plus me-1'></i> Add New Group
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div id='table-invoices-list'>
                    <GroupTable tableData={result?.accountsGroup} handleEdit={handleEdit} handleDelete={handleDelete} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <AccountGroupModal
        deleteModal={deleteModal}
        toggleDeleteModal={() => toggleDeleteModal()}
        setDeleteModal={() => setDeleteModal(false)}
        handleConfirmDelete={() => handleConfirmDelete()}
      />

      <AddAccountGroupModal
      firstClick={firstClick}
        accGroup={accGroup}
        modal_invoice={modal_invoice}
        handleModalClose={() => handleModalClose()}
        toggleModal={() => toggleModal()}
        errorMsg={errorMsg}
      />
    </>
  );
};

export default AccountGroup;
