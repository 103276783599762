import {GET_CITY, GET_TRANSAJCTION, CHECK_PASSWORD_TRAN, CHECK_PASSWORD_TRAN_FALSE, GET_LEADER_DATA} from './actionTypes';

const initialState = {
  transactionData: [],
  city: [],
  password: false,
  passwordList: false,
  leaderData: [],
  isAdmin: false
};

const accounts = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRANSAJCTION:
      state = {
        ...state,
        transactionData: action.payload
      };
      break;
    case GET_CITY:
      state = {
        ...state,
        city: action.payload
      };
      break;
    case CHECK_PASSWORD_TRAN:
      if (action.payload == 'list') {
        state = {
          ...state,
          passwordList: true
        };
      } else {
        state = {
          ...state,
          password: true
        };
      }
      break;
    case CHECK_PASSWORD_TRAN_FALSE:
      state = {
        ...state,
        password: false,
        passwordList: false
      };
      break;
    case GET_LEADER_DATA:
      state = {
        ...state,
        leaderData: action.payload
      };
      break;

    default:
      state = {...state};
      break;
  }
  return state;
};

export default accounts;
