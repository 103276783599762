import React from 'react';

import TableContainer from '../../components/Common/transacationTableGrid';
import moment from 'moment';

const AccountGroupsTable = (props) => {
  console.log(props,"prop")
  const columns = [
    {
      Header: 'Date',
      Cell: (cell) => {
        if (cell?.cell?.row?.original?.tranStatus === 2 && !cell.cell?.row?.original?.isDeleted) {
          return (
            <div className='cursor text-primary' onClick={() => props?.handleChangeLeader(cell?.cell?.row?.original?.id)}>
              {moment(cell?.cell?.row?.original?.tranDate).format('DD/MM/YYYY')}
            </div>
          );
        } else {
          return <div className='text-primary'>{moment(cell?.cell?.row?.original?.tranDate).format('DD/MM/YYYY')}</div>;
        }
      },
      Filter: true
    },
    {
      Header: 'City',
      accessor: 'cityName',
      Filter: true
    },
    {
      Header: 'Token Details',
      accessor: 'tokenDetail',
      Filter: true
    },
    {
      Header: 'Amount',
      accessor: 'tranAmount',
      Filter: true
    },
    {
      Header: 'Debit',
      accessor: 'sender',
      Filter: true
    },
    {
      Header: 'Rate',
      accessor: 'sRate',
      Filter: true
    },
    {
      Header: 'Commission',
      accessor: 'sCommission',
      Filter: true
    },
    {
      Header: 'Credit',
      accessor: 'receiver',
      Filter: true
    },
    {
      Header: 'Rate',
      accessor: 'rRate',
      Filter: true
    },
    {
      Header: 'Commission',
      accessor: 'rCommission',
      Filter: true
    },
    {
      Header: 'Remarks',
      accessor: 'remarks',
      Filter: true
    },
    {
      Header: 'Status',

      Cell: (cell) => {
        if (cell.cell.row.original.isDeleted == 1) {
          return <span className='cursor badge badge-pill badge-soft-danger font-size-12'>Deleted</span>;
        } else if (cell.cell.row.original.tranStatus === 1) {
          return (
            <span
              onClick={() => props?.ApproveStatus(cell?.cell?.row?.original?.id)}
              className='cursor badge badge-pill badge-soft-warning font-size-12'
            >
              Pending
            </span>
          );
        } else if (cell.cell.row.original.tranStatus === 2) {
          return <span className='cursor badge badge-pill badge-soft-success font-size-12'>Ok</span>;
        }
      }
    },
    {
      Header: 'Action',
      Cell: (cell) => {
        if (cell?.cell?.row?.original.isDeleted === 1) return;
        if (!props?.password) {
          if (cell?.cell?.row?.original.isLock) {
            return (
              <span
                className=' cursor text-primary'
                onClick={() => {
                  props?.togglePassword('list');
                }}
              >
                <i
                  className='bx bx-lock-alt
	 font-size-18'
                ></i>
              </span>
            );
          } else {
            return (
              <>
                <span
                  className=' cursor text-primary'
                  onClick={() => {
                    props?.onEditClick(cell?.cell?.row?.original);
                  }}
                >
                  <i className='bx bx-pencil font-size-18 '></i>
                </span>
                <span
                  className=' cursor text-danger'
                  onClick={() => {
                    props?.ondeleteClick(cell?.cell?.row?.original);
                  }}
                >
                  <i className='bx bx-trash-alt font-size-18'></i>
                </span>
              </>
            );
          }
        } else {
          return (
            <>
              <span
                className='cursor text-primary'
                onClick={() => {
                  props?.onEditClick(cell?.cell?.row?.original);
                }}
              >
                <i className='bx bx-pencil font-size-18'></i>
              </span>
              <span
                className='cursor text-danger'
                onClick={() => {
                  props?.ondeleteClick(cell?.cell?.row?.original);
                }}
              >
                <i className='bx bx-trash-alt font-size-18'></i>
              </span>
            </>
          );
        }
      }
    }
  ];

  console.log( props?.transStatus)
  !props?.transStatus && columns?.splice(11, 1);


  return (
    <React.Fragment>
      <TableContainer
        columns={columns}
        data={props.tableData}
        isGlobalFilter={true}
        customPageSize={20}
        className='Invoice table'
        tableDataExtra={'Transaction'}
        ondownloadClick={(type) => props?.ondownloadClick(type)}
        activeTab2={props?.activeTab2}
        toggle2={(tab) => props?.toggle2(tab)}
        transStatus={props.transStatus}
      />
    </React.Fragment>
  );
};

export default AccountGroupsTable;
