import {Form, useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';
import {Col, FormFeedback, Input, Label, Modal, Row} from 'reactstrap';
import * as Yup from 'yup';
import {getUserRole, saveUser} from '../../../store/users/userTable/actions';
import {getCompanyListWithoutNav} from '../../../store/actions';
function UserOperationModal(props) {
  const [validationSelect, setvalidationSelect] = useState(false);
  const [selectedCompany, setSelectedComapany] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [role, Setrole] = useState('');

  const currentUser = props?.currentUser;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCompanyListWithoutNav());
    dispatch(getUserRole());
  }, [dispatch]);

  useEffect(() => {
    setSelectedComapany(props?.selectedCompany);
    Setrole(props?.role);
    setIsActive(props?.isActive);
  }, [props?.role, props?.selectedCompany, props?.isActive]);

  const {error, comapny} = useSelector((state) => ({
    error: state.login.error,
    comapny: state.login.company
  }));

  const roleChange = useSelector((state) => state.usersList.userRole);

  const handleCompanyChange = async (e) => {
    setvalidationSelect(false);
    await setSelectedComapany(e);
    if (e === '' || e?.length <= 0) {
      validation.setFieldValue('companyName', '');
      return;
    } else {
      validation.setFieldValue('companyName', e[0]?.label);
      validation.handleChange('companyName');
    }
  };

  const handleroleChange = (roleChange) => {
    Setrole(roleChange);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      userName: currentUser ? currentUser.userName : '',
      email: currentUser ? currentUser.email : '',
      roleID: currentUser ? currentUser.role : '',
      tranPswd: '',
      pswd: '',
      companyName: currentUser ? currentUser.companyName : ''
    },
    validationSchema: Yup.object().shape({
      pswd: currentUser.id ? '' : Yup.string().required('Please enter password').matches(/^\S*$/, 'Whitespace is not allowed'),
      companyName: Yup.string().required('Please select company')?.trim(),
      tranPswd: currentUser.id
        ? ''
        : Yup.string().required('Please enter transaction password').matches(/^\S*$/, 'Whitespace is not allowed'),
      roleID: Yup.string().required('Please select user role'),
      userName: Yup.string().required('Please enter user name').trim('Leading and trailing space is not allowed').strict(true),
      email: Yup.string().email('Please enter valid email').required('Please enter email')
    }),
    onSubmit: async (values) => {
      if (selectedCompany === '' || selectedCompany.length <= 0) {
        validation.setFieldValue('companyName', '');
        return;
      }
      setvalidationSelect(false);
      values.isActive = isActive;
      values.operationToPerform = 1;
      let company = JSON.parse(localStorage.getItem('userData'));
      values.companyName = company?.companyName;
      values.companyID = company?.companyID;
      selectedCompany?.map((data) => {
        if (values?.compID) {
          values.compID = values?.compID + ',' + data?.value;
        } else {
          values.compID = data?.value.toString();
        }
      });
      if (currentUser?.id) {
        selectedCompany?.map((data) => {
          values.companyUserGroupingList = [...currentUser.companyUserGroupingList, data];
        });
      } else {
        values.companyUserGroupingList = selectedCompany;
      }
      if (currentUser?.id) {
        values.id = currentUser?.id;
      }
      values.roleID = role.value;
      dispatch(saveUser(values));
      props?.tog_user();
      validation.resetForm();
    }
  });
  return (
    <>
      <Modal size='l' isOpen={props?.modal_user} className='addInvoiceModal' centered>
        <div className='modal-header'>
          <h5 className='modal-title' id='addInvoiceModalLabel'>
            {' '}
            {currentUser ? 'Edit' : 'Add'} User{' '}
          </h5>
          <button
            onClick={() => {
              validation.resetForm();
              props?.tog_user();
              setSelectedComapany();
              Setrole();
            }}
            type='button'
            className='btn-close'
            aria-label='Close'
          ></button>
        </div>
        <div className='modal-body p-4'>
          <form tag={Form} className='form-horizontal' onSubmit={validation.handleSubmit}>
            <Row>
              <Col>
                <Label>
                  Company<span className='text-danger'>*</span>
                </Label>
                <Select
                  options={comapny?.map((value) => ({
                    value: value?.id,
                    label: value?.companyName
                  }))}
                  openMenuOnFocus={true}
                  value={selectedCompany}
                  onChange={handleCompanyChange}
                  isMulti
                  placeholder='Select Company'
                  isSearchable={true}
                  name='companyName'
                  isLoading={false}
                  onBlur={validation.handleBlur}
                  invalid={false}
                  loadingMessage={() => 'Fetching Company Name'}
                  noOptionsMessage={() => 'Company appears here'}
                />
                {validation.touched.companyName && validation.errors.companyName ? (
                  <div className='text-error'>Please select Company</div>
                ) : null}
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col>
                <Label>
                  User Name <span className='text-danger'>*</span>
                </Label>
                <Input
                  name='userName'
                  className='form-control'
                  placeholder='Enter User Name'
                  type='text'
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.userName || ''}
                  invalid={validation.touched.userName && validation.errors.userName ? true : false}
                />
                {validation.touched.userName && validation.errors.userName ? (
                  <FormFeedback type='invalid'>
                    <div>{validation.errors.userName}</div>
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>

            <Row className='mt-4'>
              <Col>
                <Label>
                  User Role <span className='text-danger'>*</span>
                </Label>
                <Select
                  placeholder='User Role'
                  value={role}
                  openMenuOnFocus={true}
                  onChange={(selectedOption) => {
                    handleroleChange(selectedOption);
                    validation.setFieldValue('roleID', selectedOption?.value);
                    validation.handleChange('roleID');
                  }}
                  isSearchable={true}
                  options={roleChange?.map((value) => ({
                    value: value?.id,
                    label: value?.role
                  }))}
                  name='roleID'
                  isLoading={false}
                  loadingMessage={() => 'Fetching Transaction Type'}
                  noOptionsMessage={() => 'Transaction Type appears here'}
                />
                {validation.touched.roleID && validation.errors.roleID ? (
                  <div className='text-error'>{validation.errors.roleID}</div>
                ) : null}
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col>
                <Label>
                  Email <span className='text-danger'>*</span>
                </Label>
                <Input
                  name='email'
                  className='form-control'
                  placeholder='Enter Email'
                  type='text'
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ''}
                  invalid={validation.touched.email && validation.errors.email ? true : false}
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type='invalid'>
                    <div>{validation.errors.email}</div>
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>
            {!currentUser?.id && (
              <>
                <Row className='mt-4'>
                  <Col>
                    <Label>
                      Password<span className='text-danger'>*</span>
                    </Label>
                    <Input
                      name='pswd'
                      className='form-control'
                      placeholder='Enter Password'
                      type='password'
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.pswd || ''}
                      invalid={validation.touched.pswd && validation.errors.pswd ? true : false}
                    />
                    {validation.touched.pswd && validation.errors.pswd ? (
                      <FormFeedback type='invalid'>
                        <div>{validation.errors.pswd}</div>
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>
                <Row className='mt-4'>
                  <Col>
                    <Label>
                      Transaction Password <span className='text-danger'>*</span>
                    </Label>
                    <Input
                      name='tranPswd'
                      className='form-control'
                      placeholder='Enter Transaction Password'
                      type='password'
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.tranPswd || ''}
                      invalid={validation.touched.tranPswd && validation.errors.tranPswd ? true : false}
                    />
                    {validation.touched.tranPswd && validation.errors.tranPswd ? (
                      <FormFeedback type='invalid'>
                        <div>{validation.errors.tranPswd}</div>
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>
              </>
            )}
            <Row className='mt-4'>
              <Col>
                <Input
                  type='checkbox'
                  value={isActive}
                  checked={isActive}
                  id='checkbox_id'
                  onChange={(event) => {
                    setIsActive(event.target.checked);
                  }}
                />
                <Label for='checkbox_id' className='ms-2  cursor'>
                  IsActive
                </Label>
              </Col>
            </Row>

            <div className='mt-3 text-end'>
              <button className='btn btn-primary btn-block' type='submit'>
                {currentUser ? 'Update' : 'Save'}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default UserOperationModal;
