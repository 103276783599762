/* eslint-disable array-callback-return */
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {Row, Col, Container, Card, CardBody} from 'reactstrap';

//Import Flatepicker

// Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

import UsersListTable from './userTable';
import {useDispatch, useSelector} from 'react-redux';
import {getUsersList, saveUser} from '../../../store/users/userTable/actions';
import {getCompanyList, getCompanyListUser} from '../../../store/actions';
import {changePassword} from '../../../store/users/userTable/actions';
import {blockUnBlockUser} from '../../../store/actions';
import UsersPaswordOperationModal from './UserPasswordOperationModal';
import UserOperationModal from './UserOperationModal';
import UsersUnBlockModal from './UserUnblockModal';

const Users = (args) => {
  document.title = 'Users - account-books';

  const [modal_user, setmodal_user] = useState(false);
  const [modalBlockUser, setModalBlockUser] = useState({showPopUp: false, data: []});
  const [modal, setModal] = useState(false);
  const [validationSelect, setvalidationSelect] = useState(false);
  const [passwordId, setPasswordId] = useState('');
  const [selectedCompany, setSelectedComapany] = useState('');
  const [role, Setrole] = useState('');
  const [currentUser, setCurrentUser] = useState('');
  const [isActive, setIsActive] = useState(true);

  const onclearValue = () => {
    setSelectedComapany('');
    setvalidationSelect(false);
    setCurrentUser('');
    Setrole('');
  };

  function tog_user() {
    setmodal_user(!modal_user);
    validation.resetForm();
    onclearValue();
  }

  const toggle = () => {
    setModal(!modal);
    onclearValue();
  };

  const toggleBlockPopupModal = () => {
    setModalBlockUser(!modalBlockUser);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsersList());
    dispatch(getCompanyListUser());
  }, [dispatch]);

  const {error, comapny, unBlockUser} = useSelector((state) => ({
    error: state.login.error,
    comapny: state.login.company,
    unBlockUser: state.login.unBlockUser
  }));

  const editClick = async (data) => {
    await setSelectedComapany(data?.companyUserGroupingList);
    setIsActive(data?.isActive);
    setSelectedComapany((prevData) =>
      prevData.map((obj) => ({
        ...obj,
        value: obj?.companyID,
        label: obj?.companyName
      }))
    );
    Setrole({label: data.role, value: data.roleID});

    setCurrentUser(data);
    setmodal_user(true);
  };

  const unBlockUserClick = async (data) => {
    setModalBlockUser({showPopUp: true, data: data});
  };

  const callUnBlockUserApi = async (data) => {
    if (data && data?.isBlocked) {
      const unBlockUserObj = {isUserBlocked: false, Email: data?.email};
      dispatch(blockUnBlockUser(unBlockUserObj));
    }
  };

  const onPassClick = (user) => {
    setPasswordId(user.id);
    setModal(true);
  };
  // Wizard Modal
  const {usersList} = useSelector((state) => ({
    usersList: state?.usersList?.usersList
  }));

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      userName: setCurrentUser ? currentUser.userName : '',
      email: setCurrentUser ? currentUser.email : '',
      roleID: setCurrentUser ? currentUser.role : 'C',
      tranPswd: '',
      pswd: '',
      companyName: setCurrentUser ? currentUser.companyName : ''
    },
    validationSchema: Yup.object().shape({
      pswd: currentUser.id ? '' : Yup.string().required('Please enter password').matches(/^\S*$/, 'Whitespace is not allowed'),
      companyName: Yup.string().required('Please select company')?.trim(),
      tranPswd: currentUser.id
        ? ''
        : Yup.string().required('Please enter transaction password').matches(/^\S*$/, 'Whitespace is not allowed'),
      roleID: Yup.string().required('Please select user role'),
      userName: Yup.string().required('Please enter user name').matches(/^\S*$/, 'Whitespace is not allowed'),
      email: Yup.string().email('Please enter valid email').required('Please enter email')
    }),
    onSubmit: async (values) => {
      if (selectedCompany === '' || selectedCompany.length <= 0) {
        validation.setFieldValue('companyName', '');
        return;
      }
      setvalidationSelect(false);
      values.isActive = isActive;
      values.operationToPerform = 1;
      let company = JSON.parse(localStorage.getItem('userData'));
      values.companyName = company?.companyName;
      values.companyID = company?.companyID;
      selectedCompany?.map((data) => {
        if (values?.compID) {
          values.compID = values?.compID + ',' + data?.value;
        } else {
          values.compID = data?.value.toString();
        }
      });
      if (currentUser?.id) {
        selectedCompany?.map((data) => {
          values.companyUserGroupingList = [...currentUser.companyUserGroupingList, data];
        });
      } else {
        values.companyUserGroupingList = selectedCompany;
      }
      if (currentUser?.id) {
        values.id = currentUser?.id;
      }
      values.roleID = role.value;
      dispatch(saveUser(values));
      setmodal_user(false);
      validation.resetForm();
    }
  });

  // {password reset yup validation }

  const validationPassword = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: '',
      confirmpassword: ''
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Please enter password').matches(/^\S*$/, 'Whitespace is not allowed'),
      confirmpassword: Yup.string()
        .required('Please enter password Again')
        .matches(/^\S*$/, 'Whitespace is not allowed')
        .oneOf([Yup.ref('password'), null], 'Password and confirm password must match')
    }),
    onSubmit: (values) => {
      values.userID = passwordId;
      dispatch(changePassword(values));
      validationPassword.resetForm();
      setModal(false);
    }
  });

  const {errorPassword} = useSelector((state) => ({
    error: state.login.error
  }));

  useEffect(() => {
    if (unBlockUser === 1) dispatch(getUsersList());
  }, [unBlockUser]);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid={true}>
          <Breadcrumbs title='Account Users' breadcrumbItem='Users' />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className='position-relative'>
                    <div className='modal-button mt-2'>
                      <Row className='align-items-start'>
                        <Col className='col-sm'>
                          <div>
                            <button
                              type='button'
                              className='btn btn-success mb-4'
                              data-bs-toggle='modal'
                              data-bs-target='#addInvoiceModal'
                              onClick={() => {
                                tog_user();
                                Setrole('');
                              }}
                            >
                              <i className='mdi mdi-plus me-1'></i> Add New User
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div id='table-invoices-list'>
                    <UsersListTable
                      UsersData={usersList || []}
                      onEditClick={(data) => editClick(data)}
                      onUnblockClick={(data) => unBlockUserClick(data)}
                      onpassClick={(data) => onPassClick(data)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Invoice List Modal */}
      <UserOperationModal
        modal_user={modal_user}
        tog_user={() => tog_user()}
        currentUser={currentUser}
        selectedCompany={selectedCompany}
        role={role}
        isActive={isActive}
      />
      <UsersPaswordOperationModal
        modal={modal}
        toggle={toggle}
        args={args}
        passwordId={passwordId}
        error={error}
        errorPassword={errorPassword}
      />

      <UsersUnBlockModal
        modalBlockUser={modalBlockUser.showPopUp}
        data={modalBlockUser.data}
        toggle={toggleBlockPopupModal}
        callUnblockApi={callUnBlockUserApi}
      />
    </React.Fragment>
  );
};

export default Users;
