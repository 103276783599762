import React from 'react';
import {Navigate} from 'react-router-dom';

// DashBoard

import ComingSoonPage from '../Pages/Utility/pages-comingsoon';

import Error404 from '../Pages/Utility/pages-404';
import Error500 from '../Pages/Utility/pages-500';

// Import Authentication pages
import Login from '../Pages/Authentication/Login';
import ForgetPasswordPage from '../Pages/Authentication/ForgetPassword';
import Logout from '../Pages/Authentication/Logout';
import UserProfile from '../Pages/Authentication/user-profile';
import Company from '../Pages/Authentication/Company';
import ChangePassword from '../Pages/Authentication/changePassword';
import AccountGroups from '../Pages/Masters/accountsGroups/AccountGroup';
import Users from '../Pages/Masters/users/users';
import Accounts from '../Pages/Masters/Accounts/accounts';
import Configuration from '../Pages/Masters/CompanyConfiguration/configuration';
import Ledger from '../Pages/Reports/Ledger/ledger';
import TrialBalance from '../Pages/Reports/TrialBalance/trialBalance';
import Transactions from '../Pages/Transactions/transactions';
import Journals from '../Pages/Journals/journals';
import Purchase from '../Pages/Purchase/Purchase';
import PurchaseNew from '../Pages/Purchase/PurchaseNew';
import Sales from '../Pages/Sales/Sales';
import SalesNew from '../Pages/Sales/SalesNew';
import Stock from '../Pages/Reports/Stock/Stock';
import PartyStock from '../Pages/Reports/Party Stock/PartyStock';
import Products from '../Pages/Masters/Products/Products';
import Cities from '../Pages/Masters/Cities/city';
import BankBook from '../Pages/BankBook/bankbook';
import CashB from '../Pages/CashB/CashB';
import ProfitLoss from '../Pages/Reports/ProfitLoss/ProfitLoss';

const authProtectedRoutes = [
  {path: '/transactions', component: <Transactions />},
  {path: '/cashbook', component: <CashB />},
  {path: '/bankbook', component: <BankBook />},
  {path: '/journals', component: <Journals />},

  {path: '/report/ledger', component: <Ledger />},
  {path: '/report/trial-balance', component: <TrialBalance />},
  {path: '/report/Stock', component: <Stock />},
  {path: '/report/PartyStock', component: <PartyStock />},

  {path: '/purchase/metal/order', component: <Purchase />},
  {path: '/purchase/metal/invoice', component: <Purchase />},
  {path: '/purchase/currency/order', component: <Purchase />},
  {path: '/purchase/currency/invoice', component: <Purchase />},

  {path: '/sales/metal/order', component: <Sales />},
  {path: '/sales/metal/invoice', component: <Sales />},
  {path: '/sales/currency/order', component: <Sales />},
  {path: '/sales/currency/invoice', component: <Sales />},

  {path: '/master/accountGroup', component: <AccountGroups />},
  {path: '/master/users', component: <Users />},
  {path: '/master/acoounts', component: <Accounts />},
  {path: '/master/configuration', component: <Configuration />},
  {path: '/master/products', component: <Products />},
  {path: '/master/city', component: <Cities />},
  {path: '/master/profitloss', component: <ProfitLoss />}
];

const extraAuthRoutes = [
  {path: '/userprofile', component: <UserProfile />},
  {
    path: '/',
    exact: true,
    component: <Navigate to='/transactions' />
  },
  {
    path: '*',
    exact: true,
    component: <Navigate to='/pages-404' />
  },
  {path: '/purchase/new', component: <PurchaseNew />},
  {path: '/sales/new', component: <SalesNew />}
];

const publicRoutes = [
  {path: '/logout', component: <Logout />},
  {path: '/login', component: <Login />},
  {path: '/company', component: <Company />},
  {path: '/forgot-password', component: <ForgetPasswordPage />},
  {path: '/change-password', component: <ChangePassword />},
  {path: '/pages-comingsoon', component: <ComingSoonPage />},
  {path: '/pages-comingsoon', component: <ComingSoonPage />},
  {path: '/pages-404', component: <Error404 />},
  {path: '/pages-500', component: <Error500 />}
];

export {authProtectedRoutes, publicRoutes, extraAuthRoutes};
