import React, {useState, useEffect} from 'react';
import {Col, Input, Row, Table} from 'reactstrap';
import moment from 'moment';

const GroupTable = (props) => {
  const [filteredData, setFilteredData] = useState(props.tableData);

  useEffect(() => {
    setFilteredData(props.tableData);
  }, [props.tableData]);

  const handleExp = (e, i) => {
    let obj = [...filteredData];
    obj.map((a, j) => {
      if (i == j) {
        a.isExpand = !a.isExpand;
      } else {
        a.isExpand = false;
      }
    });
    setFilteredData(obj);
  };

  return (
    <>
      {
        <div>
          <Row className={props.modTable ? '' : 'mt-4'}>
            <Col sm={12}>
              <Table
                responsive
                variant='dark'
                className='
              table-bordered'
              >
                <thead>
                  {props.modTable ? (
                    <>
                      <tr style={{background: '#f5f6f8'}}>
                        <th></th>
                        <th>{props?.heading?.includes('Invoice') ? 'Invoice' : 'Order'} No.</th>
                        <th>Date </th>
                        <th>Customer </th>
                        <th>Amount </th>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr style={{background: '#f5f6f8'}}>
                        <th></th>
                        <th>{props.heading.includes('Invoice') ? 'Invoice' : 'Order'} No. </th>
                        <th>{props.heading.includes('Invoice') ? 'Invoice' : 'Order'} Date </th>
                        <th>Customer</th>
                        <th>Total Amount </th>
                        <th>Actions </th>
                      </tr>
                    </>
                  )}
                </thead>
                <tbody>
                  {filteredData &&
                    filteredData.map((row, i) => (
                      <>
                        <tr key={row.id}>
                          <td style={{width: '5px'}} onClick={(e) => handleExp(e, i)}>
                            {row.isExpand ? (
                              <i className='fas fa-chevron-circle-down font-size-20 cursor'></i>
                            ) : (
                              <i className='fas fa-chevron-circle-right font-size-20 cursor'></i>
                            )}
                          </td>

                          <td>{row.orderNo}</td>
                          <td>{row?.date?.split('T')[0]}</td>
                          <td>{row?.customer}</td>
                          <td>{row.totalAmount}</td>
                          {props.modTable ? (
                            <></>
                          ) : (
                            <td style={{width: '100px'}}>
                              <span
                                className=' cursor text-primary'
                                onClick={() => {
                                  props?.handleEdit(row);
                                }}
                              >
                                <i className='bx bx-pencil font-size-18'></i>
                              </span>
                              <span
                                className='cursor text-danger'
                                onClick={() => {
                                  props?.handleDelete(row);
                                }}
                              >
                                {' '}
                                <i className='bx bx-trash-alt font-size-18'></i>
                              </span>
                            </td>
                          )}
                        </tr>

                        {row.isExpand ? (
                          <tr>
                            <td colSpan='6'>
                              <div
                                style={{
                                  backgroundColor: 'white',
                                  color: '#FFF'
                                }}
                              >
                                <Table bordered size='sm' className='m-0'>
                                  <thead style={{background: '#f5f6f8'}}>
                                    <tr>
                                      <th>Product</th>
                                      <th>Qty</th>
                                      <th>Rate</th>
                                      <th>Amount</th>
                                      <th>Is Unfixed</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {row.productList.map((data) => (
                                      <tr>
                                        <td> {data.product} </td>
                                        <td> {data.qty} &nbsp;{data.unitName}</td>
                                        <td> {data.rate} </td>
                                        <td> {data.amount} </td>
                                        <td> {data.isHold ? 'yes' : 'no'} </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </div>
                            </td>
                          </tr>
                        ) : null}
                      </>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      }
    </>
  );
};

export default GroupTable;
