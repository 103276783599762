import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {Row, Col, Modal, FormFeedback, Input, Label, Form} from 'reactstrap';
import {useDispatch} from 'react-redux';

import {addCity} from '../../../store/actions';
export default function AddUpdateCity(props) {
  const {modal_user, tog_user, setmodal_user, currentUser} = props;
  const [isActive, setIsActive] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsActive(props?.isActive);
  }, [props?.isActive]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      cityName: currentUser ? currentUser.cityName : ''
    },
    validationSchema: Yup.object().shape({
      cityName: Yup.string().required('Please enter city name').trim('City name cannot include leading and trailing spaces').strict(true)
    }),
    onSubmit: async (values) => {
      values.isActive = isActive;
      //   values.operationToPerform = 1;
      //   let company = JSON.parse(localStorage.getItem('userData'));
      //   values.companyName = company?.companyName;
      //   values.companyID = company?.companyID;
      if (currentUser?.id) {
        values.id = currentUser?.id;
      } else {
        values.id = 0;
      }
      dispatch(addCity(values, tog_user));
      validation.resetForm();
    }
  });
  return (
    <Modal size='l' isOpen={modal_user} className='addInvoiceModal' centered>
      <div className='modal-header'>
        <h5 className='modal-title' id='addInvoiceModalLabel'>
          {' '}
          {currentUser ? 'Edit' : 'Add'} City{' '}
        </h5>
        <button
          onClick={() => {
            setmodal_user(false);
          }}
          type='button'
          className='btn-close'
          aria-label='Close'
        ></button>
      </div>
      <div className='modal-body p-4'>
        <Form className='form-horizontal' onSubmit={validation.handleSubmit}>
          <Row>
            <Col>
              <Label>
                City Name <span className='text-danger'>*</span>
              </Label>
              <Input
                name='cityName'
                className='form-control'
                placeholder='Enter City '
                type='text'
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.cityName || ''}
                invalid={validation.touched.cityName && validation.errors.cityName ? true : false}
              />
              {validation.touched.cityName && validation.errors.cityName ? (
                <FormFeedback type='invalid'>
                  <div>{validation.errors.cityName}</div>
                </FormFeedback>
              ) : null}
            </Col>
          </Row>

          <Row className='mt-4'>
            <Col>
              <Input
                type='checkbox'
                value={isActive}
                id='checkbox_id'
                checked={isActive}
                onChange={(event) => {
                  setIsActive(event.target.checked);
                }}
              />
              <Label for='checkbox_id' className='ms-2  cursor'>
                IsActive
              </Label>
            </Col>
          </Row>

          <div className='mt-3 text-end'>
            <button className='btn btn-primary btn-block' type='submit'>
              {currentUser ? 'Update' : 'Save'}
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
