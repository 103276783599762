import axios from 'axios';
import {toast} from 'react-toastify';
import {STOCK_FETCH_SUCCESS, STOCK_FETCH_ERROR} from './actionTypes';
import moment from 'moment';
import {errorHandlers} from '../../helpers/errorHandlers';
import {loaderStart, loaderStop} from '../actions';

export const getStockList = (date, prodId) => async (dispatch) => {
  dispatch(loaderStart());
  const date1 = moment(date[0]).format('YYYY-MM-DD');
  const date2 = moment(date[1]).format('YYYY-MM-DD');
  axios
    .get('Stock/GetStockReport' + '?fromDate=' + date1 + '&toDate=' + date2 + '&productID=' + prodId)
    .then((response) => {
      dispatch(loaderStop());
      dispatch({
        type: STOCK_FETCH_SUCCESS,
        payload: response
      });
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};

export const downloadStockList = (date, productID, name, type, product) => async (dispatch) => {
  dispatch(loaderStart());
  const date1 = moment(date[0]).format('YYYY-MM-DD');
  const date2 = moment(date[1]).format('YYYY-MM-DD');

  let name1 = name?.trim();
  if (!name1) {
    name1 = 'Stock Report';
  }

  let tag;
  if (type == 'pdf') {
    tag = '.pdf';
  } else {
    tag = '.csv';
  }
  const url = type == 'csv' ? `/ExportExcel/ExportstocklExcel` : `/ExportPDF/ExportstocklPDF`;
  axios
    .get(url + '?fromDate=' + date1 + '&toDate=' + date2 + '&productID=' + productID + '&ProductName=' + product, {
      responseType: 'blob'
    })
    .then((response) => {
      dispatch(loaderStop());
      const url = URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = url;
      link.download = name1 + tag;
      link.click();
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};
