import PropTypes from 'prop-types';
import React from 'react';

import {Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label} from 'reactstrap';

//redux
import {useSelector, useDispatch} from 'react-redux';

import {Link, useSearchParams} from 'react-router-dom';
import withRouter from '../../components/Common/withRouter';

// Formik validation
import * as Yup from 'yup';
import {useFormik} from 'formik';

// actions
import {changePassword} from '../../store/actions';

// import images
import logo from '../../assets/images/ACCBOOK_white.svg';

//Import config

const ChangePassword = (props) => {
  document.title = 'Password | Account-book';

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: '',
      confirmpassword: ''
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Please enter password'),
      confirmpassword: Yup.string()
        .required('Please Enter Your passwoord again')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
    }),
    onSubmit: (values) => {
      values.token = searchParams.get('token'); // 'name'
      dispatch(changePassword(values, props.router.navigate));
    }
  });

  const {error} = useSelector((state) => ({
    error: state.login.error
  }));

  return (
    <React.Fragment>
      <div className='authentication-bg min-vh-100'>
        <div className='bg-overlay'></div>
        <Container>
          <div className='d-flex flex-column min-vh-100 px-3 pt-4'>
            <Row className='justify-content-center my-auto'>
              <Col md={8} lg={6} xl={5}>
                <div className='text-center mb-4'>
                  <Link to='/'>
                    <img src={logo} alt='' height='45' /> <span className='logo-txt text-light'></span>
                  </Link>
                </div>
                <Card>
                  <CardBody className='p-4'>
                    <div className='text-center mt-2'>
                      <h5 className='text-primary'>Change Password !</h5>
                      <p className='text-muted'>change the password for continue to Account-Books.</p>
                    </div>
                    <div className='p-2 mt-4'>
                      <Form
                        className='form-horizontal'
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {error ? (
                          <Alert color='danger'>
                            <div>{error}</div>
                          </Alert>
                        ) : null}

                        <div className='mb-3'>
                          <Label className='form-label'>Password</Label>
                          <Input
                            name='password'
                            value={validation.values.password || ''}
                            type='password'
                            placeholder='Enter Password'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={validation.touched.password && validation.errors.password ? true : false}
                          />
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type='invalid'>
                              <div> {validation.errors.password} </div>
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className='mb-3'>
                          <Label className='form-label'>Conform Password</Label>
                          <Input
                            name='confirmpassword'
                            value={validation.values.confirmpassword || ''}
                            type='password'
                            placeholder='Enter confirm password'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={validation.touched.confirmpassword && validation.errors.confirmpassword ? true : false}
                          />
                          {validation.touched.confirmpassword && validation.errors.confirmpassword ? (
                            <FormFeedback type='invalid'>
                              <div> {validation.errors.confirmpassword} </div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className='mt-3 text-end'>
                          <button className='btn btn-primary btn-block' type='submit'>
                            Change Password
                          </button>
                        </div>

                        <div className='mt-4 text-center'>
                          <Link to='/login' className='text-muted'>
                            <i className='mdi mdi-lock me-1' />
                            Back to login
                          </Link>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className='mt-5 text-center text-white-50'>
                  {/* <p>
										Don&#39;t have an account ?{" "}
										<Link to="/register" className="fw-medium text-primary">
											{" "}
											Signup now{" "}
										</Link>{" "}
									</p> */}
                  {/* <p>
                    © {new Date().getFullYear()}
                    <i className='mdi mdi-heart text-danger' /> Account-Books
                  </p> */}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ChangePassword);

ChangePassword.propTypes = {
  history: PropTypes.object
};
