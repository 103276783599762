import {GET_PURCHASE_LIST, POST_PURCHASE_SAVE, POST_PURCHASE_DELETE, GET_PURCHASE_ID, GET_PURCHASE_PENDING_ITEMS} from './actionTypes';

const initialState = {
  error: '',
  refresh: new Date(),
  purchaseList: [],
  pendingItemList: []
};

const purchaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PURCHASE_LIST:
      state = {
        ...state,
        error: '',
        purchaseList: action.payload,
        refresh: new Date()
      };
      break;
    case POST_PURCHASE_SAVE:
      state = {
        ...state,
        error: '',
        purchaseList: [],
        refresh: new Date(),
        supplierInfo: action.payload
      };
      break;

    case POST_PURCHASE_DELETE:
      state = {
        ...state,
        error: '',
        delRefresh: new Date()
      };
      break;

    case GET_PURCHASE_PENDING_ITEMS:
      state = {
        ...state,
        error: '',
        pendingItemList: action.payload,
        delRefresh: new Date()
      };
      break;

    default:
      state = {...state};
      break;
  }
  return state;
};

export default purchaseReducer;
