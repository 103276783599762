import React from 'react';
import {Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useDispatch} from 'react-redux';
import {transactionPassword} from '../../store/actions';
function TransactionPassword(props) {
  const {modalpassword, togglePassword, setIsPast, isType,compType,handleUnlock,lockType} = props;
  const dispatch = useDispatch();

  const validationPassword = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: '',
      confirmpassword: ''
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Please enter password')
    }),
    onSubmit: (values) => {
      dispatch(transactionPassword(values, togglePassword, setIsPast, isType,handleUnlock,lockType,compType));
      validationPassword.resetForm();
    }
  });
  return (
    <Modal
      isOpen={modalpassword}
      toggle={() => {
        validationPassword.resetForm();

        togglePassword();
      }}
      {...props}
    >
      <ModalHeader
        toggle={() => {
          validationPassword.resetForm();

          togglePassword();
        }}
      >
        Unblock {compType}
      </ModalHeader>
      <ModalBody>
        <Form
          className='form-horizontal'
          onSubmit={(e) => {
            e.preventDefault();
            validationPassword.handleSubmit();
            return false;
          }}
        >
          <div className='mb-3'>
            <Label className='form-label'>Password</Label>
            <Input
              name='password'
              value={validationPassword.values.password || ''}
              type='password'
              placeholder='Enter Password'
              onChange={validationPassword.handleChange}
              onBlur={validationPassword.handleBlur}
              invalid={validationPassword.touched.password && validationPassword.errors.password ? true : false}
            />
            {validationPassword.touched.password && validationPassword.errors.password ? (
              <FormFeedback type='invalid'>
                <div> {validationPassword.errors.password} </div>
              </FormFeedback>
            ) : null}
          </div>

          <div className='mt-3 text-end'>
            <button className='btn btn-primary btn-block' type='submit'>
              Unblock{' '}
            </button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default TransactionPassword;
