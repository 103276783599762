import React from 'react';
import TableContainer from '../../../components/Common/TableContainer';

function ProductsTable(props) {
  const columns = [
    {
      Header: 'Product Code',
      accessor: 'productCode',
      Filter: false
    },
    {
      Header: 'Product Name',
      accessor: 'productName',
      Filter: false
    },
    {
      Header: 'Product Type',
      accessor: 'products',
      Filter: false
    },
    {
      Header: 'Units',
      accessor: 'units',
      Filter: false
    },
    {
      Header: 'Available Stocks',
      accessor: 'availStock',
      Filter: false
    },

    {
      Header: 'IsActive',

      Cell: (cell) => {
        if (cell.cell.row.original.isActive == true) {
          return <span className=' badge badge-pill badge-soft-success font-size-12'>Active</span>;
        } else {
          return <span className=' badge badge-pill badge-soft-warning font-size-12'>Inactive </span>;
        }
      }
    },

    {
      Header: 'Action',
      Cell: (cell) => {
        return (
          <>
            <span
              className=' cursor text-primary'
              onClick={() => {
                props?.onEditClick(cell?.cell?.row?.original);
              }}
            >
              <i className='bx bx-pencil font-size-16'></i>
            </span>
            <span
              className=' cursor text-danger'
              onClick={() => {
                props?.onDeleteClick(cell?.cell?.row?.original);
              }}
            >
              {' '}
              <i
                className='bx bx-trash-alt
 font-size-18'
              ></i>
            </span>
          </>
        );
      }
    }
  ];

  return (
    <>
      {props?.listData && props?.listData ? (
        <TableContainer columns={columns} data={props?.listData} isGlobalFilter={true} customPageSize={20} className='Invoice table' />
      ) : (
        <></>
      )}
    </>
  );
}

export default ProductsTable;
