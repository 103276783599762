import React from 'react';

import TableContainer from '../../../components/Common/TableContainer';

const UsersTable = (props) => {
  const columns = [
    {
      Header: 'City',
      accessor: 'cityName',
      Filter: false
    },

    {
      Header: 'Is Active',
      Cell: (cell) => {
        if (cell.cell.row.original.isActive == true) {
          return <span className=' badge badge-pill badge-soft-success font-size-12'>Active</span>;
        } else {
          return <span className=' badge badge-pill badge-soft-warning font-size-12'>Inactive </span>;
        }
      }
    },
    {
      Header: 'Action',
      Cell: (cell) => {
        return (
          <>
            <span
              className=' cursor text-primary'
              onClick={() => {
                props?.onEditClick(cell?.cell?.row?.original);
              }}
            >
              <i className='bx bx-pencil font-size-16'></i>
            </span>
            <span
              className=' cursor text-danger'
              onClick={() => {
                props?.handleDelete(cell?.cell?.row?.original?.id);
              }}
            >
              {' '}
              <i
                className='bx bx-trash-alt
 font-size-18'
              ></i>
            </span>
          </>
        );
      }
    }
  ];

  return (
    <React.Fragment>
      {props?.UsersData && props?.UsersData ? (
        <TableContainer columns={columns} data={props?.UsersData} isGlobalFilter={true} customPageSize={20} className='Invoice table' />
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default UsersTable;
