import React from 'react';
import TableContainer from '../../../components/Common/TableContainer';

const GroupTable = (props) => {
  const columns = [
    {
      Header: 'Group Name',
      accessor: 'groupName',
      Filter: false
    },
    {
      Header: 'Parent Group',
      Cell: (cell) => {
        return <>{cell?.cell?.row?.original.parentGroupName == 'null' ? <></> : <>{cell?.cell?.row?.original.parentGroupName}</>}</>;
      }
    },

    {
      Header: 'Action',
      Cell: (cell) => {
        return (
          <>
            {cell?.cell?.row?.original.isPrimary ? (
              <></>
            ) : (
              <>
                <i
                  className='bx bx-pencil font-size-18 cursor text-primary me-1'
                  onClick={() => props.handleEdit(cell?.cell?.row?.original)}
                ></i>

                <i
                  className='bx bx-trash-alt font-size-18 cursor text-danger'
                  onClick={() => props.handleDelete(cell?.cell?.row?.original)}
                ></i>
              </>
            )}
          </>
        );
      }
    }
  ];
  return (
    <>
      {props?.tableData && props?.tableData ? (
        <TableContainer columns={columns} data={props.tableData} isGlobalFilter={true} customPageSize={20} className='Invoice table' />
      ) : (
        <TableContainer columns={columns} data={[]} isGlobalFilter={true} customPageSize={20} className='Invoice table' />
      )}
    </>
  );
};

export default GroupTable;
