import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Modal, Card, CardBody, Button, ModalBody, ModalFooter, Table, ModalHeader, Row, Col, Label, Input} from 'reactstrap';
import axios from 'axios';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import Select from 'react-select';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import moment from 'moment';

import {getAccounts} from '../../store/Accounts/actions';
import {getProductListActive} from '../../store/Products/actions';
import {saveSales, getPendingSalesItems, getSalesList} from '../../store/Sales/actions';
import {getCompanyConfigurtion} from '../../store/CompanyConfiguration/actions';

import GroupTable from './SalesTable';
import AccountModal from '../Transactions/accountModal';
const SalesNew = (props) => {
  const fp = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [heading, setHeading] = useState(location?.state?.heading);

  document.title = heading + ' - account-books';
  const editRow = location?.state?.editRow;

  const productListResult = useSelector((state) => state.productReducer);
  const salesResult = useSelector((state) => state.salesReducer);
  const companyConfig = useSelector((state) => state.companyConfiguration.configurationData);
  const accResult = useSelector((state) => state.accounts);

  const [pendingModal, setPendingModal] = useState(false);
  const [modal_invoice, setmodal_invoice] = useState(false);

  const [acc, setAcc] = useState('');

  const [pendingItemList, setPendingItemList] = useState([]);
  const [delList, setDelList] = useState([]);
  const [editList, setEditList] = useState([]);

  const [total, setTotal] = useState(0);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [prodOptions, setProdOptions] = useState([]);

  const [errorMsg, setErrorMsg] = useState({
    groupName: '',
    parent: ''
  });

  const [salesInfo, setSalesInfo] = useState({
    id: 0,
    date: new Date(),
    customer: ''
  });
  let defaulVal = {id: 0, product: '', qty: '', rate: 0, amount: '0.000', fix: false, unit: '', stock: 0};

  const [rows, setRows] = useState([defaulVal]);

  let type = 0;
  if (heading?.includes('Currency')) {
    type = 2.0;
  } else {
    type = 1.0;
  }
  useEffect(() => {
    (async () => {
      fp.current.flatpickr.altInput.focus();
      fp.current.flatpickr.close();
    })();
  }, []);

  let po = productListResult && productListResult?.productList.filter((a) => a.productType == type);
  useEffect(() => {
    if (!prodOptions.length) {
      setProdOptions(po);
      if (!editRow && po.length > 0) {
        handleProduct(po[0], 0);
      }
    }
  }, [productListResult]);
  useEffect(() => {
    if (editRow) {
      setSalesInfo({
        nextSO: editRow.orderNo,
        id: editRow.id,
        date: [new Date(editRow?.soDate || editRow?.siDate)],
        customer: {value: editRow?.customerID, label: editRow.customerName || editRow.customer}
      });
      setAcc({value: editRow?.salesAccountID, label: editRow.salesAccountName});
      let arr = editRow?.productList.map((a) => {
        let obj = {
          product: {value: a.productID, label: a.product || a.product, id: a.productID},
          qty: a.qty,
          rate: a.rate,
          amount: Number(a.amount).toFixed(3),
          fix: a.isHold,
          stock: a.availStock,
          isEdit: true,
          unit: a?.unitName
        };
        if (a.id || a.id == 0) {
          obj.id = a.id;
        }
        if (a.soid || a.soid == 0) {
          obj.soid = a.soid;
        }
        if (a.siid || a.siid == 0) {
          obj.siid = a.siid;
          obj.prevQty = a.qty;
        }
        if (a.sodid || a.sodid == 0) {
          obj.sodid = a.sodid;
        }

        return obj;
      });
      if (heading?.includes('Invoice')) {
        dispatch(getPendingSalesItems(editRow?.customerID, heading));
      }
      let prevDate = moment().subtract(30, 'day');
      let date = [prevDate._d, new Date()];
      dispatch(getSalesList(date, editRow?.customerID, heading));
      var deepCopy1 = JSON.parse(JSON.stringify(arr));
      setEditList(deepCopy1);
      setRows(arr);
      setTotal(Number(editRow.totalAmount));
    } else {
      let url;
      if (heading?.includes('Invoice')) {
        url = 'CompanyConfiguration/GetNextSINo?code=SI_NO';
      } else {
        url = 'CompanyConfiguration/GetNextSONo?code=SO_NO';
      }
      axios.get(url).then((res) => {
        setSalesInfo({...salesInfo, nextSO: res.value, date: res?.date ? [new Date(res?.date)] : [new Date()]});
      });
    }
  }, [location.state]);

  useEffect(() => {
    if (!editRow && accResult) {
      companyConfig.map((c) => {
        accResult?.accounts?.map((a) => {
          if (c.code == 'SalesAccount' && c.value == a.id && heading?.includes('Metal')) {
            setAcc(a);
          }
          if (c.code == 'SalesAccountCurr' && c.value == a.id && heading?.includes('Currency')) {
            setAcc(a);
          }
        });
      });
    }
  }, [accResult, companyConfig, heading]);
  useEffect(() => {
    setPendingItemList(salesResult?.pendingItemList);
  }, [salesResult?.pendingItemList]);

  const handleCustomer = (e) => {
    setSalesInfo({...salesInfo, customer: e});
    setErrorMsg({...errorMsg, customer: ''});
    if (heading?.includes('Invoice')) {
      dispatch(getPendingSalesItems(e.value, heading));
    }

    let prevDate = moment().subtract(30, 'day');
    let date = [prevDate._d, new Date()];
    dispatch(getSalesList(date, e.value, heading));
  };

  useEffect(() => {
    dispatch(getCompanyConfigurtion());
    dispatch(getAccounts());
    dispatch(getProductListActive());
    handleGetCustomers();
  }, [dispatch]);

  useEffect(() => {
    if (!acc && !editRow) {
      companyConfig.map((c) => {
        accResult.accounts.map((a) => {
          if (c.code == 'SalesAccount' && c.value == a.id && heading?.includes('Metal')) {
            setAcc(a);
          }

          if (c.code == 'SalesAccountCurr' && c.value == a.id && heading?.includes('Currency')) {
            setAcc(a);
          }
        });
      });
    }
  }, [accResult, companyConfig]);

  const handleGetCustomers = () => {
    axios.get('Common/GetList').then((res) => {
      res.map((a) => {
        a.value = a.id;
        a.label = a.accountName;
      });

      setCustomerOptions(res);
    });
  };

  //-----------------------------------------------------

  const handleAddRow = () => {
    handleProduct(po[0], rows.length, true);
  };

  const handleClear = (e, i) => {
    let obj = [...rows];
    if (obj[i].isEdit) {
      obj[i].operationToPerform = 3;
      setDelList([...delList, obj[i]]);
    }

    obj.splice(i, 1);
    setRows(obj);
    let sum = 0;
    obj &&
      obj.map((data) => {
        sum += Number(data?.amount);
      });
    setTotal(Number(sum.toFixed(3)));
  };

  const handleProduct = (e, i, addRow) => {
    let obj = [];
    if (addRow) {
      obj = [...rows, defaulVal];
    } else {
      obj = [...rows];
    }
    obj[i].newItem = true;
    obj[i].product = {value: e?.productName, label: e?.productName, id: e.id};
    obj[i].id = 0;
    obj[i].stock = e.availStock;
    obj[i].unit = e.units;
    obj[i].isEdit = false;
    let url = 'Products/GetRateByProductID/' + '?productID=' + e.id;
    editList.map((a, j) => {
      if (j == i && a.isEdit) {
        a.operationToPerform = 3;
        setDelList([...delList, a]);
      }
    });
    if (obj[i].fix) {
      obj[i].amount = 0;
      obj[i].rate = 0;
      setRows(obj);
      handleTotal(obj);
    } else {
      axios.get(url).then((res) => {
        obj[i].rate = res.rate;
        obj[i].amount = Number((res.rate * obj[i].qty) / 1000).toFixed(3);
        setRows(obj);
        handleTotal(obj);
        setErrorMsg({...errorMsg, row: ''});
      });
    }
  };

  const handleFix = (e, i) => {
    const obj = [...rows];
    obj[i].fix = e.target.checked;
    if (e.target.checked) {
      obj[i].amount = 0;
      obj[i].rate = 0;
      handleTotal(obj);
    }
    setRows(obj);
  };

  const handleBlurQty = (e, i) => {
    const obj = [...rows];
    obj[i].rate = Number(obj[i].rate);
    obj[i].amount = Number(obj[i].amount);
    obj[i].qty = Number(obj[i].qty);
    if ((!obj[i].qty || obj[i].qty == Infinity) && !obj[i].fix) {
      obj[i].qty = 0;
    }
    obj[i].rate1 = false;
    obj[i].amount1 = false;
    handleTotal(obj);
    setRows(obj);
  };

  const handleQty = (e, i) => {
    const obj = [...rows];
    obj[i].rate = Number(obj[i].rate);
    obj[i].amount = Number(obj[i].amount);
    if (!obj[i].rate) {
      obj[i].rate1 = true;
    }
    if (!obj[i].amount) {
      obj[i].amount1 = true;
    }
    let value = e.target.value;
    if (value == '') {
      obj[i].qty = '';
    } else if (obj[i].pendingQty && value <= obj[i].pendingQty && value >= 0) {
      obj[i].qty = value;
    } else if (!obj[i].pendingQty && value >= 0) {
      obj[i].qty = value;
    }
    if (!obj[i].fix) {
      if (!obj[i].rate1 && !obj[i].amount1) {
        obj[i].amount = ((Number(obj[i].qty) * obj[i].rate) / 1000).toFixed(3);
      } else if (obj[i].rate1 && obj[i].amount && Number(obj[i].qty) > 0) {
        obj[i].rate = ((obj[i].amount * 1000) / Number(obj[i].qty))?.toFixed(4);
      } else if (obj[i].amount1 && obj[i].rate) {
        obj[i].amount = ((Number(obj[i].qty) * obj[i].rate) / 1000).toFixed(3);
      }
    }

    setRows(obj);
    handleTotal(obj);
  };
  const handleBlurRate = (e, i) => {
    const obj = [...rows];
    obj[i].rate = Number(obj[i].rate);
    obj[i].amount = Number(obj[i].amount);
    obj[i].qty = Number(obj[i].qty);

    if ((!obj[i].rate || obj[i].rate == Infinity) && !obj[i].fix) {
      obj[i].rate = 0;
    } else if (obj[i].rate.toString().includes('.')) {
      obj[i].rate = (+obj[i].rate)?.toFixed(4);
    }
    obj[i].qty1 = false;
    obj[i].amount1 = false;
    handleTotal(obj);
    setRows(obj);
  };

  const handleRate = (e, i) => {
    const obj = [...rows];
    obj[i].amount = Number(obj[i].amount);
    obj[i].qty = Number(obj[i].qty);

    if (!obj[i].qty) {
      obj[i].qty1 = true;
    }
    if (!obj[i].amount) {
      obj[i].amount1 = true;
    }
    let value = e.target.value;
    if (value == '') {
      obj[i].rate = '';
    } else if (value >= 0) {
      obj[i].rate = value;
    }

    if (!obj[i].fix) {
      if (!obj[i].qty1 && !obj[i].amount1) {
        obj[i].amount = ((obj[i].qty * Number(obj[i].rate)) / 1000).toFixed(3);
      } else if (obj[i].qty1 && obj[i].amount && Number(obj[i].rate) > 0) {
        obj[i].qty = (obj[i].amount * 1000) / Number(obj[i].rate);
      } else if (obj[i].amount1 && obj[i].qty) {
        obj[i].amount = ((obj[i].qty * Number(obj[i].rate)) / 1000).toFixed(3);
      }
    }

    handleTotal(obj);
    setRows(obj);
  };

  const handleBlurAmt = (e, i) => {
    const obj = [...rows];
    obj[i].rate = Number(obj[i].rate);
    obj[i].qty = Number(obj[i].qty);
    if (obj[i].amount?.length <= 3 && !obj[i].fix) {
      obj[i].amount = (obj[i].amount / 1000).toFixed(3);
      if (obj[i].qty > 0) {
        obj[i].rate = ((obj[i].amount * 1000) / obj[i].qty)?.toFixed(4);
      }
    } else {
      if (obj[i].amount.toString().includes('.')) {
        obj[i].amount = (+obj[i]?.amount)?.toFixed(3);
      } else {
        obj[i].amount = (obj[i].amount / 1000).toFixed(3);
        if (obj[i].qty > 0) {
          obj[i].rate = ((obj[i].amount * 1000) / obj[i].qty)?.toFixed(4);
        }
      }
    }
    obj[i].qty1 = false;
    obj[i].rate1 = false;
    setRows(obj);
    handleTotal(obj);
  };
  const handleAmt = (e, i) => {
    const obj = [...rows];
    obj[i].rate = Number(obj[i].rate);
    obj[i].qty = Number(obj[i].qty);

    if (!obj[i].qty) {
      obj[i].qty1 = true;
    }
    if (!obj[i].rate) {
      obj[i].rate1 = true;
    }
    let value = e.target.value;
    if (value == '') {
      obj[i].amount = '';
    } else if (value >= 0) {
      const val = ('' + value).replace('.', '');
      if (val?.length > 3) {
        const suppressedValue = Number(+val / 1000).toFixed(3);
        obj[i].amount = suppressedValue;
      } else {
        obj[i].amount = Number(value);
      }
    }

    if (!obj[i].fix) {
      if (!obj[i].qty1 && !obj[i].rate1 && Number(obj[i].qty) > 0) {
        // obj[i].rate = ((Number(obj[i].amount) * 1000) / obj[i].qty)?.toFixed(4);
        obj[i].rate = (Number(obj[i].amount) * 1000) / obj[i].qty;
        if (obj[i].rate?.toString()?.length > 7) {
          obj[i].rate = ((Number(obj[i].amount) * 1000) / obj[i].qty)?.toFixed(4);
        } else {
          obj[i].rate = (Number(obj[i].amount) * 1000) / obj[i].qty;
        }
      } else if (obj[i].qty1 && obj[i].rate > 0) {
        obj[i].qty = (Number(obj[i].amount) * 1000) / obj[i].rate;
      } else if (obj[i].rate1 && Number(obj[i].qty) > 0) {
        // obj[i].rate = ((Number(obj[i].amount) * 1000) / obj[i].qty)?.toFixed(4);
        obj[i].rate = (Number(obj[i].amount) * 1000) / obj[i].qty;
        if (obj[i].rate?.toString()?.length > 7) {
          obj[i].rate = ((Number(obj[i].amount) * 1000) / obj[i].qty)?.toFixed(4);
        } else {
          obj[i].rate = (Number(obj[i].amount) * 1000) / obj[i].qty;
        }
      }
    }

    setRows(obj);
    handleTotal(obj);
  };

  const handleTotal = (obj) => {
    let sum = 0;
    obj &&
      obj.map((data) => {
        sum += Number(data.amount);
      });

    if (isNaN(sum)) {
      sum = Number(0);
    }

    setTotal(Number(sum.toFixed(3)));
  };

  const handleSave = () => {
    setSalesInfo({...salesInfo, errShow: true});
    let msg1 = 'Please select account';
    let msg2 = 'Please select customer';
    if (!acc?.value && !salesInfo?.customer?.value) {
      setErrorMsg({
        account: msg1,
        customer: msg2
      });
      return;
    }
    if (!acc?.value) {
      setErrorMsg({account: msg1});
      return;
    }
    if (!salesInfo?.customer?.value) {
      setErrorMsg({customer: msg2});
      return;
    }
    let err = rows.some((a) => !a.product?.id || a.qty <= 0 || !a.qty || a.qty == Infinity);
    if (rows.length != 0 && !err) {
      let date1 = moment(salesInfo.date[0]).format('YYYY-MM-DD');
      salesInfo.date1 = date1;
      salesInfo.account = acc;
      salesInfo.total = total;
      dispatch(saveSales(rows, salesInfo, navigate, delList, heading));
      setErrorMsg('	');
    }
  };

  const tog_invoice = () => {
    setmodal_invoice(!modal_invoice);
  };

  const togglePendingModal = () => {
    setPendingModal(!pendingModal);
  };

  const handleAddPendingItems = () => {
    let arr1 = salesResult?.pendingItemList.filter((a) => a.isCheck);

    let arr = arr1?.map((a) => {
      return {
        isPull: true,
        sodid: a.sodid,
        soid: a.soid,
        product: {value: a.productID, label: a?.productName, id: a.productID},
        oldQty: a.soQty,
        qty: a.penForInvQty,
        pendingQty: a.penForInvQty,
        rate: a.soRate,
        amount: Number(a.amount)?.toFixed(3),
        fix: a.isHold,
        stock: a?.availStock,
        unit: a?.unitName
      };
    });
    if (arr.length != 0) {
      if (rows.length == 0 || !rows[0].product) {
        setRows(arr);
        handleTotal(arr);
      } else {
        rows.push(...arr);
        handleTotal(rows);
      }
      setPendingModal(false);
    }
  };

  const handleSelect = (e, i) => {
    let obj = [...pendingItemList];
    obj[i].isCheck = e.target.checked;
    setPendingItemList(obj);
  };
  const handleSelectAll = (e, i) => {
    let obj = [...pendingItemList];
    if (obj.length != 0) {
      obj.map((a) => {
        if (!a.added) {
          a.isCheck = e.target.checked;
        }
      });
      setPendingItemList(obj);
    }
  };
  const handlePendingClose = () => {
    let arr = [...pendingItemList];
    setPendingModal(false);
    arr.map((a) => {
      a.isCheck = false;
      a.added = false;
    });
    setPendingItemList(arr);
  };

  const htmlTable = () => {
    return (
      <>
        <h5 className='tabView'>Past Orders {salesInfo?.customer.label ? ': ' + salesInfo?.customer.label : ''}</h5>
        {salesInfo?.customer.label ? (
          <GroupTable tableData={salesResult?.salesList} modTable={true} heading={heading} />
        ) : (
          <div className='text-center mt-5 pt-5'>No Data Available</div>
        )}
      </>
    );
  };

  const handleDate = (e) => {
    let d;
    if (e.length == 0) {
      d = new Date();
    } else {
      d = e;
    }

    setSalesInfo({
      ...salesInfo,
      date: d
    });
  };

  const handlePullData = () => {
    let arr = [...salesResult?.pendingItemList];

    if (rows.length != 0) {
      arr.map((a) => {
        rows &&
          rows?.map((b) => {
            if (b?.sodid == a?.sodid && b?.isPull) {
              a.added = true;
            }
          });
      });
      setPendingItemList(arr);
    } else {
      arr.map((a) => {
        a.added = false;
      });
      setPendingItemList(arr);
    }

    setPendingModal(true);
  };

  return (
    <div className='page-content'>
      <Modal
        isOpen={pendingModal}
        onClosed={() => handlePendingClose()}
        toggle={() => {
          togglePendingModal();
        }}
        centered
        size='lg'
      >
        <ModalHeader
          toggle={() => {
            togglePendingModal();
          }}
        >
          Pending Products for Invoice
        </ModalHeader>
        <ModalBody>
          <table cellPadding={3}>
            <tbody>
              <tr>
                <td className='w90'>
                  <input type='checkbox' onChange={(e) => handleSelectAll(e)} />
                </td>
                <td className='w90'> SNo.</td>
                <td className='w200'>Product</td>
                <td className='w200'> Date</td>
                <td className='w200'>Qty</td>
                <td className='w200'>Rate</td>
                <td className='w200'>Amount</td>
              </tr>
              {pendingItemList &&
                pendingItemList.map((a, i) => {
                  return (
                    <>
                      <tr>
                        <td>
                          <input
                            disabled={a?.added ? true : false}
                            type='checkbox'
                            className='cursor'
                            checked={a.isCheck}
                            onChange={(e) => handleSelect(e, i)}
                          />{' '}
                        </td>
                        <td>{a.soNumber}</td>
                        <td>{a?.productName}</td>
                        <td>{a.date} </td>
                        <td>
                          {a.penForInvQty}&nbsp; {a?.unitName}
                        </td>
                        <td>{a.soRate}</td>
                        <td>{Number(a.amount)?.toFixed(3)}</td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
          {pendingItemList.length == 0 ? <div className='text-center mt-3 pt-3'>No Data Available</div> : <></>}
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={() => setPendingModal(false)}>
            Cancel
          </Button>
          <Button color='primary' onClick={() => handleAddPendingItems()}>
            Add
          </Button>{' '}
        </ModalFooter>
      </Modal>
      <div className='row2'>
        {' '}
        <Breadcrumbs breadcrumbItem={editRow ? 'Edit ' + heading : 'New ' + heading} />
        <h5 className='deskView'>Past Orders {salesInfo?.customer.label ? ': ' + salesInfo?.customer.label : ''}</h5>
      </div>

      <Card>
        <CardBody className='h100'>
          <div className='row1'>
            <div className='wp65' id='leftTable'>
              <Row id='formRow'>
                <Col>
                  <Label> {heading.includes('Invoice') ? 'Invoice' : 'Order'} No.</Label>{' '}
                  <Input disabled={true} value={salesInfo?.nextSO} defaultValue={0} />
                </Col>
                <Col>
                  {' '}
                  <Label>{heading.includes('Invoice') ? 'Invoice' : 'Order'} Date</Label>
                  <Flatpickr
                    ref={fp}
                    className='form-control'
                    openMenuOnFocus={false}
                    options={{
                      altInput: true,
                      altFormat: 'd/m/Y',
                      disableMobile: true,
                      mode: 'single',
                      dateFormat: 'd/m/Y'
                    }}
                    value={salesInfo.date}
                    onChange={(e) => handleDate(e)}
                  />
                </Col>
                <Col>
                  {' '}
                  <Label>Customer</Label>{' '}
                  <Select
                    openMenuOnFocus={true}
                    minMenuHeight={400}
                    placeholder='Customer'
                    options={customerOptions}
                    // value={salesInfo?.customer}
                    defaultValue={salesInfo?.customer}
                    value={
                      customerOptions ? customerOptions.find((option) => option.id == salesInfo?.customer?.value) : salesInfo?.customer
                    }
                    onChange={(e) => handleCustomer(e)}
                  />
                  <span className='text-error'> {errorMsg?.customer}</span>
                </Col>
                <Col>
                  {' '}
                  <Label>{heading.includes('Purchase') ? 'Purchase ' : 'Sales '}Account</Label>
                  <Select
                    openMenuOnFocus={true}
                    placeholder='Account'
                    options={accResult?.accounts}
                    value={acc}
                    isDisabled={acc?.value}
                    noOptionsMessage={() => {
                      return (
                        <button className='btn btn-primary btn-block' onClick={() => tog_invoice()}>
                          Add new Account
                        </button>
                      );
                    }}
                    onChange={(e) => {
                      setAcc(e);
                      setErrorMsg({...errorMsg, account: ''});
                    }}
                  />
                  <span className='text-error'> {errorMsg?.account}</span>
                </Col>
                {heading?.includes('Invoice') && salesInfo.customer.value ? (
                  <Col>
                    <Button className='mt30' color={pendingItemList?.length > 0 ? 'primary' : 'secondary'} onClick={() => handlePullData()}>
                      Pull Data
                    </Button>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>

              <br />
              <div>
                <div className='table-responsive react-table table-containerpurchase'>
                  <Table cellPadding={2} border={1}>
                    <thead>
                      <tr>
                        <td>IsUnfix</td>
                        <td>Product</td>
                        <td>Stock</td>
                        <td>Qty</td>
                        <td>Unit</td>
                        <td>Rate</td>
                        <td>Amount</td>
                        <td>Action</td>
                      </tr>
                    </thead>

                    {rows.length > 0 ? (
                      rows.map((a, i) => {
                        return (
                          <>
                            <tbody>
                              <tr>
                                <td className='text-center  align-middle'>
                                  <input type='checkbox' className='cursor' checked={a.fix} onChange={(e) => handleFix(e, i)} />
                                </td>
                                <td>
                                  <Select
                                    openMenuOnFocus={true}
                                    isDisabled={a?.sodid ? true : false}
                                    className='w200'
                                    placeholder='Product'
                                    options={prodOptions}
                                    defaultValue={a.product}
                                    value={prodOptions ? prodOptions.find((option) => option.id == a.product?.id) : a.product}
                                    onChange={(e) => handleProduct(e, i)}
                                  />
                                  {/* {salesInfo?.errShow && (
                                    <span className='text-error'> {a.product?.id ? '' : 'Please select product'}</span>
                                  )} */}
                                </td>
                                <td>
                                  <Input disabled={true} value={a.stock} />
                                </td>
                                <td>
                                  <Input
                                    type='number'
                                    placeholder='Enter Qty'
                                    disabled={a.product?.id ? false : true}
                                    value={a.qty}
                                    onChange={(e) => handleQty(e, i)}
                                    onBlur={(e) => handleBlurQty(e, i)}
                                  />
                                  {salesInfo?.errShow && (a.qty <= 0 || a.qty == Infinity) && (
                                    <span className='text-error'> Please enter Qty</span>
                                  )}
                                </td>
                                <td>
                                  <Input disabled={true} value={a.unit} placeholder='unit' />
                                </td>
                                <td>
                                  <Input
                                    type='number'
                                    disabled={a.fix || !a.product?.id ? true : false}
                                    value={isNaN(a.rate) || a.rate == Infinity ? 0 : a.rate}
                                    onChange={(e) => handleRate(e, i)}
                                    onBlur={(e) => handleBlurRate(e, i)}
                                  />
                                </td>
                                <td>
                                  <Input
                                    type='number'
                                    disabled={!a.product?.id ? true : false}
                                    value={isNaN(a.amount) || a.amount == Infinity ? (0).toFixed(3) : a.amount}
                                    // value={a.amount}
                                    onChange={(e) => handleAmt(e, i)}
                                    onBlur={(e) => handleBlurAmt(e, i)}
                                  />
                                </td>

                                <td className=' text-center align-middle'>
                                  <button className='cursor p-0 border-0 bg-transparent text-primary' onClick={(e) => handleClear(e, i)}>
                                    <i className='bx bx-trash-alt text-danger font-size-18 cursor'></i>
                                  </button>{' '}
                                </td>
                              </tr>
                            </tbody>
                          </>
                        );
                      })
                    ) : (
                      <tbody>
                        <tr className='text-center mb-4 mt-4'>
                          <td colSpan={8} className=''>
                            No product added
                          </td>
                        </tr>
                      </tbody>
                    )}

                    <thead>
                      <tr>
                        <td className='cent'>
                          <button className='cursor p-0 border-0 bg-transparent text-primary' onClick={handleAddRow}>
                            <i className='bx bx-plus text-dark bg-light font-size-26 text=bold rounded-circle  cursor'></i>
                          </button>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total:</td>
                        <td>{total?.toFixed(3)}</td>
                        <td></td>
                      </tr>
                    </thead>
                  </Table>{' '}
                </div>

                {rows.length == 0 && <div className='text-error'>Please select atleast one row</div>}
                <br />
                <div className='w-100 d-none' id='downTable'>
                  {htmlTable()}
                </div>
                <div>
                  {' '}
                  &nbsp;&nbsp;
                  <Button color='secondary' onClick={() => navigate(-1)}>
                    Back
                  </Button>
                  &nbsp;&nbsp;
                  <Button color='primary' onClick={handleSave}>
                    Save
                  </Button>
                </div>
                <div className='whiteBox'></div>
              </div>
            </div>

            <div className='wp35' id='rightTable'>
              {htmlTable()}
            </div>
          </div>
        </CardBody>{' '}
      </Card>
      <AccountModal modal_invoice={modal_invoice} tog_invoice={() => tog_invoice()} setmodal_invoice={() => setmodal_invoice()} />
    </div>
  );
};

export default SalesNew;
