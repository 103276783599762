import React, {useEffect, useState} from 'react';
import {Button, Col, Input, Label, Row} from 'reactstrap';
import {downloadTrialList, getTrialBalanceList} from '../../../store/TrialBalance/actions';
import {useDispatch, useSelector} from 'react-redux';
import {getAccountsGroups, loaderStart} from '../../../store/actions';
import Select from 'react-select';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import {toast} from 'react-toastify';

function TrialBalanceOperation(props) {
  const [fileName, setFileName] = useState('');
  const [check, setCheck] = useState(false);

  let prevDate = moment().subtract(6, 'day');
  const [date, setDate] = useState([prevDate._d, new Date()]);
  const dispatch = useDispatch();
  const [selectedGroup, setSelectedGropu] = useState('');

  const ondownloadClick = (type) => {
    dispatch(downloadTrialList(date, fileName, type, selectedGroup?.value, selectedGroup?.label, check));
    setFileName('');
  };
  const handleReload = () => {
    (async () => {
      await dispatch(getAccountsGroups());
      await dispatch(getTrialBalanceList(check, date, selectedGroup?.value));
    })();
  };
  const {accountGroups} = useSelector((state) => ({
    accountGroups: state.accounts.accountGroups
  }));
  useEffect(() => {
    handleReload();
  }, [selectedGroup]);

  const handleDate = (e) => {
    if (e.length == 0) {
      setDate([prevDate._d, new Date()]);
    } else {
      setDate(e);
    }
    if (e.length > 1) {
      dispatch(getTrialBalanceList(check, e, selectedGroup?.value));
    }
  };

  const handleDateClose = (e) => {
    if (date.length != 2) {
      let defDate = [e[0], e[0]];
      setDate(defDate);
      if (!selectedGroup?.value) {
        toast.info('Please select the selectedGroup');
        return;
      }
      dispatch(getTrialBalanceList(check, defDate, selectedGroup?.value));
    }
  };
  const handleCheck = (e) => {
    setCheck(e.target.checked);
    dispatch(getTrialBalanceList(e.target.checked, date, selectedGroup?.value));
  };
  return (
    <div className='position-relative'>
      <div className=' mt-2 row2'>
        <div className='row0'>
          <Input
            className='mx-1 w200'
            type='text'
            value={fileName}
            onChange={(e) => setFileName(e?.target?.value)}
            placeholder='Enter export file name...'
          />
          <Button onClick={() => ondownloadClick('pdf')}>PDF</Button> &nbsp;
          <Button onClick={() => ondownloadClick('csv')}> CSV</Button> &nbsp;
          <Button
            style={{
              background: 'none',
              color: '#495057',
              border: '1px solid #eff0f2'
            }}
            onClick={() => handleReload()}
          >
            <span className='bx bx-revision font-size-18'></span>
          </Button>{' '}
          <Flatpickr
            className='form-control w220 mx-1'
            placeholder='Date Range'
            disabled={!check}
            value={date}
            options={{
              mode: 'range',
              disableMobile: true,
              dateFormat: 'd/m/Y '
            }}
            onChange={(e) => handleDate(e)}
            onClose={handleDateClose}
          />
          <Select
            className='w200'
            placeholder='Select Group Name'
            value={selectedGroup}
            isDisabled={!check}
            onChange={(selectedOption) => {
              setSelectedGropu(selectedOption);
            }}
            isSearchable={true}
            options={accountGroups?.map((value) => ({
              value: value.id,
              label: value.groupName
            }))}
            name='groupName'
            isLoading={false}
            loadingMessage={() => 'Fetching Group Name'}
            noOptionsMessage={() => 'Groups appears here'}
            openMenuOnFocus={true}
          />
          {/* <Input type='checkbox' className='mx-1' checked={check} onChange={handleCheck} /> */}
          <div className='ms-3'>
            <Input type='checkbox' value={check} id='checkbox_id' checked={check} onChange={handleCheck} />
            <Label for='checkbox_id' className='ms-2 cursor'>
              Apply Filter
            </Label>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default TrialBalanceOperation;
