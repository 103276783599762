import {LOADER_START, LOADER_STOP, ALL_CITY, GET_SUPPLIER, COMMON_ERROR} from './actionTypes';
import {errorHandlers} from './../../helpers/errorHandlers';
import axios from 'axios';

import {CITY_LIST, SUPPLIER_LIST} from '../apiRoutes';
export const loaderStart = () => async (dispatch) => {
  dispatch({
    type: LOADER_START,
    payload: true
  });
};

export const loaderStop = () => async (dispatch) => {
  dispatch({
    type: LOADER_STOP,
    payload: false
  });
};

export const getCity = () => async (dispatch) => {
  dispatch(loaderStart());
  axios
    .get(CITY_LIST)
    .then(async (response) => {
      await dispatch(loaderStop());
      await dispatch({
        type: ALL_CITY,
        payload: response
      });
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};

export const getSupplier = () => async (dispatch) => {
  dispatch(loaderStart());
  axios
    .get(SUPPLIER_LIST)
    .then((response) => {
      response.map((a) => {
        a.value = a.id;
        a.label = a.accountName;
      });
      dispatch(loaderStop());
      dispatch({
        type: GET_SUPPLIER,
        payload: response
      });
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};
