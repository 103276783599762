import axios from 'axios';
import {toast} from 'react-toastify';

import {
  ADD_TRANJACTION_DATA,
  CHANGE_TRANSACTION_LEASER,
  CHANGE_TRANSACTION_STATUS,
  CHECK_TRANJACTION_PASS,
  CITY_LIST,
  DELETE_TRANJACTION_DATA,
  DOWNLOAD_TRANJACTION_DATA,
  DOWNLOAD_TRANJACTION_DATA_CSV,
  GET_TRANJACTION_DATA
} from '../apiRoutes';

import {errorHandlers} from './../../helpers/errorHandlers';
import {loaderStart, loaderStop} from '../actions';
import {GET_CITY, GET_TRANSAJCTION, CHECK_PASSWORD_TRAN, CHECK_PASSWORD_TRAN_FALSE, GET_LEADER_DATA} from './actionTypes';
export const addTransactionData = (transactionData, onclearValue, fp, activeTab2) => async (dispatch) => {
  dispatch(loaderStart());

  axios
    .post(ADD_TRANJACTION_DATA, transactionData)
    .then((response) => {
      // dispatch(getTransactionData())
      // dispatch(getAccountsGroups())
      // dispatch(getAccountsCounts())
      // dispatch({
      //   type: CHECK_PASSWORD_TRAN_FALSE,
      //   payload: response
      // });

      dispatch(loaderStop());
      if (transactionData?.id) {
        toast.success('Transaction updated successfully', {autoClose: 500});
      } else {
        toast.success('Transaction added successfully', {autoClose: 500});
      }
      setTimeout(() => {
        onclearValue();
        // setCommList([...commList, obj]);
        fp.current.flatpickr.altInput.focus();
        fp.current.flatpickr.close();
        let active;
        let isdelte;
        if (activeTab2 == 1) {
          active = '';
          isdelte = 0;
        } else if (activeTab2 == 2) {
          active = '2';
          isdelte = 0;
        } else if (activeTab2 == 3) {
          active = '1';
          isdelte = '0';
        } else {
          active = '';
          isdelte = 1;
        }
        dispatch(getTransactionData(transactionData?.tranDate, active, isdelte));
      }, '600');
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};

export const deleteTransactionData = (transactionData, active, isdelete) => async (dispatch) => {
  dispatch(loaderStart());

  axios
    .post(DELETE_TRANJACTION_DATA + '/' + transactionData?.id)
    .then((response) => {
      dispatch(loaderStop());
      // dispatch(getTransactionData())
      // dispatch(getAccountsGroups())
      // dispatch(getAccountsCounts())
      dispatch(getTransactionData(transactionData?.tranDate, active, isdelete));
      toast.success('Transaction deleted successfully');
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};
export const getTransactionData = (from, statusData, Isdelete) => async (dispatch) => {
  dispatch(loaderStart());
  if (statusData === undefined || statusData === null) {
    statusData = '';
  }
  axios
    .get(GET_TRANJACTION_DATA + '?fromDate=' + from + '&toDate=' + from + '&TranStatus=' + statusData + '&IsDeleted=' + Isdelete)
    .then((response) => {
      dispatch(loaderStop());
      dispatch({
        type: GET_TRANSAJCTION,
        payload: response
      });
      // dispatch(getAccounts())
      // dispatch(getAccountsGroups())
      // dispatch(getAccountsCounts())
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};
export const getCityTran = () => async (dispatch) => {
  dispatch(loaderStart());
  axios
    .get(CITY_LIST)
    .then(async (response) => {
      await dispatch(loaderStop());
      await dispatch({
        type: GET_CITY,
        payload: response
      });
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};

export const transactionPassword = (pass, toggle, setIsPast, isType, handleUnlock, lockType,compType) => async (dispatch) => {
  dispatch(loaderStart());
  axios
    .post(CHECK_TRANJACTION_PASS, {
      tranPswd: pass?.password
    })
    .then(async (response) => {
      if (response == 1) {
        !lockType &&
          (await dispatch({
            type: CHECK_PASSWORD_TRAN,
            payload: isType
          }));
if(!compType){
  compType="Transaction"
}
        toast.info(compType + ' unblocked ');
        toggle(false);
        setIsPast && setIsPast(false);
        if (handleUnlock) handleUnlock(lockType);
      } else {
        toast.info('Please enter correct password ');
      }
      await dispatch(loaderStop());
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};
export const transactionOkStatus = (id, date, tab) => async (dispatch) => {
  dispatch(loaderStart());
  axios
    .get(CHANGE_TRANSACTION_STATUS + '?ID=' + id)
    .then(async (response) => {
      dispatch(getTransactionData(date, tab, 0));
      await dispatch(loaderStop());
      toast.success('Transaction status changed successfully');
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};
export const getLeaderDAta = (id, type) => async (dispatch) => {
  if (id == '' || id == null) {
    return;
  }
  dispatch(loaderStart());

  axios
    .get(CHANGE_TRANSACTION_LEASER + '?Tranid=' + id + '&TranType=' + type)
    .then(async (response) => {
      await dispatch(loaderStop());
      await dispatch({
        type: GET_LEADER_DATA,
        payload: response
      });
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};

export const downloadTransactionData = (from, statusData, Isdelete, type,tStatus) => async (dispatch) => {
  dispatch(loaderStart());
  if (statusData === undefined || statusData === null) {
    statusData = '';
  }
  let url;
  if (type === 'pdf') {
    url = DOWNLOAD_TRANJACTION_DATA;
  } else {
    url = DOWNLOAD_TRANJACTION_DATA_CSV;
  }

  let isStatusDis = tStatus ? 0 :1;

  axios
    .get(url + '?fromDate=' + from + '&toDate=' + from + '&TranStatus=' + statusData + '&IsDeleted=' + Isdelete+
    '&isStatusDisabled=' +
    isStatusDis, {
      responseType: 'blob'
    })
    .then((response) => {
      dispatch(loaderStop());
      const url = URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = url;
      // eslint-disable-next-line no-useless-concat
      link.download = 'Transaction Report' + `.${type}`;
      link.click();
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};
