import React from 'react';
import {Row, Col, Container, Card, CardBody, Input, Button, Nav, NavItem, NavLink} from 'reactstrap';

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import GroupTable from './PartyStockTable';
import {useSelector} from 'react-redux';
import PartyStockOperations from './partyStockOperation';

const PartyStock = () => {
  const partyStockResult = useSelector((state) => state.partyStockReducer);

  document.title = 'Party stock - account-books';

  return (
    <>
      <div className='page-content'>
        <Container fluid={true}>
          <Breadcrumbs title='Party Stock Report' breadcrumbItem='Party Stock Report' />

          <Row>
            <Col lg={12}>
              <Card style={{minHeight: '350px'}}>
                <CardBody>
                  <PartyStockOperations />
                  <div id='table-invoices-list'>
                    <GroupTable
                      tableData={partyStockResult?.partyStockList?.partyStocks}
                      tableDataExtra={partyStockResult?.partyStockList}
                      //   handleEdit={handleEdit}
                      //   handleDelete={handleDelete}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PartyStock;
