import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

function DeleteModal(props) {
  return (
    <>
      <Modal isOpen={props?.modal} toggle={props?.toggle}>
        <ModalHeader toggle={props?.toggle}>Delete Journal </ModalHeader>
        <ModalBody>Are you sure you want to delete this journal ?</ModalBody>

        <ModalFooter>
          <Button color='primary' onClick={(data) => props?.deleteUserAccount(props?.deleteJournalData)}>
            Delete
          </Button>{' '}
          <Button color='secondary' onClick={props?.toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default DeleteModal;
