import React, {useState, useRef, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button} from 'reactstrap';
//redux
import {useSelector, useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import withRouter from '../../components/Common/withRouter';
// Formik validation
import * as Yup from 'yup';
import {useFormik} from 'formik';
// actions
import {loginUser, blockUnBlockUser} from '../../store/actions';
// import images
import logo from '../../assets/images/ACCBOOK_white.svg';
//Import config
const Login = (props) => {
  document.title = 'Login | Account-book';
  const [num1, setNum1] = useState(0);
  const [num2, setNum2] = useState(0);
  const [operator, setOperator] = useState('+');
  const [answer, setAnswer] = useState(0);
  const [userInput, setUserInput] = useState('');
  const [isCorrect, setIsCorrect] = useState(null);
  const [passwordCount, setPasswordCount] = useState(1);
  const [captchaMessage, setCaptchaMessage] = useState('Your captcha is empty. Please try again');
  let countAttempt = useRef(1);

  const dispatch = useDispatch();
  const {error, loading} = useSelector((state) => ({
    error: state.login.error,
    loading: state.login.loading
  }));

  const refreshButton = () => {
    setIsCorrect(null);
    setCaptchaMessage('');
    generateCaptcha();
  };
  useEffect(() => {
    generateCaptcha();
    setIsCorrect(null);
  }, []);

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    const operators = ['+', '-', '*'];
    const operator = operators[Math.floor(Math.random() * operators.length)];
    let answer;
    switch (operator) {
      case '+':
        answer = num1 + num2;
        break;
      case '-':
        answer = num1 - num2;
        break;
      case '*':
        answer = num1 * num2;
        break;
      default:
        break;
    }
    setNum1(num1);
    setNum2(num2);
    setOperator(operator);
    setAnswer(answer);
    setUserInput('');
  };
  const handleChange = (e) => {
    setUserInput(e.target.value);
    if (e.target.value === '') {
      setIsCorrect(false);
      setCaptchaMessage('Your captcha is empty. Please try again');
    } else if (+e.target.value === answer) {
      setIsCorrect(null);
      setCaptchaMessage('');
    } else {
      setIsCorrect(false);
      setCaptchaMessage('You have enterted an invalid Captcha. Please try again.');
    }
  };

  const validateBlockOnThreeAttempt = useCallback(
    (field, data = null) => {
      const blockUserObj = {isUserBlocked: true, Email: data?.Username};

      if (field === 'password') {
        if (passwordCount === 3 && data) {
          setCaptchaMessage(
            'Your 3 attempt is failed.your profile has been blocked by the Administrator for security purposes.Please contact the administrator for more details.'
          );
          dispatch(blockUnBlockUser(blockUserObj, props.router.navigate));
          setPasswordCount(0);
        }
        return;
      } else if (field === 'captcha') {
        setCaptchaMessage(
          'Your 3 attempt is failed.your profile has been blocked by the Administrator for security purposes.Please contact the administrator for more details.'
        );

        dispatch(blockUnBlockUser(blockUserObj, props.router.navigate));
        countAttempt.current = 0;
        return;
      }
    },
    [dispatch, props, passwordCount]
  );

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      Username: '' || '',
      password: '' || ''
    },
    validationSchema: Yup.object({
      Username: Yup.string().required('Please Enter Your Username'),
      password: Yup.string().required('Please Enter Your Password')
    }),
    onSubmit: (values) => {
      if (parseInt(userInput, 10) === answer) {
        setIsCorrect(true);
        dispatch(loginUser(values, props.router.navigate));
        setPasswordCount(passwordCount + 1);
        validateBlockOnThreeAttempt('password', values);
      } else if (userInput !== '' && parseInt(userInput, 10) !== answer) {
        if (countAttempt.current === 3) {
          validateBlockOnThreeAttempt('captcha', values);
        } else {
          countAttempt.current += 1;
          setCaptchaMessage('You have enterted an invalid Captcha. Please try again.');
          setIsCorrect(false);
        }
      } else {
        setIsCorrect(false);
      }
    }
  });

  useEffect(() => {
    let pwdErrorMsgBlocked = error.includes('administrator');
    if (pwdErrorMsgBlocked) {
      setCaptchaMessage();
    }
  }, [error]);

  return (
    <React.Fragment>
      <div className='authentication-bg min-vh-100'>
        <div className='bg-overlay'></div>
        <Container>
          <div className='d-flex flex-column min-vh-100 px-3 pt-4'>
            <Row className='justify-content-center my-auto'>
              <Col md={8} lg={6} xl={5}>
                <div className='text-center mb-4'>
                  <Link to='/'>
                    <img src={logo} alt='' height='45' /> <span className='logo-txt text-light'></span>
                  </Link>
                </div>
                <Card>
                  <CardBody className='p-4'>
                    <div className='text-center mt-2'>
                      <h5 className='text-primary'>Welcome Back !</h5>
                      <p className='text-muted'>Sign in to continue to Account-Books.</p>
                    </div>
                    <div className='p-2 mt-4'>
                      <Form
                        className='form-horizontal'
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {error ? (
                          <Alert color='danger'>
                            <div>{error}</div>
                          </Alert>
                        ) : null}
                        <div className='mb-3'>
                          <Label className='form-label'>Username</Label>
                          <Input
                            name='Username'
                            className='form-control'
                            placeholder='Enter Username'
                            type='text'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.Username || ''}
                            invalid={validation.touched.Username && validation.errors.Username ? true : false}
                          />
                          {validation.touched.Username && validation.errors.Username ? (
                            <FormFeedback type='invalid'>
                              <div>{validation.errors.Username}</div>
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className='mb-3'>
                          <Label className='form-label'>Password</Label>
                          <Input
                            name='password'
                            value={validation.values.password || ''}
                            type='password'
                            placeholder='Enter Password'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              error && isCorrect
                                ? validation.touched.password
                                : validation.touched.password && validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type='invalid'>
                              <div> {validation.errors.password} </div>
                            </FormFeedback>
                          ) : null}
                          {error && <p className='text-error'>{captchaMessage} </p>}
                        </div>
                        <div className='form-check'>
                          <input type='checkbox' className='form-check-input' id='customControlInline' />
                          <label className='form-check-label' htmlFor='customControlInline'>
                            Remember me
                          </label>
                        </div>
                        <div className='row mt-3'>
                          <div className='row captcha-column'>
                            {' '}
                            <p className='equation'>
                              <span className='span-val'>
                                {num1} {operator} {num2} ={' '}
                              </span>
                              <Input
                                name='captcha'
                                value={userInput}
                                type='number'
                                onChange={handleChange}
                                invalid={isCorrect === false && true}
                              />
                              <Button
                                style={{
                                  background: 'none',
                                  color: '#495057',
                                  border: '1px solid #EFF0F2'
                                }}
                                onClick={() => refreshButton()}
                                // disabled={!account?.value}
                              >
                                <span className='bx bx-revision font-size-18'></span>
                              </Button>
                            </p>
                            {isCorrect === false && <p className='text-error'>{captchaMessage} </p>}
                          </div>
                          {/* <div className='col-6'>
                            <div className='main-captcha'>
                              <Input
                                name='password'
                                value={userInput}
                                type='number'
                                placeholder='Enter captcha'
                                onChange={handleChange}
                                invalid={isCorrect === false && true}
                              />
                            </div>
                            {isCorrect === false && <p className='text-error'>{captchaMessage} </p>}
                          </div> */}
                        </div>
                        <div className='text-end mt-3'>
                          <button className='btn btn-primary btn-block' type='submit' disabled={loading}>
                            {loading ? 'loading...' : 'Log In'}
                          </button>
                        </div>
                        {/* <div className='mt-4 text-center'>
                          <Link to='/forgot-password' className='text-muted'>
                            <i className='mdi mdi-lock me-1' />
                            Forgot your password?
                          </Link>
                        </div> */}
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className='mt-5 text-center text-white-50'>
                  {/* <p>
										Don&#39;t have an account ?{" "}
										<Link to="/register" className="fw-medium text-primary">
											{" "}
											Signup now{" "}
										</Link>{" "}
									</p> */}
                  {/* <p>
                    © {new Date().getFullYear()}
                    <i className='mdi mdi-heart text-danger' /> Account-Books
                  </p> */}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(Login);
Login.propTypes = {
  history: PropTypes.object
};
