import {POST_BB_LIST, POST_BB_SAVE, POST_BB_DELETE, POST_BB_COMM_LIST, POST_BB_COMM_SAVE, POST_BB_COMM_DELETE} from './actionTypes';

const initialState = {
  error: '',
  bankBookList: [],
  commList: []
};

const bankBookReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_BB_LIST:
      state = {
        ...state,
        error: '',
        bankBookList: action.payload
      };
      break;

    case POST_BB_SAVE:
      state = {
        ...state,
        error: '',
        bankBookList: []
      };
      break;

    case POST_BB_DELETE:
      state = {
        ...state,
        error: ''
      };
      break;

    case POST_BB_COMM_LIST:
      state = {
        ...state,
        error: '',
        commList: action.payload
      };
      break;
    case POST_BB_COMM_SAVE:
      state = {
        ...state,
        error: ''
      };
      break;
    case POST_BB_COMM_DELETE:
      state = {
        ...state,
        error: ''
      };
      break;

    default:
      state = {...state};
      break;
  }
  return state;
};

export default bankBookReducer;
