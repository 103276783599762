import axios from 'axios';
import {loaderStart, loaderStop} from '../actions';
import {PRODUCTS_FETCH_SUCCESS, PRODUCTS_TYPE_LIST, PRODUCTS_UNIT_LIST, PRODUCT_CODE} from './actionType';
import {errorHandlers} from '../../helpers/errorHandlers';
import {toast} from 'react-toastify';
import {getProductListActive} from '../Products/actions';

export const getProducts = async (dispatch) => {
  dispatch(loaderStart());
  axios
    .get(`/Products/GetList`)
    .then(async (response) => {
      await dispatch(loaderStop());
      await dispatch({
        type: PRODUCTS_FETCH_SUCCESS,
        payload: response
      });
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};

export const unitList = async (dispatch) => {
  dispatch(loaderStart());
  axios
    .get(`/units/GetList`)
    .then(async (response) => {
      await dispatch(loaderStop());
      await dispatch({
        type: PRODUCTS_UNIT_LIST,
        payload: response
      });
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};
export const typeList = async (dispatch) => {
  dispatch(loaderStart());
  axios
    .get(`/productType/GetList`)
    .then(async (response) => {
      await dispatch(loaderStop());
      await dispatch({
        type: PRODUCTS_TYPE_LIST,
        payload: response
      });
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};
export const productCode = async (dispatch) => {
  dispatch(loaderStart());
  axios
    .get(`/CompanyConfiguration/GetNextPCODE?code=PCODE`)
    .then(async (response) => {
      await dispatch(loaderStop());
      await dispatch({
        type: PRODUCT_CODE,
        payload: response
      });
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};

export const saveProducts = (data) => async (dispatch) => {
  dispatch(loaderStart());

  axios
    .post('/products/SaveProducts', data)
    .then((response) => {
      dispatch(loaderStop());
      dispatch(getProducts);
      dispatch(productCode);
      if (data?.id) {
        toast.success('Product updated successfully');
        dispatch(getProductListActive());
      } else {
        toast.success('Product added successfully');
        dispatch(getProductListActive());
      }
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};

export const deleteProduct = (id) => async (dispatch) => {
  dispatch(loaderStart());

  axios
    .post(`/products/DeleteProducts/${id}`)
    .then((response) => {
      dispatch(loaderStop());
      dispatch(getProducts);
      toast.success('Product deleted successfully');
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};
