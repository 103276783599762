import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {downloadLedgerList, getLedgerList} from '../../../store/Ledger/actions';
import {toast} from 'react-toastify';
import moment from 'moment';
import {Button, Col, Input, Label, Row} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';
import {REMOVE_LEADER_DATA} from '../../../store/City/actionTypes';
import {getAccounts, loaderStop} from '../../../store/actions';
function LeaderOperations(props) {
  const [account, setAccount] = useState('');
  let prevDate = moment().subtract(6, 'day');
  const [fileName, setFileName] = useState();
  const [date, setDate] = useState([prevDate._d, new Date()]);
  const dispatch = useDispatch();
  const {ledgerResult, narration, setNarration} = props;

  const {accounts} = useSelector((state) => ({
    accounts: state.accounts.accounts
  }));
  const handleAccount = (e) => {
    setAccount(e);
    handleGetReport(date, e);
  };
  const handleGetReport = (date, acc) => {
    dispatch(getLedgerList(date, acc?.value));
  };
  useEffect(() => {
    handleReload();
    return () => {
      setAccount('');
      dispatch({
        type: REMOVE_LEADER_DATA
      });
    };
  }, []);

  const handleReload = () => {
    dispatch(getAccounts());

    if (!account?.value) {
      // dispatch(loaderStop());
      return;
    } else {
      dispatch(getLedgerList(date, account.value));
    }
  };
  const ondownloadClick = (type) => {
    if (!account?.value) {
      toast.info('Please select account');
    } else {
      const narrations = narration ? 1 : 0;
      dispatch(downloadLedgerList(date, account?.value, narrations, fileName, type, account?.label));
      setFileName('');
    }
  };

  const handleDate = (e) => {
    setDate(e);
    if (e.length > 1) {
      if (!account?.value) {
        toast.info('Please select the account');
        return;
      }
      handleGetReport(e, account);
    }
  };

  const handleDateClose = (e) => {
    if (date.length != 2) {
      let defDate = [e[0], e[0]];
      setDate(defDate);
      if (!account?.value) {
        toast.info('Please select the account');
        return;
      }
      handleGetReport(defDate, account);
    }
  };

  return (
    <div className='position-relative'>
      <div>
        <Row>
          <Col className='mt-3 auto'>
            <div className='d-flex'>
              <Input
                className='me-2 w-50'
                type='text'
                value={fileName}
                onChange={(e) => setFileName(e?.target?.value)}
                placeholder='Enter export file name...'
              />
              <Button onClick={() => ondownloadClick('pdf')}>PDF</Button> &nbsp;
              <Button onClick={() => ondownloadClick('csv')}> CSV</Button> &nbsp;
              <Button
                style={{
                  background: 'none',
                  color: '#495057',
                  border: '1px solid #eff0f2'
                }}
                onClick={() => handleReload()}
                disabled={!account?.value}
              >
                <span className='bx bx-revision font-size-18'></span>
              </Button>{' '}
            </div>
          </Col>
          <Col className='mt-3 auto' md='4' sm='8'>
            <div className='row'>
              <div className='col-md-6'>
                <Flatpickr
                  className='form-control w-100 mb-3'
                  placeholder='Date Range'
                  value={date}
                  options={{
                    mode: 'range',
                    disableMobile: true,
                    dateFormat: 'd/m/Y '
                  }}
                  onChange={(e) => handleDate(e)}
                  onClose={(e) => handleDateClose(e)}
                />
              </div>
              <div className='col-md-5'>
                <Select
                  className='w-100'
                  openMenuOnFocus={true}
                  placeholder='Select Account'
                  options={accounts}
                  value={account}
                  onChange={(e) => handleAccount(e)}
                />
              </div>
            </div>
          </Col>
          <Col className='mt-3 auto'>
            <Input className='me-2' type='checkbox' checked={narration} id='checkbox_id' onChange={(e) => setNarration(e.target.checked)} />
            <Label for='checkbox_id' className='me-4  cursor'>
              Narration
            </Label>{' '}
            {/* <Input
              className='me-2'
              type='checkbox'
              checked={isCommision}
              id='checkbox_id'
              onChange={(e) => setIsCommision(e.target.checked)}
            />
            <Label for='checkbox_id' className='me-4  cursor'>
              Is Commision
            </Label>{' '} */}
          </Col>
          <Col className='mt-3 auto mb-3'>
            {ledgerResult.ledgerList?.totalAmount && (
              <span className='mt-2 fw-bold'>
                {' '}
                <span className='me-2'>Total Balance : </span>
                <span
                  className={ledgerResult.ledgerList?.drAmount - ledgerResult.ledgerList?.crAmount > 0 ? 'text-danger' : 'text-success'}
                >
                  {ledgerResult.ledgerList?.totalAmount}
                </span>
              </span>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default LeaderOperations;
