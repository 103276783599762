import React, {Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTable, useGlobalFilter, useAsyncDebounce, useSortBy, useFilters, useExpanded, usePagination} from 'react-table';
import {Table, Row, Col, Button, Input, NavItem, Nav, NavLink} from 'reactstrap';
import {Filter, DefaultColumnFilter} from './filters';

// Define a default UI for filtering
function GlobalFilter({preGlobalFilteredRows, globalFilter, setGlobalFilter}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  useEffect(() => {
    if (globalFilter == undefined) {
      setValue(globalFilter);
    }
  }, [globalFilter]);

  return (
    <Col sm={3} md={2} lg={2}>
      <div className='search-box me-2 mb-2 d-inline-block'>
        <div className='position-relative'>
          <label htmlFor='search-bar-0' className='search-label'>
            <span id='search-bar-0-label' className='sr-only'>
              Search this table
            </span>
            <input
              onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
              id='search-bar-0'
              type='text'
              className='form-control'
              placeholder={`${count} records...`}
              value={value || ''}
            />
          </label>
          <i className='bx bx-search-alt search-icon'></i>
        </div>
      </div>
    </Col>
  );
}

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  tableName,
  tableDataExtra,
  ondownloadClick,
  activeTab2,
  toggle2,
  customPageSizeOptions,
  transStatus
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: {pageIndex, pageSize}
  } = useTable(
    {
      columns,
      data,
      defaultColumn: {Filter: DefaultColumnFilter},
      initialState: {
        pageIndex: 0,
        pageSize: 500000
      }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );
  return (
    <Fragment>
      <Row className='mb-2 mt-3'>
        <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />

        {tableDataExtra === 'Transaction' && (
          <Col>
            <div className=' mb-4 transactions-pdf d-flex flex-wrap align-items-center'>
              <div className='transactions-pdf-left'>
                <Button onClick={() => ondownloadClick('csv')}> CSV</Button> <Button onClick={() => ondownloadClick('pdf')}> PDF</Button>{' '}
              </div>
              {transStatus?
               <div className='transactions-pdf-right'>
               <Nav pills className='navtab-bg nav-justified'>
                 <NavItem className={activeTab2 == '1' && 'bg-primary rounded'}>
                   <NavLink
                     className='cursor'
                     // className={classnames({
                     // 	active: activeTab2 === "1",
                     // })}
                     onClick={() => {
                       toggle2('1');
                     }}
                   >
                     {/* <span className='d-block d-sm-none'>
                       <i className='fas fa-home'></i>
                     </span> */}
                     <span className={activeTab2 == '1' && 'text-white'}>All</span>
                   </NavLink>
                 </NavItem>
                 <NavItem className={activeTab2 === '2' && 'bg-success rounded'}>
                   <NavLink
                     className='cursor'
                     // className={classnames({
                     // 	active: activeTab2 === "2",
                     // })}
                     onClick={() => {
                       toggle2('2');
                     }}
                   >
                     {/* <span className='d-block d-sm-none'>
                       <i className='far fa-user'></i>
                     </span> */}
                     <span className={activeTab2 == '2' && 'text-white'}>Approved</span>
                   </NavLink>
                 </NavItem>
                 <NavItem className={activeTab2 === '3' && 'bg-warning rounded'}>
                   <NavLink
                     className='cursor'
                     // className={classnames({
                     // 	active: activeTab2 === "3",
                     // })}
                     onClick={() => {
                       toggle2('3');
                     }}
                   >
                     {/* <span className='d-block d-sm-none'>
                       <i className='far fa-envelope'></i>
                     </span> */}
                     <span className={activeTab2 == '3' && 'text-white'}>Pending</span>
                   </NavLink>
                 </NavItem>
                 <NavItem className={activeTab2 == '4' && 'bg-danger rounded'}>
                   <NavLink
                     className='cursor'
                     // className={classnames({
                     // 	active: activeTab2 === "4",
                     // })}
                     onClick={() => {
                       toggle2('4');
                     }}
                   >
                     {/* <span className='d-block d-sm-none'>
                       <i className='fas fa-cog'></i>
                     </span> */}
                     <span className={activeTab2 == '4' && 'text-white'}>Deleted</span>
                   </NavLink>
                 </NavItem>
               </Nav>
             </div>
            :<div></div>}
             
            </div>
          </Col>
        )}
      </Row>
      {page.length > 0 ? (
        <div className='table-responsive react-table customScrollAttach transTable'>
          <Table bordered hover {...getTableProps()} className={className}>
            <thead className='table-light table-nowrap'>
              {headerGroups.map((headerGroup) => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()} className='leaderSticky'>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} className={column?.headerClassName}>
                      {column.canGroupBy ? (
                        // If the column can be grouped, let's add a toggle
                        <span {...column.getGroupByToggleProps()} />
                      ) : null}
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.length > 0 ? (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <tr>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              key={cell.id}
                              {...cell.getCellProps()}
                              className={row?.original?.isDeleted && cell?.column?.Header !== 'Date' && 'deleted'}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    </Fragment>
                  );
                })
              ) : (
                <tr className='text-center'>
                  <p>No Data Available</p>
                </tr>
              )}
              {page.length > 0 && tableName == 'stockTable' && (
                <>
                  <tr>
                    <td className='text-end' colSpan={4}>
                      Total :
                    </td>
                    <td>{tableDataExtra?.totalBalance}</td>
                    <td></td>
                    <td>{tableDataExtra?.totalAmount}</td>
                  </tr>
                </>
              )}
              {page.length > 0 && tableName == 'partyStock' && (
                <>
                  <tr>
                    <td className='text-end' colSpan={2}>
                      Total:-
                    </td>
                    <td>{tableDataExtra?.totalQty}</td>
                    <td></td>
                    <td></td>
                    <td className=''>{tableDataExtra?.totalAmount}</td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className='text-center p-5'>No Data Available</div>
      )}
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any
};

export default TableContainer;
