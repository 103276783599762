import axios from 'axios';
// default
axios.defaults.baseURL = process.env.REACT_APP_SERVER_API;
// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common['Cache-Control'] = 'no-cache';

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response?.data ? response?.data : response;
  }
  // },
  // function (error) {
  // 	// Any status codes that falls outside the range of 2xx cause this function to trigger
  // 	if (error?.response?.status == 401) {
  // 		window.location.href = "/login"
  // 	}
  // 	return error
  // }
);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common['Authorization'] = token;
};

class APIClient {
  /**
   * Fetches data from given url
   */
  get = (url, params) => {
    return axios.get(url, params);
  };

  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };

  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.put(url, data);
  };

  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, {...config});
  };
}

const getLoggedinUser = () => {
  const user = localStorage.getItem('userData');
  if (!user) {
    return null;
  } else {
    const nuser = JSON.parse(user);
    setAuthorization(nuser?.token);
    return nuser;
  }
};

export {APIClient, setAuthorization, getLoggedinUser};
