import React, {useCallback, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

//Simple bar
import SimpleBar from 'simplebar-react';

// MetisMenu
import MetisMenu from 'metismenujs';
import {Link} from 'react-router-dom';

import {withTranslation} from 'react-i18next';
import withRouter from '../../components/Common/withRouter';

import SidebarData from './SidebarData';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
const SidebarContent = (props) => {
  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains('active')) {
        item.classList.remove('active');
      }
      if (parent) {
        const parent2El = parent.childNodes && parent.childNodes.lenght && parent.childNodes[1] ? parent.childNodes[1] : null;
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.remove('mm-show');
        }

        parent.classList.remove('mm-active');
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove('mm-show');

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove('mm-active'); // li
            parent3.childNodes[0].classList.remove('mm-active');

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove('mm-show'); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove('mm-show'); // li
                parent5.childNodes[0].classList.remove('mm-active'); // a tag
              }
            }
          }
        }
      }
    }
  };

  const location = useLocation();
  const activeMenu = useCallback(() => {
    const previousPath = location.state?.from || '/'; // Default to the root path if no previous path exists
    const pathName = props.router.location.pathname;
    let fullPath = pathName;
    if (fullPath == '/purchase/new' || fullPath == '/sales/new') {
      fullPath = previousPath;
    }
    let matchingMenuItem = null;
    const ul = document.getElementById('side-menu');
    const items = ul.getElementsByTagName('a');
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (fullPath === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.router.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu('#side-menu');
    activeMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }
  const menuChange = useSelector((state) => state.login.menuList);

  let localMenuList = [];

  let m1 = localStorage.getItem('menuList');
  if (m1) {
    localMenuList = JSON.parse(m1);
  }
  useEffect(() => {
    m1 = localStorage.getItem('menuList');
    if (m1) {
      localMenuList = JSON.parse(m1);
    }
  }, [menuChange]);

  let arr = [...SidebarData];
  arr.map((a, i) => {
    localMenuList?.map((b, j) => {
      if (a.url) {
        if (a?.url.includes(b?.path) && b?.isAllowed) {
          a.check = true;
        }
      } else if (a.subItem) {
        a?.subItem?.map((c) => {
          if (c?.link.includes(b?.path) && b?.isAllowed) {
            a.check = true;
            c.isCheck = true;
          }
        });
      }
    });
  });
  return (
    <React.Fragment>
      <SimpleBar ref={ref} className='sidebar-menu-scroll'>
        <div id='sidebar-menu'>
          <ul className='metismenu list-unstyled' id='side-menu'>
            {(arr || []).map((item, key) => (
              <React.Fragment key={key}>
                {item.isMainMenu ? (
                  <li className='menu-title'>{props.t(item.label)}</li>
                ) : (
                  item.check && (
                    <li key={key}>
                      <Link to={item.url ? item.url : '/#'} className={item.url || !item.check ? '' : 'has-arrow'}>
                        <i className={item.check && item.icon}></i>
                        <span className='menu-item'>{item.check && props.t(item.label)}</span>
                        {item.issubMenubadge && <span className={'badge rounded-pill ' + item.bgcolor}> {props.t(item.badgeValue)} </span>}
                      </Link>
                      {item.subItem && (
                        <ul className='sub-menu'>
                          {item.subItem.map(
                            (item, key) =>
                              item.isCheck && (
                                <li key={key}>
                                  <Link to={item.link} className={item.subMenu && item.isCheck && 'has-arrow'}>
                                    {item.isCheck && props.t(item.sublabel)}
                                  </Link>
                                  {/* {item.subMenu && (
                              <ul className='sub-menu'>
                                {item.subMenu.map((item, key) => (
                                  <li key={key}>
                                    <Link to='#'>{props.t(item.title)}</Link>
                                  </li>
                                ))}
                              </ul>
                            )} */}
                                </li>
                              )
                          )}
                        </ul>
                      )}
                    </li>
                  )
                )}
              </React.Fragment>
            ))}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
};

export default withRouter(withTranslation()(SidebarContent));
