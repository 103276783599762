import {GET_CASHBOOK} from './actionTypes';

const initialState = {
  cashbookData: {
    inwardCash: [
      {
        id: 3,
        companyID: 2,
        date: '2023-05-26T05:46:17.063',
        accountID: 143,
        groupID: 62,
        narration: 'test123',
        trans_type: 'I',
        createdDate: '0001-01-01T00:00:00',
        createdBy: 0,
        updatedDate: '0001-01-01T00:00:00',
        updatedBy: 0,
        deletedDate: '0001-01-01T00:00:00',
        deletedBy: 0,
        isDeleted: 0,
        cbNo: 1,
        cbAccount: 142,
        amount: 5500,
        fyid: 10,
        accountName: 'Metal Sales A/C',
        groupName: 'Sales Accounts'
      }
    ],
    outwardCash: [
      {
        id: 4,
        companyID: 2,
        date: '2023-05-26T05:46:17.063',
        accountID: 147,
        groupID: 43,
        narration: 'test123',
        trans_type: 'O',
        createdDate: '0001-01-01T00:00:00',
        createdBy: 0,
        updatedDate: '0001-01-01T00:00:00',
        updatedBy: 0,
        deletedDate: '0001-01-01T00:00:00',
        deletedBy: 0,
        isDeleted: 0,
        cbNo: 1,
        cbAccount: 142,
        amount: 7500,
        fyid: 10,
        accountName: 'IDFC',
        groupName: 'Bank Accounts'
      }
    ]
  }
};

const cashbook = (state = initialState, action) => {
  switch (action.type) {
    case GET_CASHBOOK:
      state = {
        ...state,
        cashbookData: action.payload
      };
      break;

    default:
      state = {...state};
      break;
  }
  return state;
};

export default cashbook;
