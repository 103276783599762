import React, {useEffect, useState} from 'react';
import {Col, Modal, Row} from 'reactstrap';
import TableContainer from '../../../components/Common/ReportWithoutPagination';
import {useDispatch, useSelector} from 'react-redux';
import {getLeaderDAta} from '../../../store/actions';
import moment from 'moment';
import {getLeaderTransaction} from '../../../store/Ledger/actions';

const EntryList = (args) => {
  const dispatch = useDispatch();
  const {reportType} = args;
  const {leaderData, LeaderEntry, JournalData, purchaseData, bankBookData, cashBookData} = useSelector((state) => ({
    leaderData: state?.ledgerReducer?.LeaderEntry[0],
    LeaderEntry: state?.ledgerReducer?.LeaderEntry,
    JournalData: state?.ledgerReducer?.LeaderEntry,
    purchaseData: state?.ledgerReducer?.LeaderEntry[0]?.purchaseInvoiceList,
    bankBookData: state?.ledgerReducer?.LeaderEntry,
    cashBookData: state?.ledgerReducer?.LeaderEntry
  }));

  const columns = [
    {
      Header: 'Date',
      Cell: (cell) => {
        return <div>{moment(cell?.cell?.row?.original.tranDate).format('DD/MM/YYYY')}</div>;
      },
      Filter: false
    },
    {
      Header: 'City',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.tCity}</div>;
      },
      Filter: false
    },
    {
      Header: 'Token',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original?.tokenDetail}</div>;
      },
      Filter: false
    },
    {
      Header: 'Amount',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original?.tranAmount}</div>;
      },
      Filter: false
    },
    {
      Header: 'Debit',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original?.debit}</div>;
      },
      Filter: false
    },
    {
      Header: 'D Rate',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original?.sRate}</div>;
      },
      Filter: false
    },
    {
      Header: 'D Commission',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original?.sCommission}</div>;
      },
      Filter: false
    },
    {
      Header: 'Credit',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original?.credit}</div>;
      },
      Filter: false
    },

    {
      Header: 'C Rate',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original?.rRate}</div>;
      },
      Filter: false
    },
    {
      Header: 'C Commission',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original?.rCommission}</div>;
      },
      Filter: false
    },
    {
      Header: 'Remarks',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original?.remarks}</div>;
      },
      Filter: false
    }
  ];

  const columnJournal = [
    {
      Header: 'Account Name	',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.accountName}</div>;
      },
      Filter: false
    },
    {
      Header: 'Amount',
      Cell: (cell) => {
        return (
          <div>
            {cell?.cell?.row?.original?.cdMode == 'C'
              ? cell?.cell?.row?.original?.amount + ' Cr'
              : cell?.cell?.row?.original?.amount + ' Dr'}
          </div>
        );
      },
      Filter: false
    }
  ];
  const columnCash = [
    {
      Header: 'Date',
      Cell: (cell) => {
        return <div>{moment(cell?.cell?.row?.original.date).format('DD/MM/YYYY')}</div>;
      },
      Filter: false
    },
    {
      Header: 'Token',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original?.narration}</div>;
      },
      Filter: false
    },
    {
      Header: 'Account Name',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.accountName}</div>;
      },
      Filter: false
    },
    {
      Header: 'Amount',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.amount}</div>;
      },
      Filter: false
    },
    {
      Header: 'Type',
      Cell: (cell) => {
        if (cell?.cell?.row?.original?.trans_type == 'I') {
          return <div>{'Inward'}</div>;
        } else if (cell?.cell?.row?.original?.trans_type == 'O') {
          return <div>{'Outward'}</div>;
        }
      },
      Filter: false
    }
  ];
  const columnSales = [
    {
      Header: 'Product',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.product}</div>;
      },
      Filter: false
    },
    {
      Header: 'Stock',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.availStock}</div>;
      },
      Filter: false
    },
    {
      Header: 'Qty',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.qty + ' ' + cell?.cell?.row?.original.unitName}</div>;
      },
      Filter: false
    },
    // {
    //   Header: 'Unit',
    //   Cell: (cell) => {
    //     return <div>{cell?.cell?.row?.original.unitName}</div>;
    //   },
    //   Filter: false
    // },
    {
      Header: 'Rate',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.rate}</div>;
      },
      Filter: false
    },
    {
      Header: 'Amount',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.amount}</div>;
      },
      Filter: false
    },
    {
      Header: 'Is Unfixed',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.isHold ? 'yes' : 'No'}</div>;
      },
      Filter: false
    }
    // {
    //   Header: 'customer',
    //   Cell: (cell) => {
    //     return <div>{cell?.cell?.row?.original.amount}</div>;
    //   },
    //   Filter: false
    // }
  ];

  const columnCashBook = [
    {
      Header: 'Date',
      Cell: (cell) => {
        return <div>{moment(cell?.cell?.row?.original.date).format('DD/MM/YYYY')}</div>;
      },
      Filter: false
    },
    {
      Header: 'Amount',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.amount}</div>;
      },
      Filter: false
    },
    {
      Header: 'Bank Account',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.bankAccountName}</div>;
      },
      Filter: false
    },
    {
      Header: 'Party Account',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.partyAccountName}</div>;
      },
      Filter: false
    },
    {
      Header: 'UTR',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.narration}</div>;
      },
      Filter: false
    },
    {
      Header: 'Type',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.trans_type == 'I' ? 'Inward' : 'Outward'}</div>;
      },
      Filter: false
    },
    {
      Header: 'Group Name',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.groupName}</div>;
      },
      Filter: false
    }

    // {
    //   Header: 'AMOUNT',
    //   Cell: (cell) => {
    //     return <div>{cell?.cell?.row?.original?.amount + cell?.cell?.row?.original?.cdMode == 'C' ? 'Cr' : 'Dr'}</div>;
    //   },
    //   Filter: false
    // }
  ];

  let columnsToShow;
  let ledgerVal;
  switch (reportType) {
    case 'Journal':
      columnsToShow = columnJournal || [];
      ledgerVal = LeaderEntry?.length ? JournalData : [];
      break;
    case 'Transaction':
      columnsToShow = columns || [];
      ledgerVal = [LeaderEntry] || [];
      break;
    case 'Sales - Metal':
      columnsToShow = columnSales;
      ledgerVal = LeaderEntry?.length ? leaderData?.salesInvoiceList : [];
      break;
    case 'Sales - Currency':
      columnsToShow = columnSales;
      ledgerVal = LeaderEntry?.length ? leaderData?.salesInvoiceList : [];
      break;

    case 'Purchase - Currency':
      columnsToShow = columnSales;
      ledgerVal = LeaderEntry?.length ? purchaseData : [];
      break;
    case 'Purchase - Metal':
      columnsToShow = columnSales;
      ledgerVal = LeaderEntry?.length ? purchaseData : [];
      break;
    case 'Bank Tran Journal':
      columnsToShow = columnCashBook || [];
      ledgerVal = [bankBookData] || [];
      break;
    case 'Cash Book':
      columnsToShow = columnCash || [];
      ledgerVal = [cashBookData] || [];
      break;
    default:
      columnsToShow = [];
      break;
  }

  useEffect(() => {
    if (args?.leaderId) {
      dispatch(getLeaderTransaction(args.leaderId, reportType));
    }
  }, [args.leaderId, args.reportType, dispatch, reportType]);
  const renderFA = (type) => {
    if (type == 'Purchase - Currency' || type == 'Purchase - Metal') {
      return (
        <>
          <Row className='ms-4 mb-2 mt-2'>
            <Col>Invoice No.</Col>
            <Col>Invoice Date</Col>
            <Col>Supplier</Col>
          </Row>
          <Row className='ms-4'>
            <Col>{leaderData?.piNumber}</Col>
            <Col>{moment(leaderData?.piDate).format('DD/MM/YYYY')}</Col>
            <Col>{leaderData?.supplier}</Col>
          </Row>
        </>
      );
    } else {
      if (type == 'Purchase - Currency' || type == 'Purchase - Metal' || type == 'Sales - Currency' || type == 'Sales - Metal') {
        return (
          <>
            <Row className='ms-4 mb-2   mt-2'>
              <Col>Invoice No</Col>
              <Col>Invoice Date</Col>
              <Col>Supplier</Col>
            </Row>
            <Row className='ms-4'>
              <Col>{leaderData?.siNumber}</Col>
              <Col>{moment(leaderData?.siDate).format('DD/MM/YYYY')}</Col>
              <Col>{leaderData?.customer}</Col>
            </Row>
          </>
          // <div className='ms-4'>
          //   {' '}
          //   <div className='d-flex'>
          //     <div className='me-3'>supplier</div> <div className='me-3'>{leaderData.supplier}</div>
          //     <div className='me-3'>supplier</div>
          //     <div className='me-3'>{leaderData.piDate}</div>
          //     <div className='me-3'>Number</div>
          //     <div className='me-3'>{leaderData.id}</div>
          //   </div>
          // </div>
        );
      }
    }
  };
  return (
    <Modal
      size='xl'
      isOpen={args?.leaderReport}
      toggle={() => {
        args?.handleChangeLeaderClose();
      }}
      className='addInvoiceModal'
      centered
    >
      <div className='modal-header'>
        <h5 className='modal-title' id='addInvoiceModalLabel'>
          Ledger Entries
        </h5>
        <button
          onClick={() => {
            args?.handleChangeLeaderClose();
          }}
          type='button'
          className='btn-close'
          aria-label='Close'
        ></button>
      </div>
      {renderFA(reportType)}

      <div className='modal-body '>
        <TableContainer
          columns={columnsToShow || []}
          data={ledgerVal || []}
          isGlobalFilter={true}
          customPageSize={20}
          className='Invoice table1'
        />
      </div>
    </Modal>
  );
};

export default EntryList;
