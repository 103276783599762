import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {Row, Col, CardBody, Card, Alert, Container} from 'reactstrap';
import FeatherIcon from 'feather-icons-react';

//redux
import {useSelector, useDispatch} from 'react-redux';

import {Link} from 'react-router-dom';
import withRouter from '../../components/Common/withRouter';

// import images
import logo from '../../assets/images/ACCBOOK_white.svg';

//Actions
import {getCompanyList, getCompanyToken} from '../../store/actions';

const Company = (props) => {
  document.title = 'Company | Accounts-books';

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanyList(props?.router?.navigate));
  }, [dispatch]);

  const {companyError, comapny, loading} = useSelector((state) => ({
    companyError: state.login.companyError,
    comapny: state.login.company,
    loading: state.login.loading
  }));

  const onCompanyClick = (companyData) => {
    dispatch(getCompanyToken(companyData?.companyID, props.router.navigate));
  };
  return (
    <React.Fragment>
      <div className='authentication-bg min-vh-100'>
        <div className='bg-overlay'></div>
        <Container>
          <div className='d-flex flex-column min-vh-100 px-3 pt-4'>
            <Row className='justify-content-center my-auto'>
              <Col md={8} lg={6} xl={5}>
                <div className='text-center mb-4'>
                  <Link to='/'>
                    <img src={logo} alt='' height='45' /> <span className='logo-txt text-light'></span>
                  </Link>
                </div>
                <Card>
                  {companyError ? (
                    <Alert color='danger'>
                      <div>{companyError}</div>
                    </Alert>
                  ) : null}
                  {loading ? (
                    <Alert color='danger'>
                      <div>"please wait to load the company data"</div>
                    </Alert>
                  ) : null}
                  <CardBody className='p-4'>
                    <div className='text-center mt-2'>
                      <h5 className='text-primary'>Choose Company !</h5>
                    </div>
                    <div className='p-2 mt-4'>
                      {comapny && comapny.length > 0
                        ? comapny.map((com) => {
                            return (
                              <button
                                onClick={() => onCompanyClick(com)}
                                type='button'
                                disabled={loading}
                                className='btn btn-primary btn-lg waves-effect waves-light mt-4 w-100 companyButton'
                                key={com?.companyName}
                              >
                                {com?.companyName}
                                <FeatherIcon icon='arrow-right' />
                                <span className='ml-5 p-2'></span>
                              </button>
                            );
                          })
                        : null}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Company);

Company.propTypes = {
  history: PropTypes.object
};
