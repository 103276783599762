import React, {useEffect, useState} from 'react';
import {Row, Col, Container, Card, CardBody} from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import GroupTable from './LedgerTable';
import {useDispatch, useSelector} from 'react-redux';
import LeaderOperations from './leaderOperations';
import {REMOVE_LEADER_DATA} from '../../../store/Ledger/actionTypes';
import EntryList from './entryList';

const Ledger = () => {
  document.title = 'Ledger Report - account-books';
  const dispatch = useDispatch();
  const ledgerResult = useSelector((state) => state.ledgerReducer);
  const [narration, setNarration] = useState(true);
  const [leaderReport, setLeaderReport] = useState(false);
  const [leaderId, setLeaderId] = useState('');
  const [reportType, setReportType] = useState('"Transaction"');

  useEffect(() => {
    return () => {
      dispatch({
        type: REMOVE_LEADER_DATA
      });
    };
  }, []);
  const handleChangeLeaderClose = () => {
    setLeaderId();
    setLeaderReport(false);
  };

  const handleChangeLeader = (id, type) => {
    setLeaderId(id);
    setReportType(type);
    setLeaderReport(true);
  };
  return (
    <>
      <div className='page-content'>
        <Container fluid={true}>
          <Breadcrumbs title='Ledger Report' breadcrumbItem='Ledger Report' />

          <Row>
            <Col lg={12}>
              <Card style={{minHeight: '350px'}}>
                <CardBody>
                  <LeaderOperations ledgerResult={ledgerResult} narration={narration} setNarration={setNarration} />
                  <div id='table-invoices-list'>
                    <GroupTable
                      tableData={ledgerResult?.ledgerList?.ledger?.length > 0 ? ledgerResult?.ledgerList?.ledger : []}
                      tableDataExtra={ledgerResult?.ledgerList}
                      handleFilter={narration}
                      handleChangeLeader={(id, type) => handleChangeLeader(id, type)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <EntryList
        leaderReport={leaderReport}
        handleChangeLeaderClose={() => handleChangeLeaderClose()}
        leaderId={leaderId}
        reportType={reportType}
      />
    </>
  );
};

export default Ledger;
