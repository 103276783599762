import axios from 'axios';
import {toast} from 'react-toastify';
import {GET_USERS, GET_USERS_SUCCESS, USER_ROLE_SUCCEESS} from './actionTypes';
import {GET_USERS_LIST} from '../../apiRoutes';
import {SAVE_USERS} from '../../apiRoutes';
import {loaderStart, loaderStop} from '../../actions';
import {errorHandlers} from '../../../helpers/errorHandlers';

export const getUsersList = () => async (dispatch) => {
  dispatch({
    type: GET_USERS,
    payload: ''
  });
  dispatch(loaderStart());
  axios
    .get(GET_USERS_LIST)
    .then((response) => {
      dispatch(loaderStop());
      dispatch({
        type: GET_USERS_SUCCESS,
        payload: response
      });
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};

export const saveUser = (userData) => async (dispatch) => {
  axios
    .post(SAVE_USERS, userData)
    .then((response) => {
      dispatch(getUsersList());
      if (userData?.id) {
        toast.success('User updated Successfully');
      } else {
        toast.success('User added successfully');
      }
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};

export const changePassword = (passwordData) => async (dispatch) => {
  dispatch(loaderStart());
  axios
    .post(`/User/ChangeUserPassword`, passwordData)
    .then((response) => {
      dispatch(loaderStop());
      toast.success('Password updated successfully');
    })
    .catch((error) => {
      dispatch(loaderStop());

      errorHandlers(error);
    });
};

export const getUserRole = () => async (dispatch) => {
  axios
    .get(`/UserRoles/GetList`)
    .then((response) => {
      dispatch({
        type: USER_ROLE_SUCCEESS,
        payload: response
      });
    })
    .catch((error) => {
      errorHandlers(error);
    });
};
