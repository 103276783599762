/* eslint-disable eqeqeq */
/* eslint-disable no-useless-concat */
import axios from 'axios';
import {DELETE_CITY, GET_CITY, SAVE_CITY} from '../apiRoutes';
import {GET_ALL_CITY} from './actionTypes';

import {errorHandlers} from '../../helpers/errorHandlers';
import {loaderStart, loaderStop} from '../actions';
import {toast} from 'react-toastify';

export const getCityList = () => async (dispatch) => {
  dispatch(loaderStart());

  axios
    .get(GET_CITY)
    .then((response) => {
      dispatch(loaderStop());
      dispatch({
        type: GET_ALL_CITY,
        payload: response
      });
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};

export const addCity = (value, tog_user) => async (dispatch) => {
  dispatch(loaderStart());

  axios
    .post(SAVE_CITY, value)
    .then((response) => {
      tog_user();
      if (value?.id) {
        toast.success('City updated successfully');
      } else {
        toast.success('City added successfully');
      }
      dispatch(loaderStop());
      dispatch(getCityList());
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};
export const deleteCity = (id) => async (dispatch) => {
  dispatch(loaderStart());

  axios
    .post(DELETE_CITY + id)
    .then((response) => {
      toast.success('City deleted successfully');

      dispatch(loaderStop());
      dispatch(getCityList());
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};
