import axios from 'axios';

import {COMAPNY_CONFIGURATION, COMAPNY_CONFIGURATION_DATA, COMAPNY_CONFIGURATION_UPDATE_DATA, COMAPNY_CONFIG_PENDINGENTRY} from '../apiRoutes';
import {CONFIGURATION_FETCH_SUCCESS, CURRENCY_FETCH_SUCCESS, LOADER_CONFIG_START, LOADER_CONFIG_STOP} from './actionTypes';

import {errorHandlers} from './../../helpers/errorHandlers';
import {loaderStart, loaderStop} from '../actions';
import {toast} from 'react-toastify';
export const getCurrency = () => async (dispatch) => {
  dispatch(loaderStart());
  axios
    .get(COMAPNY_CONFIGURATION)
    .then((response) => {
      dispatch(loaderStop());
      dispatch({
        type: CURRENCY_FETCH_SUCCESS,
        payload: response
      });
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};

export const getCompanyConfigurtion = () => async (dispatch) => {
  dispatch({
    type: LOADER_CONFIG_START
  });
  axios
    .get(COMAPNY_CONFIGURATION_DATA)
    .then((response) => {
      dispatch(loaderStop());
      dispatch({
        type: CONFIGURATION_FETCH_SUCCESS,
        payload: response
      });
      dispatch({
        type: LOADER_CONFIG_STOP
      });
    })
    .catch((error) => {
      dispatch({
        type: LOADER_CONFIG_STOP
      });
      errorHandlers(error);
    });
};

export const setCompanyConfigurtion = (value, code) => async (dispatch) => {
  dispatch(loaderStart());
  axios
    .get(COMAPNY_CONFIGURATION_UPDATE_DATA + '?code=' + code + '&value=' + value)
    .then((response) => {
      dispatch(loaderStop());
      toast.success('Configuration Updated Successfully');
      // dispatch({
      // 	type: CONFIGURATION_FETCH_SUCCESS,
      // 	payload: response,
      // })
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};

