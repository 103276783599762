import {POST_PROFITLOSS_DETAIL, POST_PROFITLOSS_LIST} from './actionTypes';

const initialState = {
  PLList: [],
  PLDetail: []
};

const PLReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_PROFITLOSS_LIST:
      state = {
        ...state,
        PLList: action.payload
      };
      break;

    case POST_PROFITLOSS_DETAIL:
      state = {
        ...state,
        PLDetail: action.payload
      };
      break;

    default:
      state = {...state};
      break;
  }
  return state;
};

export default PLReducer;
