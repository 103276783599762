import {toast} from 'react-toastify';

const errorHandlers = (error) => {
  if (error) {
    if (error?.response?.status === 401) {
      toast.error('Token expired');
      return (window.location.href = '/login');
    } else if (error?.response?.data?.errorMessage) {
      return toast.error(error?.response?.data?.errorMessage);
    } else if (error?.response?.message) {
      return toast.error(error?.response.message);
    } else if (error?.response?.errorMessage) {
      return toast.error(error?.response?.errorMessage);
    } else if (error?.message) {
      if (error?.message === 'Network Error') {
        return toast.error('Network error occured please try again');
      } else return toast.error(error?.message);
    } else {
      return toast.error(error || 'Something Went Wrong');
    }
  } else {
    return toast.error('Something Went Wrong');
  }
};

export {errorHandlers};
