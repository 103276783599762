import {TRIALBALANCE_FETCH_SUCCESS, TRIALBALANCE_FETCH_ERROR, TRIALBALANCE_LOADER_START, TRIALBALANCE_LOADER_STOP} from './actionTypes';

const initialState = {
  error: '',
  trialBal: [],
  loading: false,
  refresh: false
};

const trialBalReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRIALBALANCE_FETCH_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: '',
        trialBal: action.payload
      };
      break;
    case TRIALBALANCE_FETCH_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload
      };
      break;
    case TRIALBALANCE_LOADER_START:
      state = {
        ...state,
        loading: true
      };
      break;
    case TRIALBALANCE_LOADER_STOP:
      state = {
        ...state,
        loading: false
      };
      break;

    default:
      state = {...state};
      break;
  }
  return state;
};

export default trialBalReducer;
