import axios from 'axios';
import {
  LOGIN_USER,
  BLOCK_USER,
  UNBLOCK_USER,
  LOGIN_SUCCESS,
  UNBLOCK_USER_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  COMPANY_SUCCESS,
  FORGET_USER_SUCCESS,
  COMAPNY_API_ERROR,
  MENU_BY_ROLE
} from './actionTypes';
import {
  CHANGE_PASSWORD,
  COMPANY_AUTH,
  COMPANY_LIST,
  COMPANY_LIST_AUTH,
  FORGOT_PASSWORD,
  LOGIN_USER_URL,
  LOGIN_BLOCK_USER_URL,
  LOGIN_UNBLOCK_USER_URL,
  MENU_ROLE
} from '../../apiRoutes';
import {errorHandlers} from '../../../helpers/errorHandlers';
import {unregister} from './../../../serviceWorker';

export const getMenuByRole = (roleId, navigate, reload) => async (dispatch) => {
  let url = MENU_ROLE + '?roleId=' + roleId;
  axios
    .get(url)
    .then(async (res) => {
      dispatch({
        type: MENU_BY_ROLE,
        payload: res
      });
      await localStorage.setItem('menuList', JSON.stringify(res));
      await navigate('/transactions');
      (await reload) && window.location.reload();
    })
    .catch((error) => {
      errorHandlers(error);
    });
};

export const loginUser = (userData, navigate) => async (dispatch) => {
  dispatch({
    type: LOGIN_USER,
    payload: ''
  });
  axios
    .post(LOGIN_USER_URL, userData)
    .then(async (response) => {
      await localStorage.setItem('userData', JSON.stringify(response));

      dispatch(getCompanyList(navigate));

      dispatch({
        type: LOGIN_SUCCESS,
        payload: response
      });
    })
    .catch((error) => {
      dispatch({
        type: API_ERROR,
        payload: error?.response?.data?.errorMessage || 'Login Failed Please try again'
      });
    });
};

export const blockUnBlockUser = (userData) => async (dispatch) => {
  dispatch({
    type: userData && userData?.isUserBlocked ? BLOCK_USER : UNBLOCK_USER,
    payload: ''
  });

  const apiURL = userData && userData?.isUserBlocked ? LOGIN_BLOCK_USER_URL : LOGIN_UNBLOCK_USER_URL;
  const params = `isUserBlocked=${userData?.isUserBlocked}&Email=${userData.Email}`;

  axios
    .post(`${apiURL}?${params}`)
    .then(async (response) => {
      if (userData && userData?.isUserBlocked === false) {
        dispatch({
          type: UNBLOCK_USER_SUCCESS,
          payload: response
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: API_ERROR,
        payload: error?.response?.data?.errorMessage || 'Login Failed Please try again'
      });
    });
};

export const changePassword = (userData, navigate) => async (dispatch) => {
  dispatch({
    type: LOGIN_USER,
    payload: ''
  });
  axios
    .post(CHANGE_PASSWORD, userData)
    .then((response) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: response
      });
    })
    .catch((error) => {
      dispatch({
        type: API_ERROR,
        payload: error?.response?.data?.errorMessage || 'Login Failed Please try again'
      });
    });
};

export const forgetPassword = (userData, navigate) => async (dispatch) => {
  dispatch({
    type: LOGIN_USER,
    payload: ''
  });
  axios
    .post(FORGOT_PASSWORD + '?userName=' + userData)
    .then((response) => {
      dispatch({
        type: FORGET_USER_SUCCESS,
        payload: response
      });
      // navigate("/company")
    })
    .catch((error) => {
      dispatch({
        type: API_ERROR,
        payload: error?.response?.data?.errorMessage || 'Login Failed Please try again'
      });
    });
};
export const getCompanyList = (navigate) => async (dispatch) => {
  const userdata = JSON.parse(localStorage.getItem('userData'));
  axios
    .get(COMPANY_LIST_AUTH + '?id=' + userdata?.id)
    .then((response) => {
      response.map((a) => {
        a.value = a.companyID;
        a.label = a.companyName;
      });
      if (response?.length === 1) {
        dispatch(getCompanyToken(response[0]?.companyID, navigate));
      } else {
        navigate && navigate('/company');
      }

      dispatch({
        type: COMPANY_SUCCESS,
        payload: response
      });
    })
    .catch((error) => {
      dispatch({
        type: COMAPNY_API_ERROR,
        payload: error?.response?.data?.errorMessage || ' Please try again'
      });
    });
};

export const getCompanyListWithoutNav = () => async (dispatch) => {
  const userdata = JSON.parse(localStorage.getItem('userData'));
  axios
    .get(COMPANY_LIST_AUTH + '?id=' + userdata?.id)
    .then((response) => {
      response.map((a) => {
        a.value = a.companyID;
        a.label = a.companyName;
      });

      dispatch({
        type: COMPANY_SUCCESS,
        payload: response
      });
    })
    .catch((error) => {
      dispatch({
        type: COMAPNY_API_ERROR,
        payload: error?.response?.data?.errorMessage || ' Please try again'
      });
    });
};
export const getCompanyListUser = (navigate) => async (dispatch) => {
  const userdata = JSON.parse(localStorage.getItem('userData'));
  axios
    .get(COMPANY_LIST)
    .then((response) => {
      response.map((a) => {
        a.value = a.companyID;
        a.label = a.companyName;
      });

      dispatch({
        type: COMPANY_SUCCESS,
        payload: response
      });
    })
    .catch((error) => {
      dispatch({
        type: COMAPNY_API_ERROR,
        payload: error?.response?.data?.errorMessage || ' Please try again'
      });
    });
};

export const getCompanyToken = (comapnyId, navigate, reload) => async (dispatch) => {
  const userdata = JSON.parse(localStorage.getItem('userData'));

  axios
    .post(COMPANY_AUTH + '?companyID=' + comapnyId + '&userID=' + userdata.id + '&email=' + userdata.email)
    .then(async (response) => {
      axios.defaults.headers.common['Authorization'] = response?.token;
      dispatch({
        type: LOGIN_SUCCESS,
        payload: response
      });
      await dispatch(getMenuByRole(response.roleId, navigate, reload));
    })
    .catch((error) => {
      dispatch({
        type: COMAPNY_API_ERROR,
        payload: error?.response?.data?.errorMessage || ' Please Try Again'
      });
    });
};
export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user
  };
};

export const logoutUser = (history) => {
  localStorage.removeItem('userData');
  localStorage.removeItem('company');
  localStorage.removeItem('menuList');
  unregister();

  return {
    type: LOGOUT_USER,
    payload: {history}
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {}
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error
  };
};

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: {data, history, type}
  };
};
