import axios from 'axios';
import {toast} from 'react-toastify';
import {PARTYSTOCK_FETCH_SUCCESS, PARTYSTOCK_FETCH_ERROR} from './actionTypes';
import moment from 'moment';
import {errorHandlers} from '../../helpers/errorHandlers';
import {loaderStart, loaderStop} from '../actions';

export const getPartyStockList = (date, prodId, accId, ishold) => async (dispatch) => {
  dispatch(loaderStart());
  const date1 = moment(date[0]).format('YYYY-MM-DD');
  const date2 = moment(date[1]).format('YYYY-MM-DD');
  axios
    .get(
      'Stock/GetPartyStockReport' +
        '?fromDate=' +
        date1 +
        '&toDate=' +
        date2 +
        '&productID=' +
        prodId +
        '&accountID=' +
        accId +
        '&isHold=' +
        ishold
    )
    .then((response) => {
      dispatch(loaderStop());
      dispatch({
        type: PARTYSTOCK_FETCH_SUCCESS,
        payload: response
      });
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
      dispatch({
        type: PARTYSTOCK_FETCH_ERROR,
        payload: error?.response?.data?.errorMessage || 'Login Failed Please try again'
      });
    });
};

export const downloadPartyStockList = (date, accountID, name, type, prodid, ishold, account, product) => async (dispatch) => {
  dispatch(loaderStart());
  const date1 = moment(date[0]).format('YYYY-MM-DD');
  const date2 = moment(date[1]).format('YYYY-MM-DD');

  let name1 = name?.trim();
  if (!name1) {
    name1 = 'Party Stock Report';
  }

  let tag;
  if (type == 'pdf') {
    tag = '.pdf';
  } else {
    tag = '.csv';
  }
  const url = type == 'csv' ? `/ExportExcel/ExportPartystocklExcel` : `/ExportPDF/ExportPartystocklPDF`;
  axios
    .get(
      url +
        '?fromDate=' +
        date1 +
        '&toDate=' +
        date2 +
        '&productID=' +
        prodid +
        '&accountID=' +
        accountID +
        '&isHold=' +
        ishold +
        '&AcName=' +
        account +
        '&Productname=' +
        product,
      {
        responseType: 'blob'
      }
    )
    .then((response) => {
      dispatch(loaderStop());
      const url = URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = url;
      link.download = name1 + tag;
      link.click();
    })
    .catch((error) => {
      dispatch(loaderStop());
      if (error) {
        if (error?.response?.status === 401) {
          toast.error('Token expired');
          return (window.location.href = '/login');
        } else if (error?.response?.data?.errorMessage) {
          return toast.error(error?.response?.data?.errorMessage);
        } else if (error?.response?.message) {
          return toast.error(error?.response.message);
        } else if (error?.response?.errorMessage) {
          return toast.error(error?.response?.errorMessage);
        } else if (error.response.status == 400) {
          return toast.error('No Data Found');
        } else if (error?.message) {
          if (error?.message === 'Network Error') {
            return toast.error('Network error occured please try again');
          } else return toast.error(error?.message);
        } else {
          return toast.error(error || 'Something Went Wrong');
        }
      } else {
        return toast.error('Something Went Wrong');
      }
      dispatch(loaderStop());
    });
};

// export const saveAccountGroup = (accGroup) => async (dispatch) => {
//   dispatch(loaderStart());
//   dispatch({
//     type: ACCOUNTGROUP_SAVE_START,
//     payload: "",
//   });
//   const data = {
//     groupName: accGroup.groupName,
//     parentGroupName: accGroup.parent.label,
//     parentGroupID: accGroup.parent.value,
//   };
//   if (accGroup.recId) {
//     data.id = accGroup.recId;
//   }
//   axios
//     .post("AccountGroup/SaveAccountGroup", data)
//     .then((response) => {
//       if (response==1&&accGroup.recId) {
// 				toast.success("Account Edited Successfully")
// 			} else if(response==1) {
// 				toast.success("Account Added Successfully")
// 			}else{
//         toast.error("Action can't be performed")

//       }

//       dispatch(loaderStop());
//       dispatch({
//         type: ACCOUNTGROUP_SAVE_SUCCESS,
//         payload: response,
//       });
//     })
//     .catch((error) => {
//       errorHandlers(error);
//       dispatch(loaderStop());
//       dispatch({
//         type: ACCOUNTGROUP_SAVE_ERROR,
//         payload:
//           error?.response?.data?.errorMessage ||
//           "Login Failed Please try again",
//       });
//     });
// };

// export const deleteAccountGroup = (recId) => async (dispatch) => {
//   axios
//     .post("AccountGroup/DeleteAccountGroup/" + recId)
//     .then((response) => {

//       dispatch({
//         type: ACCOUNTGROUP_DELETE_SUCCESS,
//         payload: response,
//       });
//     })
//     .catch((error) => {
//       errorHandlers(error);
//       dispatch(loaderStop());
//       dispatch({
//         type: ACCOUNTGROUP_DELETE_ERROR,
//         payload:
//           error?.response?.data?.errorMessage ||
//           "Login Failed Please try again",
//       });
//     });
// };
