const layoutTypes = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical'
};

const layoutWidthTypes = {
  FLUID: 'fluid',
  BOXED: 'boxed',
  SCROLLABLE: 'scrollable'
};

const layoutPositionTypes = {
  FIXED: 'false',
  SCROLLABLE: 'true'
};

const topBarThemeTypes = {
  LIGHT: 'light',
  DARK: 'dark'
};

const leftSidebarTypes = {
  DEFAULT: 'lg',
  COMPACT: 'md',
  ICON: 'sm'
};

const leftSideBarThemeTypes = {
  LIGHT: 'light',
  DARK: 'dark',
  BRAND: 'brand'
};

const themeModeTypes = {
  LIGHT: 'light',
  DARK: 'dark'
};

export {layoutTypes, themeModeTypes, layoutWidthTypes, layoutPositionTypes, topBarThemeTypes, leftSidebarTypes, leftSideBarThemeTypes};
