import {
  ACCOUNTGROUP_FETCH_START,
  ACCOUNTGROUP_FETCH_ERROR,
  ACCOUNTGROUP_FETCH_SUCCESS,
  ACCOUNTGROUP_SAVE_START,
  ACCOUNTGROUP_SAVE_SUCCESS,
  ACCOUNTGROUP_SAVE_ERROR,
  ACCOUNTGROUP_DELETE_SUCCESS,
  ACCOUNTGROUP_DELETE_ERROR
} from './actionTypes';

const initialState = {
  error: '',
  accountsGroup: [],
  loading: false,
  refresh: false
};

const accountsGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNTGROUP_FETCH_START:
      state = {
        ...state,
        loading: true,
        error: ''
      };
      break;
    case ACCOUNTGROUP_FETCH_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: '',
        accountsGroup: action.payload
      };
      break;
    case ACCOUNTGROUP_FETCH_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload
      };
      break;

    case ACCOUNTGROUP_SAVE_START:
      state = {
        ...state,
        loading: false,
        error: action.payload
      };
      break;
    case ACCOUNTGROUP_SAVE_SUCCESS:
      state = {
        ...state,
        loading: false,
        refresh: !state.refresh
      };
      break;
    case ACCOUNTGROUP_SAVE_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload
      };
      break;

    case ACCOUNTGROUP_DELETE_SUCCESS:
      state = {
        ...state,
        deleteRecord: true,
        refresh: !state.refresh
      };
      break;

    case ACCOUNTGROUP_DELETE_ERROR:
      state = {
        ...state,
        deleteRecord: false
      };
      break;

    default:
      state = {...state};
      break;
  }
  return state;
};

export default accountsGroupReducer;
