import {STOCK_FETCH_SUCCESS, STOCK_FETCH_ERROR, STOCK_REMOVE_DATA} from './actionTypes';

const initialState = {
  error: '',
  stockList: [],
  loading: false,
  refresh: false
};

const stockReducer = (state = initialState, action) => {
  switch (action.type) {
    case STOCK_FETCH_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: '',
        stockList: action.payload
      };
      break;
    case STOCK_FETCH_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload
      };
      break;
    case STOCK_REMOVE_DATA:
      state = {
        ...state,
        stockList: []
      };
      break;
    default:
      state = {...state};
      break;
  }
  return state;
};

export default stockReducer;
