import React from 'react';
import TableContainer from '../../../components/Common/ReportWithoutPagination';
import moment from 'moment';

const GroupTable = (props) => {
  const columns = [
    {
      Header: 'Date',
      Cell: (cell) => {
        return (
          <div>
            {moment(cell?.cell?.row?.original.date).format('DD/MM/YYYY') === '01/01/1900'
              ? ''
              : moment(cell?.cell?.row?.original.date).format('DD/MM/YYYY')}
          </div>
        );
      }
    },
    {
      Header: 'Type',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.type}</div>;
      },
      Filter: false
    },

    {
      Header: 'Quantity',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.qty}</div>;
      },
      Filter: false
    },

    {
      Header: 'Rate',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.rate}</div>;
      },
      Filter: false
    },
    {
      Header: 'Fixed Status',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.isHold ? 'Un Fixed' : 'Fixed'}</div>;
      },
      Filter: false
    },

    {
      Header: 'Amount',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.amount}</div>;
      },
      Filter: false
    }
  ];
  return (
    <>
      {props?.tableData && props?.tableData ? (
        <TableContainer
          columns={columns}
          data={props?.tableData}
          tableName={'partyStock'}
          tableDataExtra={props?.tableDataExtra}
          isGlobalFilter={false}
          customPageSize={20}
        />
      ) : (
        <TableContainer
          columns={columns}
          data={[]}
          isGlobalFilter={false}
          customPageSize={20}
          tableName={'stockTable'}
          tableDataExtra={props?.tableDataExtra}
        />
      )}
    </>
  );
};

export default GroupTable;
