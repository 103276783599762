import {LOADER_START, LOADER_STOP, ALL_CITY, GET_SUPPLIER} from './actionTypes';

const initialState = {
  loading: false,
  error: '',
  city: [],
  supplier: [],
  refresh: false
};

const common = (state = initialState, action) => {
  switch (action.type) {
    case LOADER_START:
      state = {
        loading: true
      };
      break;
    case LOADER_STOP:
      state = {
        ...state,
        loading: false
      };
      break;
    case ALL_CITY:
      state = {
        ...state,
        city: action.payload
      };
      break;
    case GET_SUPPLIER:
      state = {
        ...state,
        supplier: action.payload
      };
      break;

    default:
      state = {...state};
      break;
  }
  return state;
};

export default common;
