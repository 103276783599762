export const ACCOUNTGROUP_FETCH_START = 'ACCOUNTGROUP_FETCH_START';
export const ACCOUNTGROUP_FETCH_SUCCESS = 'ACCOUNTGROUP_FETCH_SUCCESS';
export const ACCOUNTGROUP_FETCH_ERROR = 'ACCOUNTGROUP_FETCH_ERROR';

export const ACCOUNTGROUP_SAVE_START = 'ACCOUNTGROUP_SAVE_START';
export const ACCOUNTGROUP_SAVE_SUCCESS = 'ACCOUNTGROUP_SAVE_SUCCESS';
export const ACCOUNTGROUP_SAVE_ERROR = 'ACCOUNTGROUP_SAVE_ERROR';

export const ACCOUNTGROUP_DELETE_SUCCESS = 'ACCOUNTGROUP_DELETE_SUCCESS';
export const ACCOUNTGROUP_DELETE_ERROR = 'ACCOUNTGROUP_DELETE_ERROR';
