import React from 'react';
import {Col, Form, FormFeedback, Input, InputGroup, Label, Row} from 'reactstrap';
import {Field, Formik} from 'formik';
import * as Yup from 'yup';
import {useEffect, useRef, useState} from 'react';
import moment from 'moment';
import Select from 'react-select';
import {useDispatch, useSelector} from 'react-redux';
import Flatpickr from 'react-flatpickr';
import {addTransactionData} from '../../store/actions';
import {toast} from 'react-toastify';
import LoaderDisplay from '../../components/Common/loader';

import {CHECK_PASSWORD_TRAN_FALSE} from '../../store/Transjaction/actionTypes';
function TransactionOperation(props) {
  const {currentUser, serCurrentUser, setModalpassword, activeTab2, tog_invoice, setIsPast, isPast, date, habdleDateChange,transLock,  transStatus} = props;
  const [isCredit, setIsCredit] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const dispatch = useDispatch();
  const fp = useRef(null);

  const {accounts, city, password, currentAccountData, configurationData, CompanyLoader, passwordList} = useSelector((state) => ({
    accounts: state.accounts.accounts,
    city: state.transjaction.city,
    password: state.transjaction.password,
    currentAccountData: state.accounts.currentAccountData,
    configurationData: state.companyConfiguration.configurationData,
    CompanyLoader: state.companyConfiguration.CompanyLoader,
    passwordList: state.transjaction.passwordList
  }));

  useEffect(() => {
    if (configurationData) {
      let obj = configurationData?.find((o) => o.code === 'City');
      setIsCompany(obj?.value);
    }
  }, [configurationData]);


  const onclearValue = () => {
    serCurrentUser('');
    formikRef.current.resetForm();
  };

  useEffect(() => {
    if (currentUser) {
      formikRef.current.setErrors({});
      formikRef.current.setTouched({}, false);
      habdleDateChange([new Date(currentUser?.tranDate)]);

      formikRef.current.setFieldValue('receiverID', currentUser?.receiverID);
      formikRef.current.setFieldValue('receiver', currentUser?.receiver);
      formikRef.current.setFieldValue('senderID', currentUser?.senderID);
      formikRef.current.setFieldValue('sender', currentUser?.sender);
      formikRef.current.setFieldValue('tranCityID', currentUser?.tranCityID);
      formikRef.current.setFieldValue('cityName', currentUser?.cityName?currentUser?.cityName:"");
      formikRef.current.setFieldValue('tokenDetail', currentUser?.tokenDetail);
      formikRef.current.setFieldValue('sCommission', currentUser?.sCommission || '0.000');
      formikRef.current.setFieldValue('remarks', currentUser?.remarks);
      formikRef.current.setFieldValue('message', currentUser?.message);
      formikRef.current.setFieldValue('sRate', currentUser?.sRate || '0');
      formikRef.current.setFieldValue('rRate', currentUser?.rRate || '0');
      formikRef.current.setFieldValue('rCommission', currentUser?.rCommission || '0.000');
      formikRef.current.setFieldValue('tranStatus', currentUser?.tranStatus || 1);
      formikRef.current.setFieldValue('tranAmount', currentUser?.tranAmount || 1);
    }
  }, [currentUser]);
  useEffect(() => {
    var dDiff = moment().diff(date[0], 'days');
    if (dDiff > 1 && !currentUser) {
      setIsPast(true);
    } else {
      setIsPast(false);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentAccountData?.id) {
      if (isCredit) {
        formikRef.current.setFieldValue('receiverID', currentAccountData?.id);
        formikRef.current.setFieldValue('receiver', currentAccountData?.accountName);
        formikRef.current.handleChange('receiverID');
        formikRef.current.handleChange('receiver');
      } else {
        formikRef.current.setFieldValue('senderID', currentAccountData?.id);
        formikRef.current.setFieldValue('sender', currentAccountData?.accountName);
        formikRef.current.handleChange('senderID');
        formikRef.current.handleChange('sender');
      }
    } else {
      formikRef.current.setFieldValue('senderID', '');
      formikRef.current.setFieldValue('sender', '');
      formikRef.current.setFieldValue('receiver', '');
      formikRef.current.setFieldValue('receiverID', '');
    }
  }, [currentAccountData]);

  const formikRef = useRef();

  const validationSchema = Yup.object().shape({
    cityName: isCompany === 'true' ? Yup.string().required('Please select city') : Yup.string(),
    tokenDetail: Yup.string().required('Please add token').trim('Please add token'),
    sender: Yup.string().required('Please select debit'),
    tranAmount: Yup.number().required('Please Enter amount').min(0, "Amount can't be negative "),
    rRate: Yup.number('please enter only number').required('Please enter rate'),
    rCommission: Yup.number('please enter only number').required('Please enter commission'),
    sRate: Yup.number('please enter only number').required('Please enter rate'),
    sCommission: Yup.number('please enter only number').required('Please enter commission'),
    receiver: Yup.string().required('Please select credit')
  });

  const SelectField2: React.SFC<ReactSelectProps & FieldProps> = ({options, index, field, form}) => (
    <Select
      className='w-130'
      options={options}
      name={field.name}
      openMenuOnFocus={true}
      value={options ? options.find((option) => option.value === field.value) : ''}
      onChange={(option) => {
        form.setFieldValue(`cityName`, option.label);
        form.setFieldValue('tranCityID', option.value);
      }}
      onBlur={field.onBlur}
    />
  );

  const SelectFieldSender: React.SFC<ReactSelectProps & FieldProps> = ({options, index, field, form}) => (
    <Select
      className='w175'
      options={options}
      name={field.name}
      openMenuOnFocus={true}
      value={options ? options.find((option) => option.value === field.value) : ''}
      onChange={(option) => {
        form.setFieldValue(`sender`, option.label);
        form.setFieldValue('senderID', option.value);
      }}
      noOptionsMessage={() => {
        return (
          <button
            className='btn btn-primary btn-block'
            onClick={() => {
              setIsCredit(false);
              tog_invoice();
            }}
          >
            Add new account
          </button>
        );
      }}
      onBlur={field.onBlur}
    />
  );
  const SelectFieldReceiver: React.SFC<ReactSelectProps & FieldProps> = ({options, index, field, form}) => (
    <Select
      className='w175'
      options={options}
      name={field.name}
      openMenuOnFocus={true}
      value={options ? options.find((option) => option.value === field.value) : ''}
      onChange={(option) => {
        form.setFieldValue(`receiver`, option.label);
        form.setFieldValue('receiverID', option.value);
      }}
      noOptionsMessage={() => {
        return (
          <button
            className='btn btn-primary btn-block'
            onClick={() => {
              setIsCredit(true);
              tog_invoice();
            }}
          >
            Add new account
          </button>
        );
      }}
      onBlur={field.onBlur}
    />
  );
 
  return (
    <Form className='form-horizontal transactions-table'>
      {CompanyLoader && <LoaderDisplay />}
      <Formik
        initialValues={{
          tranAmount: currentUser ? currentUser?.tranAmount : null,
          cityName: currentUser?.cityName || '',
          tokenDetail: currentUser?.tokenDetail || '',
          sender: currentUser?.sender || '',
          receiver: currentUser?.receiver || '',
          sRate: currentUser?.sRate || '0',
          sCommission: currentUser?.sCommission || '0.000',
          remarks: currentUser?.remarks || '',
          message: currentUser?.message || '',
          rRate: currentUser?.rRate || '0',
          rCommission: currentUser?.rCommission || '0.000',
          tranStatus: currentUser?.tranStatus || 1
        }}
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={(values, {resetForm}) => {
          values.tranDate = moment(date[0]).format('MM/DD/YYYY');
          if (currentUser?.id) {
            values.tranStatus = 1;
            values.id = currentUser.id;
            if (!values?.tranCityID) {
              values.tranCityID = currentUser.tranCityID;
            }
            if (!values?.receiverID) {
              values.receiverID = currentUser.receiverID;
            }
            if (!values?.senderID) {
              values.senderID = currentUser.senderID;
            }
         
          }
          values.tokenDetail = values.tokenDetail?.trim();
          values.tranStatus = transStatus?1:2
          if (isPast) {
            if (password) {
              dispatch(addTransactionData(values, onclearValue, fp, activeTab2));
            } else {
              toast.info('Please Unblock the Transaction');
              setModalpassword('add');
            }
          } else {
            dispatch(addTransactionData(values, onclearValue, fp, activeTab2));
            onclearValue();
            resetForm();
          }
        }}
      >
        {({values, handleSubmit, handleBlur, handleChange, errors, getFieldProps, setFieldValue, touched}) => (
          <>
            <Row>
              <Col xs='auto' className='mb-2'>
                <Label>Date</Label>
                <InputGroup className='w105'>
                  <Flatpickr
                    className='form-control d-block cwidth'
                    name='tranDate'
                    value={date}
                    ref={fp}
                    openMenuOnFocus={false}
                    options={{
                      altInput: true,
                      altFormat: 'd/m/Y',
                      disableMobile: true,
                      dateFormat: 'd/m/Y',
                      openMenuOnFocus: true
                    }}
                    onChange={(e) => {
                      if (e.length) {
                        var dDiff = moment().diff(e[0], 'days');
                        if (dDiff > 1) {
                          dispatch({
                            type: CHECK_PASSWORD_TRAN_FALSE,
                            payload: false
                          });
                          setIsPast(true);
                        } else {
                          setIsPast(false);
                        }
                        habdleDateChange(e);
                      } else {
                        habdleDateChange([new Date()]);
                      }
                    }}
                  />
                </InputGroup>
                {touched.tranDate && errors.tranDate ? (
                  <FormFeedback type='invalid' className='width-150'>
                    <div>{errors.tranDate}</div>
                  </FormFeedback>
                ) : null}
              </Col>
              {isCompany === 'true' && (
                <>
                  <Col xs='auto' className='mb-2'>
                    <Label>City</Label>

                    <Field
                      name='tranCityID'
                      className='w-130'
                      component={SelectField2}
                      options={city?.map((value) => ({
                        value: value.id,
                        label: value.cityName
                      }))}
                    />

                    {touched.cityName && errors.cityName ? <div className='text-error width-150'> {errors.cityName}</div> : null}
                  </Col>
                </>
              )}
              <Col xs='auto' className='mb-2'>
                <Label>Token</Label>
                <input
                  name='tokenDetail'
                  className='form-control width-100'
                  placeholder='Token'
                  type='text'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.tokenDetail?.trimStart() || ''}
                  invalid={touched.tokenDetail && errors.tokenDetail ? true : false}
                />
                {touched.tokenDetail && errors.tokenDetail ? (
                  <div className='text-error width-100'>
                    <div>{errors.tokenDetail}</div>
                  </div>
                ) : null}
              </Col>
              <Col xs='auto' className='mb-2'>
                <Label>Amount</Label>
                <Input
                  name='tranAmount'
                  className='form-control width-125'
                  placeholder='Amount'
                  onChange={(e) => {
                    const value = e.target.value;
                    const val = ('' + value).replace('.', '');
                    if (val?.length > 3) {
                      const suppressedValue = Number(+val / 1000).toFixed(3);
                      const sCommission = Number((suppressedValue * Number(values?.sRate)) / 100000).toFixed(3);
                      const rCommission = Number((suppressedValue * Number(values?.rRate)) / 100000).toFixed(3);

                      setFieldValue('rCommission', rCommission);
                      setFieldValue('sCommission', sCommission);
                      setFieldValue('tranAmount', suppressedValue);
                    } else {
                      const sCommission = Number((value * Number(values?.sRate)) / 100000).toFixed(3);
                      const rCommission = Number((value * Number(values?.rRate)) / 100000).toFixed(3);
                      setFieldValue('rCommission', rCommission);
                      setFieldValue('sCommission', sCommission);
                      setFieldValue('tranAmount', value);
                    }
                  }}
                  step={0.0031}
                  onBlur={(e) => {
                    if (e?.target.value?.length <= 3) {
                      const formatted = Number(e?.target.value / 1000).toFixed(3);
                      if (e?.target.value.toString().includes('.')) {
                        setFieldValue('tranAmount', (+e?.target.value)?.toFixed(3));
                      } else if (values?.amount === Number(e.target.value)) {
                        return;
                      } else {
                        const sCommission = Number((formatted * Number(Number(values?.sRate))) / 100000).toFixed(3);
                        const rCommission = Number((formatted * Number(values?.rRate)) / 100000).toFixed(3);

                        setFieldValue('rCommission', rCommission);
                        setFieldValue('sCommission', sCommission);
                        setFieldValue('tranAmount', formatted);
                      }
                    } else {
                      setFieldValue('tranAmount', (+e?.target.value).toFixed(3));
                    }
                  }}
                  type='number'
                  value={values.tranAmount || ''}
                  invalid={touched.tranAmount && errors.tranAmount ? true : false}
                />
                {touched.tranAmount && errors.tranAmount ? (
                  <FormFeedback type='invalid' className='width-100'>
                    <div>{errors.tranAmount}</div>
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xs='auto' className='mb-2'>
                <Label>Debit</Label>

                <Field
                  name='senderID'
                  component={SelectFieldSender}
                  options={accounts?.map((value) => ({
                    value: value.id,
                    label: value.accountName
                  }))}
                />
                {/* <Select
                  className='w175'
                  placeholder='Debit'
                  value={selectedSender}
                  openMenuOnFocus={true}
                  onChange={async (selectedOption) => {
                    await handleSenderChange(selectedOption);
                    // handleYearChange(selectedOption, values);
                    // values.groupName = selectedOption.value;
                    await setFieldValue('sender', selectedOption?.label);
                    await setFieldValue('senderID', selectedOption?.value);
                    await handleChange('sender');
                    await handleChange('senderID');
                  }}
                  isSearchable={true}
                  options={accounts?.map((value) => ({
                    value: value.id,
                    label: value.accountName
                  }))}
                  name='sender'
                  isLoading={false}
                  loadingMessage={() => 'Fetching Debit'}
                  noOptionsMessage={() => {
                    return (
                      <button
                        className='btn btn-primary btn-block'
                        onClick={() => {
                          setIsCredit(false);
                          tog_invoice();
                        }}
                      >
                        Add new account
                      </button>
                    );
                  }}
                /> */}
                {touched.sender && errors.sender ? <div className='text-error width-175'>{errors.sender}</div> : null}
              </Col>
              <Col xs='auto' className='mb-2'>
                <Label>Rate</Label>
                <Input
                  name='sRate'
                  className='form-control width-75'
                  placeholder='Rate'
                  step={0.0031}
                  type='number'
                  onChange={(e) => {
                    const value = e.target.value;
                    // ;(Amount * Rate) / 100000
                    if (Number(values?.sRate) === value) {
                      return;
                    } else {
                      if (values?.tranAmount > 0) {
                        const constCommosion = Number((values?.tranAmount * +value) / 100000).toFixed(3);
                        setFieldValue('sCommission', constCommosion);
                        setFieldValue('sRate', value);
                      } else {
                        setFieldValue('sRate', value);
                        setFieldValue('sCommission', '0.000');
                      }
                    }
                  }}
                  onBlur={handleBlur}
                  value={values.sRate}
                  invalid={touched.sRate && errors.sRate ? true : false}
                />
                {touched.sRate && errors.sRate ? (
                  <FormFeedback type='invalid' className='width-75'>
                    <div>{errors.sRate}</div>
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xs='auto' className='mb-2'>
                <Label>Commission</Label>
                <Input
                  name='sCommission'
                  className='form-control width-100'
                  placeholder='Commission'
                  // onChange={handleChange}
                  step={0.0031}
                  type='number'
                  onChange={(e) => {
                    const value = e.target.value;
                    const val = ('' + value).replace('.', '');
                    if (val?.length > 3) {
                      const suppressedValue = Number(+val / 1000).toFixed(3);

                      if (values?.sCommission === suppressedValue) {
                        return;
                      } else {
                        if (values?.tranAmount > 0) {
                          const constCommosion = Number((suppressedValue * 100000) / values?.tranAmount);
                          setFieldValue('sRate', constCommosion);
                        }
                        setFieldValue('sCommission', suppressedValue);
                      }
                    } else {
                      if (values?.sCommission === val) {
                        return;
                      } else {
                        if (values?.tranAmount > 0) {
                          let constCommosion = Number((+val * 100000) / values?.tranAmount);
                          if (constCommosion == 0) {
                            constCommosion = '0';
                          }
                          setFieldValue('sRate', constCommosion);
                        }
                        setFieldValue('sCommission', value);
                      }
                    }
                  }}
                  onBlur={(e) => {
                    if (e?.target.value?.length <= 3) {
                      const formatted = Number(e?.target.value / 1000).toFixed(3);
                      if (e?.target.value.toString().includes('.')) {
                        setFieldValue('sCommission', (+e?.target.value)?.toFixed(3));
                      } else if (Number(values?.sRate) === Number(e.target.value)) {
                        return;
                      } else {
                        if (values?.tranAmount > 0) {
                          const constCommosion = Number((formatted * 100000) / values?.tranAmount);
                          setFieldValue('sRate', constCommosion);
                        }
                        setFieldValue('sCommission', formatted);
                      }
                    } else {
                      setFieldValue('sCommission', (+e?.target.value)?.toFixed(3));
                    }
                  }}
                  value={values.sCommission}
                  invalid={touched.sCommission && errors.sCommission ? true : false}
                />
                {touched.sCommission && errors.sCommission ? (
                  <FormFeedback type='invalid' className='width-100'>
                    <div>{errors.sCommission}</div>
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xs='auto' className='mb-2'>
                <Label>Credit</Label>

                <Field
                  name='receiverID'
                  component={SelectFieldReceiver}
                  options={accounts?.map((value) => ({
                    value: value.id,
                    label: value.accountName
                  }))}
                />

                {touched.receiver && errors.receiver ? <div className='text-error width-100'>{errors.receiver}</div> : null}
              </Col>
              <Col xs='auto' className='mb-2'>
                <Label>Rate</Label>
                <Input
                  name='rRate'
                  className='form-control width-75'
                  placeholder='Rate'
                  type='number'
                  onChange={(e) => {
                    const value = e.target.value;
                    // ;(Amount * Rate) / 100000
                    if (Number(values?.rRate) === value) {
                      return;
                    } else {
                      if (values?.tranAmount > 0) {
                        const constCommosion = Number((values?.tranAmount * +value) / 100000).toFixed(3);
                        setFieldValue('rCommission', constCommosion);
                        setFieldValue('rRate', value);
                      } else {
                        setFieldValue('rRate', value);

                        setFieldValue('rCommission', '0.000');
                      }
                    }
                  }}
                  onBlur={handleBlur}
                  value={values.rRate}
                  invalid={touched.rRate && errors.rRate ? true : false}
                />
                {touched.rRate && errors.rRate ? (
                  <FormFeedback type='invalid' className='width-75'>
                    <div>{errors.rRate}</div>
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xs='auto' className='mb-2'>
                <Label>Commission</Label>

                <Input
                  name='rCommission'
                  className='form-control width-100'
                  placeholder='Commission'
                  step={0.0031}
                  type='number'
                  onChange={(e) => {
                    const value = e.target.value;
                    const val = ('' + value).replace('.', '');
                    if (val?.length > 3) {
                      const suppressedValue = Number(+val / 1000).toFixed(3);
                      if (values?.rCommission === suppressedValue) {
                        return;
                      } else {
                        if (values?.tranAmount > 0) {
                          const constCommosion = Number((suppressedValue * 100000) / values?.tranAmount);
                          setFieldValue('rRate', constCommosion);
                          setFieldValue('rCommission', suppressedValue);
                        } else {
                          setFieldValue('rCommission', suppressedValue);
                          setFieldValue('rRate', '0');
                        }
                      }
                    } else {
                      if (values?.rCommission === val) {
                        return;
                      } else {
                        if (values?.tranAmount > 0) {
                          let constCommosion = Number((+val * 100000) / values?.tranAmount);
                          if (constCommosion == 0) {
                            constCommosion = '0';
                          }
                          setFieldValue('rRate', constCommosion);
                        }
                        setFieldValue('rCommission', val);
                      }
                    }
                  }}
                  onBlur={(e) => {
                    if (e?.target.value?.length <= 3) {
                      const formatted = Number(e?.target.value / 1000).toFixed(3);
                      if (e?.target.value.toString().includes('.')) {
                        setFieldValue('rCommission', (+e?.target.value)?.toFixed(3));
                      } else if (Number(values?.sRate) === Number(e.target.value)) {
                        return;
                      } else {
                        if (values?.tranAmount > 0) {
                          const constCommosion = Number((formatted * 100000) / values?.tranAmount).toFixed(3);
                          setFieldValue('rRate', constCommosion);
                          setFieldValue('rCommission', formatted);
                        }
                      }
                    } else {
                      setFieldValue('rCommission', (+e?.target.value)?.toFixed(3));
                    }
                  }}
                  value={values.rCommission}
                  invalid={touched.rCommission && errors.rCommission ? true : false}
                />
                {touched.rCommission && errors.rCommission ? (
                  <FormFeedback type='invalid' className='width-100'>
                    <div>{errors.rCommission}</div>
                  </FormFeedback> 
                ) : null}
              </Col>
              <Col xs='auto' className='mb-2'>
                <Label>Remarks</Label>
                <Input
                  name='remarks'
                  className='form-control width-150'
                  placeholder='Remarks'
                  type='text'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.remarks || ''}
                  invalid={touched.remarks && errors.remarks ? true : false}
                />
                {touched.remarks && errors.remarks ? (
                  <FormFeedback type='invalid' className=' width-150'>
                    <div>{errors.remarks}</div>
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xs='auto' className='tranButton text-primary mb-2'>
                {transLock&&isPast && !password && !passwordList ? (
                  <button
                    type='button'
                    className='cursor p-0 border-0 bg-white text-primary'
                    onClick={(e) => {
                      e.preventDefault();

                      handleSubmit();
                    }}
                  >
                    <i className='bx bx-lock  font-size-26'></i>
                  </button>
                ) : (
                  <button
                    className='cursor p-0 border-0 bg-white text-primary'
                    type='button'
                    onClick={(e) => {
                      e.preventDefault();

                      handleSubmit();
                    }}
                  >
                    {currentUser?.sender ? <i className='bx bx-pencil  font-size-26'></i> : <i className='bx bx-plus  font-size-26 '></i>}
                  </button>
                )}

                <button
                  className='cursor p-0 border-0 bg-white text-primary'
                  onClick={(e) => {
                    e.preventDefault();
                    onclearValue();
                  }}
                >
                  <i className='bx bx-reset font-size-26 '></i>
                </button>
              </Col>{' '}
            </Row>
          </>
        )}
      </Formik>
    </Form>
  );
}

export default TransactionOperation;
