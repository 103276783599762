import React, {useEffect, useState} from 'react';
import {Row, Col, Container, Card, CardBody, Input, Button} from 'reactstrap';
import Flatpickr from 'react-flatpickr';

import Select from 'react-select';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import GroupTable from './StockTable';
import {useDispatch, useSelector} from 'react-redux';
import {getStockList, downloadStockList} from '../../../store/Stock/actions';
import {getProductListActive} from '../../../store/Products/actions';
import moment from 'moment';
import {toast} from 'react-toastify';
import {STOCK_REMOVE_DATA} from '../../../store/Stock/actionTypes';

const StockOpeation = () => {
  document.title = 'Stock - account-books';

  const dispatch = useDispatch();
  const stockResult = useSelector((state) => state.stockReducer);
  const productListResult = useSelector((state) => state.productReducer);

  const [product, setProduct] = useState([]);

  let prevDate = moment().subtract(6, 'day');
  const [date, setDate] = useState([prevDate._d, new Date()]);
  const [fileName, setFileName] = useState();

  useEffect(() => {
    handleReload();
    return () => {
      dispatch({
        type: STOCK_REMOVE_DATA
      });
    };
  }, []);

  const handleReload = (getList) => {
    dispatch(getProductListActive());

    if (getList) {
      handleGetReport(date, product);
    }
  };

  const handleGetReport = (date, prod) => {
    dispatch(getStockList(date, prod?.id));
  };

  const handleProduct = (e) => {
    setProduct(e);
    handleGetReport(date, e);
  };
  const handleDate = (e) => {
    setDate(e);
    if (e.length > 1) {
      if (!product?.id) {
        toast.info('please select the product');
        return;
      }
      handleGetReport(e, product);
    }
  };
  const handleDateClose = (e) => {
    if (date.length != 2) {
      let defDate = [e[0], e[0]];
      setDate(defDate);
      if (!product?.value) {
        toast.info('Please select the product');
        return;
      }
      handleGetReport(defDate, product);
    }
  };
  const ondownloadClick = (type) => {
    if (!product?.productName) {
      toast.info('Please select product');
    } else {
      dispatch(downloadStockList(date, product?.id, fileName, type, product?.productName));
      setFileName('');
    }
  };

  return (
    <>
      <div className='position-relative'>
        <div className=' mt-2'>
          <Row>
            <Col sm='6' lg='3' md='3' className='auto mb-3 '>
              <div className='d-flex '>
                <Input
                  className='me-2 w-50'
                  type='text'
                  value={fileName}
                  placeholder='Enter export file name...'
                  onChange={(e) => setFileName(e?.target?.value)}
                />
                <Button onClick={() => ondownloadClick('pdf')}>PDF</Button> &nbsp;
                <Button onClick={() => ondownloadClick('csv')}>CSV</Button> &nbsp;
                <Button
                  style={{
                    background: 'none',
                    color: '#495057',
                    border: '1px solid #eff0f2'
                  }}
                  onClick={() => handleReload(true)}
                  disabled={product?.id ? false : true}
                >
                  <span className='bx bx-revision font-size-18'></span>
                </Button>{' '}
              </div>
            </Col>
            <Col sm='12' lg='6' md='9' className='auto '>
              <div className='row'>
                <div className='col-md-5'>
                  <Flatpickr
                    className='form-control d-block mb-3'
                    placeholder='Date Range'
                    value={date}
                    options={{
                      mode: 'range',
                      disableMobile: true,
                      dateFormat: 'd/m/Y '
                    }}
                    onChange={(e) => handleDate(e)}
                    onClose={handleDateClose}
                  />
                </div>
                <div className='col-md-4'>
                  <Select
                    className='mb-3'
                    openMenuOnFocus={true}
                    placeholder='Product'
                    options={productListResult?.productList}
                    value={product}
                    onChange={(e) => handleProduct(e)}
                  />
                </div>
              </div>
            </Col>
            <Col sm='12' lg='3' md='2' className='auto'>
              {stockResult?.stockList?.totalBalance ? (
                <Col className='mt-2 fw-bold'>
                  <span className='me-2'>Total Balance : </span>
                  <span className={stockResult?.stockList?.totalBalance > 0 ? 'text-success ' : 'text-danger'}>
                    {stockResult?.stockList?.totalBalance}
                  </span>
                </Col>
              ) : null}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default StockOpeation;
