import React, {useEffect} from 'react';
import Routes from './Routes';

import {ToastContainer} from 'react-toastify';
// Import Scss
import './assets/scss/theme.scss';
import LoaderDisplay from './/components/Common/loader';
import {useSelector} from 'react-redux';
import 'flatpickr/dist/themes/material_blue.css';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  const {loading} = useSelector((state) => ({
    loading: state.common.loading
  }));

  return (
    <React.Fragment>
      {loading && <LoaderDisplay />}
      <Routes />
      <ToastContainer autoClose={2000} />{' '}
    </React.Fragment>
  );
}

export default App;
