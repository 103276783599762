// eslint-disable-next-line react-hooks/exhaustive-deps

import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {addAccounts, getAccountsCounts, getAccountsGroups} from '../../store/actions';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import Select from 'react-select';
import {Row, Col, Modal, Form, FormFeedback, Input, Label} from 'reactstrap';
import {REMOVE_ACCOUNT_DATA} from '../../store/Accounts/actionTypes';
const AccountModal = (args) => {
  const dispatch = useDispatch();
  const [selectedGroup, setSelectedGropu] = useState('');
  const [selectedtranType, setSelectedtranType] = useState('');
  const [currentUser, serCurrentUser] = useState('');
  const [text, setText] = useState('');
  const [bankOption, setBankoption] = useState(false);
  useEffect(() => {
    dispatch(getAccountsGroups());
    dispatch(getAccountsCounts());
  }, [dispatch]);
  const transTpes = [
    {
      name: 'Cr',
      value: 'C'
    },
    {
      name: 'Dr',
      value: 'D'
    }
  ];
  const {accountGroups, accCount} = useSelector((state) => ({
    accountGroups: state.accounts.accountGroups,
    accCount: state.accounts.accCount
  }));
  const handleYearChange = (selectedYear) => {
    setSelectedGropu(selectedYear);
  };
  const handletranTypeChange = (selectedtranType) => {
    setSelectedtranType(selectedtranType);
  };
  const onclearValue = () => {
    setSelectedGropu('');
    setSelectedtranType('');
    serCurrentUser('');
    setText('');
    validation?.resetForm();
    // setmodal_invoice(false)
  };
  useEffect(() => {
    return () => {
      dispatch({
        type: REMOVE_ACCOUNT_DATA
      });
    };
  }, []);

  useEffect(() => {
    if (selectedGroup.label === 'Bank Accounts' || selectedGroup.label === 'Bank O D') {
      setBankoption(true);
    } else {
      setBankoption(false);
    }
  }, [selectedGroup]);
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      accountCode: accCount,
      accountName: '',
      openingBalance: '',
      tranType: '',
      alias: '',
      commission: '',
      groupName: '',
      accountNo: '',
      ifscCode: ''
    },
    validationSchema: Yup.object({
      accountCode: Yup.string().required('Please Enter Your Account Code'),
      commission: Yup.number().required('Please Enter Your Account Commission'),
      tranType: Yup.string().required('Please Enter Your Account tranType'),
      groupName: Yup.string().required('Please Enter Your Account groupName'),
      accountName: Yup.string().required('Please enter  account name').trim('Leading and trailing space is not allowed').strict(true),
      openingBalance: Yup.number().required('Please Enter Your Opening Balance ').min(0, 'Balance should be not negative ')
    }),
    onSubmit: (values) => {
      values.notes = text;
      if (!bankOption) {
        values.accountNo = '';
        values.ifscCode = '';
      }
      values.isParty = args?.isParty;
      dispatch(addAccounts(values, onclearValue()));
      args?.setmodal_invoice(false);
      validation.resetForm();
    }
  });
  return (
    <Modal
      size='lg'
      isOpen={args?.modal_invoice}
      toggle={() => {
        args?.tog_invoice();
        onclearValue();
      }}
      className='addInvoiceModal'
      centered
    >
      <div className='modal-header'>
        <h5 className='modal-title' id='addInvoiceModalLabel'>
          {' '}
          {currentUser ? 'Edit' : 'Add'} Account{' '}
        </h5>
        <button
          onClick={() => {
            args?.setmodal_invoice(false);
            onclearValue();
          }}
          type='button'
          className='btn-close'
          aria-label='Close'
        ></button>
      </div>
      <div className='modal-body p-4'>
        <Form className='form-horizontal' onSubmit={validation.handleSubmit}>
          <Row>
            <Col md='4'>
              <Label>
                ACCOUNT CODE<span className='text-danger'>*</span>
              </Label>
              <Input
                name='accountCode'
                className='form-control'
                placeholder='Enter Account Code'
                type='text'
                disabled
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.accountCode || ''}
                invalid={validation.touched.accountCode && validation.errors.accountCode ? true : false}
              />
              {validation.touched.accountCode && validation.errors.accountCode ? (
                <FormFeedback type='invalid'>
                  <div>{validation.errors.accountCode}</div>
                </FormFeedback>
              ) : null}
            </Col>

            <Col md='4'>
              <Label>
                Account Name<span className='text-danger'>*</span>
              </Label>
              <Input
                name='accountName'
                className='form-control'
                placeholder='Enter Account Name'
                type='text'
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.accountName || ''}
                invalid={validation.touched.accountName && validation.errors.accountName ? true : false}
              />
              {validation.touched.accountName && validation.errors.accountName ? (
                <FormFeedback type='invalid'>
                  <div>{validation.errors.accountName}</div>
                </FormFeedback>
              ) : null}
            </Col>
            <Col md='4'>
              <Label>Alias</Label>
              <Input
                name='alias'
                className='form-control'
                placeholder='Enter Alias'
                type='text'
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.alias || ''}
                invalid={validation.touched.alias && validation.errors.alias ? true : false}
              />
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col md='6'>
              <Label>
                Group Name<span className='text-danger'>*</span>
              </Label>
              <Select
                placeholder='Select Group Name'
                value={selectedGroup}
                openMenuOnFocus={true}
                onChange={(selectedOption) => {
                  handleYearChange(selectedOption);
                  // handleYearChange(selectedOption, values);
                  // values.groupName = selectedOption.value;
                  validation.setFieldValue('groupName', selectedOption?.label);
                  validation.setFieldValue('groupId', selectedOption?.value);
                  validation.handleChange('groupName');
                  validation.handleChange('groupId');
                }}
                isSearchable={true}
                options={accountGroups.map((value) => ({
                  value: value.id,
                  label: value.groupName
                }))}
                name='groupName'
                isLoading={false}
                loadingMessage={() => 'Fetching Group Name'}
                noOptionsMessage={() => 'Groups appears here'}
              />
              {validation.touched.groupName && validation.errors.groupName ? (
                <div className='text-error '>{validation.errors.groupName}</div>
              ) : null}
            </Col>
            <Col md='6'>
              <Label>
                Opening Balance<span className='text-danger'>*</span>
              </Label>
              <Input
                name='openingBalance'
                className='form-control'
                placeholder='Enter Opening Balance'
                step={0.0031}
                type='number'
                onChange={(e) => {
                  const value = e.target.value;
                  const val = ('' + value).replace('.', '');
                  if (val?.length > 3) {
                    const suppressedValue = Number(+val / 1000).toFixed(3);
                    validation?.setFieldValue('openingBalance', suppressedValue);
                  } else {
                    validation?.setFieldValue('openingBalance', val);
                  }
                }}
                onBlur={(e) => {
                  if (e?.target.value?.length <= 3) {
                    const formatted = Number(e?.target.value / 1000).toFixed(3);
                    if (validation?.values?.amount === Number(e.target.value)) {
                      return;
                    } else {
                      validation.setFieldValue('openingBalance', formatted);
                    }
                  }
                }}
                value={validation.values.openingBalance || ''}
                invalid={validation.touched.openingBalance && validation.errors.openingBalance ? true : false}
              />
              {validation.touched.openingBalance && validation.errors.openingBalance ? (
                <FormFeedback type='invalid'>
                  <div>{validation.errors.openingBalance}</div>
                </FormFeedback>
              ) : null}
            </Col>
          </Row>

          {bankOption ? (
            <Row className='mt-4'>
              <Col md='6'>
                <Label>
                  IFSC Code <span className='text-danger'>*</span>
                </Label>
                <Input
                  name='ifscCode'
                  className='form-control'
                  placeholder='Enter IFSC code'
                  type='text'
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.ifscCode?.trimStart()}
                  invalid={validation.touched.ifscCode && validation.errors.ifscCode ? true : false}
                />
                {validation.touched.ifscCode && validation.errors.ifscCode ? (
                  <FormFeedback type='invalid'>
                    <div>{validation.errors.ifscCode}</div>
                  </FormFeedback>
                ) : null}
              </Col>
              <Col md='6'>
                <Label>
                  Account Number <span className='text-danger'>*</span>
                </Label>
                <Input
                  name='accountNo'
                  className='form-control'
                  placeholder='Enter account number'
                  type='number'
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values?.accountNo}
                  invalid={validation.touched.accountNo && validation.errors.accountNo ? true : false}
                />
                {validation.touched.accountNo && validation.errors.accountNo ? (
                  <FormFeedback type='invalid'>
                    <div>{validation.errors.accountNo}</div>
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>
          ) : null}

          <Row className='mt-4'>
            <Col md='6'>
              <Label>
                Type<span className='text-danger'>*</span>
              </Label>
              <Select
                placeholder='Transaction Type'
                value={selectedtranType}
                openMenuOnFocus={true}
                onChange={(selectedOption) => {
                  handletranTypeChange(selectedOption);
                  validation.setFieldValue('tranType', selectedOption?.label);
                  validation.handleChange('tranType');
                }}
                isSearchable={true}
                options={transTpes.map((value) => ({
                  value: value.value,
                  label: value.name
                }))}
                name='tranType'
                isLoading={false}
                loadingMessage={() => 'Fetching Transaction Type'}
                noOptionsMessage={() => 'Transaction Type appears here'}
              />
              {validation.touched.tranType && validation.errors.tranType ? (
                <div className='text-error '>{validation.errors.tranType}</div>
              ) : null}
            </Col>
            <Col md='6'>
              <Label>
                Commission<span className='text-danger'>*</span>
              </Label>
              <Input
                name='commission'
                className='form-control'
                placeholder='Enter commission'
                step={0.0031}
                type='number'
                onChange={(e) => {
                  const value = e.target.value;
                  const val = ('' + value).replace('.', '');
                  if (val?.length > 3) {
                    const suppressedValue = Number(+val / 1000).toFixed(3);
                    validation?.setFieldValue('commission', suppressedValue);
                  } else {
                    validation?.setFieldValue('commission', val);
                  }
                }}
                onBlur={(e) => {
                  if (e?.target.value?.length <= 3) {
                    const formatted = Number(e?.target.value / 1000).toFixed(3);
                    if (validation?.values?.amount === Number(e.target.value)) {
                      return;
                    } else {
                      validation.setFieldValue('commission', formatted);
                    }
                  }
                }}
                value={validation.values.commission || ''}
                invalid={validation.touched.commission && validation.errors.commission ? true : false}
              />
              {validation.touched.commission && validation.errors.commission ? (
                <FormFeedback type='invalid'>
                  <div>{validation.errors.commission}</div>
                </FormFeedback>
              ) : null}
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col>
              <Label>
                <Label>Notes</Label>
              </Label>
              <textarea
                id='notes'
                name='notes'
                rows='4'
                className='form-control'
                placeholder='Enter Notes...'
                onChange={(e) => setText(e?.target?.value)}
                value={text}
              >
                At w3schools.com you will learn how to make a website. They offer free tutorials in all web development technologies.
              </textarea>
            </Col>
          </Row>

          <div className='mt-3 text-end'>
            <button className='btn btn-primary btn-block' type='submit'>
              {currentUser.accountCode ? 'Update' : 'Save'}
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AccountModal;
