import axios from 'axios';
import {toast} from 'react-toastify';
import {PURCHASE} from '../apiRoutes';
import {GET_PURCHASE_LIST, POST_PURCHASE_SAVE, POST_PURCHASE_DELETE, GET_PURCHASE_ID, GET_PURCHASE_PENDING_ITEMS} from './actionTypes';
import moment from 'moment';

import {errorHandlers} from './../../helpers/errorHandlers';
import {loaderStart, loaderStop} from '../actions';

export const getPurchaseList = (date, supplierId, heading) => async (dispatch) => {
  dispatch(loaderStart());

  let supplier = '';

  if (supplierId) {
    supplier = supplierId;
  } else {
    supplier = '';
  }
  const date1 = moment(date[0]).format('YYYY-MM-DD');
  const date2 = moment(date[1]).format('YYYY-MM-DD');
  let key = '';
  let pType = 0;

  if (heading?.includes('Purchase Currency')) {
    pType = 2;
  } else {
    pType = 1;
  }

  if (heading?.includes('Invoice')) {
    key = 'Invoice';
  } else {
    key = 'Order';
  }

  let url = PURCHASE + key + '/GetList' + '?fromDate=' + date1 + '&toDate=' + date2 + '&supplierID=' + supplier + '&purchaseType=' + pType;

  axios
    .get(url)
    .then((res) => {
      let productList = [];
      res.map((a) => {
        if (a.purchaseInvoiceList) {
          productList = a.purchaseInvoiceList.map((b) => {
            b.productName = b.product;
            return b;
          });
        }

        if (a.purchaseOrderList) {
          productList = a.purchaseOrderList;
        }
        a.supplierName = a.supplierName || a.supplier;
        a.heading = heading;
        a.date = moment(a.poDate || a.piDate).format('DD/MM/YYYY');
        a.orderNo = a?.piNumber || a?.poNumber;
        a.productList = productList;
      });
      dispatch(loaderStop());
      dispatch({
        type: GET_PURCHASE_LIST,
        payload: res
      });
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};

export const savePurchase = (rows, purchaseInfo, navigate, delList, heading) => async (dispatch) => {
  dispatch(loaderStart());
  let pType = 0;

  if (heading?.includes('Purchase Currency')) {
    pType = 2;
  } else {
    pType = 1;
  }

  let key = '',
    path = '',
    data = [],
    productList = [];

  let del = delList.map((a) => {
    let obj = {
      id: a.id,
      productID: a.product?.id,
      qty: a.qty,
      rate: a.rate,
      amount: a.amount,
      isHold: a.fix,
      operationToPerform: 3
    };

    if (heading?.includes('Invoice')) {
      obj.oldQty = 0;
      obj.product = a.product?.label;
    } else {
      obj.productName = a.product?.label;
    }

    if (a.poid || a.poid == 0) {
      obj.poid = a.poid;
    }
    if (a.piid || a.piid == 0) {
      obj.piid = a.piid;
    }
    if (a.podid || a.podid == 0) {
      obj.podid = a.podid;
    }
    return obj;
  });

  productList = rows.map((a) => {
    let obj = {
      qty: a.qty,
      rate: Number(a.rate),
      amount: Number(a.amount),
      isHold: a.fix,
      productID: a.product?.id
    };

    if (heading?.includes('Invoice')) {
      obj.oldQty = a.prevQty || a.oldQty || 0;
      obj.product = a.product?.label;
    }

    if (heading?.includes('Order')) {
      obj.productName = a.product?.label;
    }

    if (a.id || a.id == 0) {
      obj.id = a.id;
    }
    if (a.newItem || a.isPull) {
      obj.operationToPerform = 1;
    } else {
      if (a.amount == a.amount2) {
        obj.operationToPerform = 0;
      } else {
        obj.operationToPerform = 2;
      }
    }

    if (a.poid || a.poid == 0) {
      obj.poid = a.poid;
    }
    if (a.piid || a.piid == 0) {
      obj.piid = a.piid;
    }
    if (a.podid || a.podid == 0) {
      obj.podid = a.podid;
    }

    return obj;
  });
  productList.push(...del);

  if (purchaseInfo.id) {
    productList.map((a) => {
      if (heading?.includes('Order') && a.id != 0) {
        a.poid = purchaseInfo.id;
      }
    });
  }

  data = {
    id: purchaseInfo.id,
    purchaseType: pType,
    supplierID: purchaseInfo?.supplier?.value,
    supplierName: purchaseInfo.supplier?.label,
    totalAmount: purchaseInfo.total,
    purchaseAccountID: purchaseInfo.account?.value,
    purchaseAccountName: purchaseInfo.account?.label,
    operationToPerform: purchaseInfo.id ? 2 : 1
  };

  if (heading?.includes('Invoice')) {
    data.purchaseInvoiceList = productList;
    data.piDate = purchaseInfo.date1;
    data.piNumber = purchaseInfo.nextPO;

    key = 'PurchaseInvoice';

    if (pType == 2) {
      path = '/purchase/currency/invoice';
    } else {
      path = '/purchase/metal/invoice';
    }
  } else if (heading?.includes('Order')) {
    data.purchaseOrderList = productList;
    data.poDate = purchaseInfo.date1;
    data.poNumber = purchaseInfo.nextPO;

    key = 'PurchaseOrder';
    if (pType == 2) {
      path = '/purchase/currency/order';
    } else {
      path = '/purchase/metal/order';
    }
  }

  const url = '/' + key + '/Save' + key;

  axios
    .post(url, data)
    .then((response) => {
      if (purchaseInfo.id) {
        toast.success(heading?.split('Purchase')[1] + ' updated successfully');
        navigate(path);
      } else {
        toast.success(response?.errorMessage, {autoClose: 2000});
        navigate(path);
      }

      dispatch(loaderStop());
      dispatch({
        type: POST_PURCHASE_SAVE,
        payload: {value: purchaseInfo.supplier?.value, label: purchaseInfo.supplier?.label}
      });
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};

export const deletePurchase = (purchaseInfo, heading) => async (dispatch) => {
  dispatch(loaderStart());
  let key = '';
  if (heading?.includes('Invoice')) {
    key = 'Invoice';
  } else {
    key = 'Order';
  }

  const url = PURCHASE + key + '/Delete' + PURCHASE + key + '/' + purchaseInfo.id;

  axios
    .post(url)
    .then((response) => {
      if (response == 1) {
        toast.success(heading?.split('Purchase')[1] + ' deleted successfully');
      } else {
        toast.error("Action Can't be Performed");
      }

      dispatch(loaderStop());
      dispatch({
        type: POST_PURCHASE_DELETE,
        payload: response
      });
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};

export const getPendingPurchaseItems = (suppId, heading) => async (dispatch) => {
  dispatch(loaderStart());

  let pType = 0;

  if (heading?.includes('Purchase Currency')) {
    pType = 2;
  } else {
    pType = 1;
  }

  const url = '/PurchaseInvoice/GetPendingForInvoiceItems' + '?SupplierID=' + suppId + '&PurchaseType=' + pType;

  axios
    .get(url)
    .then((res) => {
      dispatch(loaderStop());

      res.map((a) => {
        a.date = moment(a.poDate).format('DD/MM/YYYY');
        a.isCheck = false;
        a.added = false;

        if (!a.isHold) {
          a.amount = (a.penForInvQty * a.poRate) / 1000;
        }
      });

      dispatch({
        type: GET_PURCHASE_PENDING_ITEMS,
        payload: res
      });
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};
