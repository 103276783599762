import React from 'react';
import TableContainer from '../../../components/Common/ReportWithoutPagination';

const GroupTable = (props) => {
  const columns = [
    {
      Header: 'Particular',
      accessor: 'accountName',
      Filter: false
    },
    {
      Header: 'Group',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.groupName}</div>;
      },
      Filter: false
    },
    {
      Header: 'Opening Balance',
      headerClassName: 'text-center',
      columns: [
        {
          Header: 'Debit',
          accessor: 'opDrBalance',
          Cell: (cell) => {
            return <div>{cell?.cell?.row?.original.opDrBalance == 0 ? null : cell?.cell?.row?.original.opDrBalance}</div>;
          },
          // Use a two-stage aggregator here to first
          // count the total rows being aggregated,
          // then sum any of those counts if they are
          // aggregated further
          // aggregate: ["sum", "count"],
          Aggregated: ({cell: {value}}) => `${value} Names`
        },
        {
          Header: 'Credit',
          accessor: 'opCrBalance',
          Cell: (cell) => {
            return <div>{cell?.cell?.row?.original.opCrBalance == 0 ? null : cell?.cell?.row?.original.opCrBalance}</div>;
          },
          // Use another two-stage aggregator here to
          // first count the UNIQUE values from the rows
          // being aggregated, then sum those counts if
          // they are aggregated further
          aggregate: ['sum', 'uniqueCount'],
          Aggregated: ({cell: {value}}) => `${value} Unique Names`
        }
      ]
    },
    {
      Header: 'Transaction Balance',
      headerClassName: 'text-center',
      columns: [
        {
          Header: 'Debit',
          accessor: 'tranDrBalance',
          Cell: (cell) => {
            return <div>{cell?.cell?.row?.original.tranDrBalance == 0 ? null : cell?.cell?.row?.original.tranDrBalance}</div>;
          },
          // Use a two-stage aggregator here to first
          // count the total rows being aggregated,
          // then sum any of those counts if they are
          // aggregated further
          // aggregate: ["sum", "count"],
          Aggregated: ({cell: {value}}) => `${value} Names`
        },
        {
          Header: 'Credit',
          accessor: 'tranCrBalance',
          Cell: (cell) => {
            return <div>{cell?.cell?.row?.original.tranCrBalance == 0 ? null : cell?.cell?.row?.original.tranCrBalance}</div>;
          },
          // Use another two-stage aggregator here to
          // first count the UNIQUE values from the rows
          // being aggregated, then sum those counts if
          // they are aggregated further
          aggregate: ['sum', 'uniqueCount'],
          Aggregated: ({cell: {value}}) => `${value} Unique Names`
        }
      ]
    },
    {
      Header: 'Closing Balance',
      headerClassName: 'text-center',
      columns: [
        {
          Header: 'Debit',
          accessor: 'clDrAmount',
          Cell: (cell) => {
            return <div>{cell?.cell?.row?.original.clDrAmount == 0 ? null : cell?.cell?.row?.original.clDrAmount}</div>;
          },
          // Use a two-stage aggregator here to first
          // count the total rows being aggregated,
          // then sum any of those counts if they are
          // aggregated further
          // aggregate: ["sum", "count"],
          Aggregated: ({cell: {value}}) => `${value} Names`
        },
        {
          Header: 'Credit',
          accessor: 'clCrAmount',
          Cell: (cell) => {
            return <div>{cell?.cell?.row?.original.clCrAmount == 0 ? null : cell?.cell?.row?.original.clCrAmount}</div>;
          },
          // Use another two-stage aggregator here to
          // first count the UNIQUE values from the rows
          // being aggregated, then sum those counts if
          // they are aggregated further
          aggregate: ['sum', 'uniqueCount'],
          Aggregated: ({cell: {value}}) => `${value} Unique Names`
        }
      ]
    }
  ];
  return (
    <>
      {props?.tableData && props?.tableData ? (
        <TableContainer columns={columns} data={props?.tableData} isGlobalFilter={false} customPageSize={20} />
      ) : (
        <></>
      )}
    </>
  );
};

export default GroupTable;
