/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {Button, Card, CardBody, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';

import {useEffect, useState} from 'react';
import moment from 'moment';
import InvoiceListTable from './accountsTable';
import AccounModal from './accountModal';
import {useDispatch, useSelector} from 'react-redux';
import {
  deleteTransactionData,
  downloadTransactionData,
  getAccounts,
  getAccountsCounts,
  getCityTran,
  getCompanyConfigurtion,
  getTransactionData,
  loaderStart,
  transactionOkStatus
} from '../../store/actions';
import LeaderReport from './leaderReport';
import {REMOVE_ACCOUNT_DATA} from '../../store/Accounts/actionTypes';
import TransactionOperation from './transactionOperation';
import TransactionPassword from './transactionPassword';
import {CHECK_PASSWORD_TRAN_FALSE} from '../../store/Transjaction/actionTypes';
const Transactions = (args) => {
  document.title = 'Transactions - account-books';
  const [activeTab2, setactiveTab2] = useState('1');
  const [modal_invoice, setmodal_invoice] = useState(false);
  const [isPast, setIsPast] = useState(false);
  const [modalpassword, setModalpassword] = useState(false);
  const [leaderReport, setLeaderReport] = useState(false);
  const [isedit, setIsEdit] = useState(false);
  const [isType, setIsType] = useState(false);
  const [transLock, setTransLock] = useState(false);
  const [transStatus, setTransStatus] = useState(false);

  const {transactionData, password, currentAccountData, configurationData, passwordList} = useSelector((state) => ({
    transactionData: state.transjaction.transactionData,
    password: state.transjaction.password,
    currentAccountData: state.accounts.currentAccountData,
    configurationData: state.companyConfiguration.configurationData,
    passwordList: state.transjaction.passwordList
  }));

  const [date, setDate] = useState([new Date()]);
  const [deleteUser, setdeleteUser] = useState('');
  const [modal, setModal] = useState(false);
  const [leaderId, setLeaderId] = useState('');
  const [currentUser, serCurrentUser] = useState('');

  useEffect(() => {
    configurationData.length > 0 &&
      configurationData?.map((a) => {
        if (a.code == 'TranLock') {
          a.value == 'true' ? setTransLock(true) : setTransLock(false);
        }
        if (a.code == 'TranStatus') {
          a.value == 'true' ? setTransStatus(true) : setTransStatus(false);
        }
      });
  }, [configurationData]);

  const onDelteClick = (user) => {
    setdeleteUser(user);
    setModal(true);
  };

  const tog_invoice = (type) => {
    if (type === 'add') {
      onclearValue();
    }
    setmodal_invoice(!modal_invoice);
  };

  const onclearValue = () => {
    serCurrentUser('');
    setdeleteUser('');
    setactiveTab2('1');
    setModal(false);
    setIsEdit(false);
  };
  const deleteUserAccount = (id) => {
    let active = '';
    let isdelte = '';
    if (activeTab2 == 1) {
      active = '';
      isdelte = 0;
    } else if (activeTab2 == 2) {
      active = '2';
      isdelte = 0;
    } else if (activeTab2 == 3) {
      active = '1';
      isdelte = '0';
    } else {
      active = '';
      isdelte = 1;
    }
    dispatch(deleteTransactionData(id, active, isdelte));
    setModal(false);
  };

  // useEffect(() => {
  //   var dDiff = moment().diff(date[0], 'days');
  //   if (dDiff !== 0 && !currentUser) {
  //     setIsPast(true);
  //     // dispatch({
  //     //   type: CHECK_PASSWORD_TRAN_FALSE
  //     // });
  //   } else {
  //     setIsPast(false);
  //   }
  // }, [currentUser]);
  const ApproveStatus = (id) => {
    let active = '';
    if (activeTab2 == 1) {
      active = '';
    } else if (activeTab2 == 3) {
      active = '1';
    }
    dispatch(transactionOkStatus(id, moment(date[0]).format('MM/DD/YYYY'), active));
    setModal(false);
  };
  const editClick = (data) => {
    setIsEdit(true);
    setTimeout(() => {
      setIsEdit(false);
    }, '1000');
    serCurrentUser(data);
  };
  const toggle = () => {
    onclearValue();
    setModal(!modal);
  };
  const toggle2 = (tab) => {
    if (activeTab2 !== tab) {
      setactiveTab2(tab);
    }
    if (tab === '4') {
      dispatch(getTransactionData(moment(date[0]).format('MM/DD/YYYY'), '', 1), activeTab2, 0);
    } else if (tab == '1') {
      dispatch(getTransactionData(moment(date[0]).format('MM/DD/YYYY'), '', 0));
    } else if (tab == '3') {
      dispatch(getTransactionData(moment(date[0]).format('MM/DD/YYYY'), 1, 0));
    } else {
      dispatch(getTransactionData(moment(date[0]).format('MM/DD/YYYY'), tab, 0));
    }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAccounts());
    dispatch(getAccountsCounts());
    dispatch(getCityTran());
    dispatch(getCompanyConfigurtion());
    return () => {
      dispatch({
        type: REMOVE_ACCOUNT_DATA
      });
    };
  }, [dispatch]);
  useEffect(() => {
    return () => {
      dispatch({
        type: REMOVE_ACCOUNT_DATA
      });
    };
  }, []);
  useEffect(() => {
    if (!isedit) {
      let active = '';
      let isdelte = '';
      if (activeTab2 == 1) {
        active = '';
        isdelte = 0;
      } else if (activeTab2 == 2) {
        active = '2';
        isdelte = 0;
      } else if (activeTab2 == 3) {
        active = '1';
        isdelte = '0';
      } else {
        active = '';
        isdelte = 1;
      }
      dispatch(getTransactionData(moment(date[0]).format('MM/DD/YYYY'), active, isdelte));
    }
  }, [date]);

  const togglePassword = () => {
    setModalpassword(!setModalpassword);
  };
  const togglePassword1 = (val) => {
    setModalpassword(true);
    setIsType(val);
  };

  const handleChangeLeader = (id) => {
    setLeaderId(id);
    setLeaderReport(true);
  };

  const handleChangeLeaderClose = () => {
    setLeaderId();
    setLeaderReport(false);
  };

  const ondownloadClick = (type) => {
    if (activeTab2 === '4') {
      dispatch(downloadTransactionData(moment(date[0]).format('MM/DD/YYYY'), '', 1, type,transStatus), activeTab2, 0);
    } else if (activeTab2 == '1') {
      dispatch(downloadTransactionData(moment(date[0]).format('MM/DD/YYYY'), '', 0, type,transStatus));
    } else if (activeTab2 == '3') {
      dispatch(downloadTransactionData(moment(date[0]).format('MM/DD/YYYY'), 1, 0, type,transStatus));
    } else {
      dispatch(downloadTransactionData(moment(date[0]).format('MM/DD/YYYY'), activeTab2, 0, type,transStatus));
    }
  };

  const habdleDateChange = (date) => {
    setDate(date);
  };

  const setPasValue = (value) => {
    setIsPast(value);
  };
  return (
    <>
      <div className='page-content abc transactions-table-width'>
        <Container fluid={true}>
          <Breadcrumbs title='Transactions - account-books' breadcrumbItem='Transactions' />
          <Row>
            <Card>
              <CardBody>
                <TransactionOperation
                  currentUser={currentUser}
                  serCurrentUser={() => serCurrentUser()}
                  setModalpassword={(val) => togglePassword1(val)}
                  tog_invoice={() => tog_invoice()}
                  activeTab2={activeTab2}
                  setIsPast={(val) => setPasValue(val)}
                  isPast={isPast}
                  habdleDateChange={(date) => habdleDateChange(date)}
                  date={date}
                  transLock={transLock}
                  transStatus={transStatus}
                />
              </CardBody>
            </Card>
          </Row>
          <Card>
            <CardBody>
              <Row>
                <div id='table-invoices-list'>
                  <InvoiceListTable
                    tableData={transactionData}
                    onEditClick={(data) => editClick(data)}
                    ondownloadClick={(type) => ondownloadClick(type)}
                    ApproveStatus={(id) => ApproveStatus(id)}
                    handleChangeLeader={(id) => handleChangeLeader(id)}
                    ondeleteClick={(data) => onDelteClick(data)}
                    togglePassword={(val) => togglePassword1(val)}
                    password={passwordList}
                    activeTab2={activeTab2}
                    toggle2={(tab) => toggle2(tab)}
                    transLock={transLock}
                    transStatus={transStatus}
                  />
                </div>
                <div className='mt-3 text-end'></div>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
      <AccounModal modal_invoice={modal_invoice} tog_invoice={() => tog_invoice()} setmodal_invoice={() => setmodal_invoice()} />
      <TransactionPassword
        modalpassword={modalpassword}
        compType='Transaction'
        togglePassword={(val) => togglePassword(val)}
        setIsPast={(val) => setPasValue(val)}
        isType={isType}
      />
      <LeaderReport leaderReport={leaderReport} handleChangeLeaderClose={() => handleChangeLeaderClose()} leaderId={leaderId} />
      <>
        <Modal isOpen={modal} toggle={toggle} {...args}>
          <ModalHeader toggle={toggle}>Delete Transaction </ModalHeader>
          <ModalBody>Are you sure to delete this Transaction ?</ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={() => deleteUserAccount(deleteUser)}>
              Delete
            </Button>{' '}
            <Button color='secondary' onClick={() => toggle()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>{' '}
      </>
    </>
  );
};
export default Transactions;
