import React from 'react';
import TableContainer from '../../../components/Common/TableContainer';
// import {Button} from 'reactstrap';

const UsersTable = (props) => {
  const columns = [
    {
      Header: 'User Name',
      accessor: 'userName',
      Filter: false
    },
    {
      Header: 'Role',
      accessor: 'role',
      Filter: false
    },
    {
      Header: 'Companies',
      // accessor: 'companyName',
      Cell: (cell) => {
        const company = cell?.cell?.row?.original?.companyName;
        const companyList = company?.split(',');

        return companyList?.map((data, index) => (
          <span className='me-2 badge badge-pill badge-soft-info font-size-14' key={index}>
            {data}
          </span>
        ));
      },
      Filter: false
    },
    {
      Header: 'Email',
      accessor: 'email',
      Filter: false
    },

    {
      Header: 'Is Active',
      Cell: (cell) => {
        if (cell?.cell?.row?.original?.isActive == true) {
          return <span className=' badge badge-pill badge-soft-success font-size-12'>Active</span>;
        } else {
          return <span className=' badge badge-pill badge-soft-warning font-size-12'>Inactive </span>;
        }
      }
    },

    {
      Header: 'Action',
      Cell: (cell) => {
        return (
          <>
            <span
              className=' cursor text-primary'
              onClick={() => {
                props?.onEditClick(cell?.cell?.row?.original);
              }}
            >
              <i className='bx bx-pencil font-size-16'></i>
            </span>
            <span
              className=' cursor text-danger'
              onClick={() => {
                props?.onpassClick(cell?.cell?.row?.original);
              }}
            >
              {' '}
              <i
                className='bx bx-key
 font-size-18'
              ></i>
            </span>
            {cell?.cell?.row?.original?.isBlocked && (
              <span
                className=' cursor text-danger'
                onClick={() => {
                  props?.onUnblockClick(cell?.cell?.row?.original);
                }}
              >
                {' '}
                <i className='bx bx-lock-open-alt font-size-18'></i>
              </span>
            )}
          </>
        );
      }
    }
  ];

  return (
    <React.Fragment>
      <TableContainer columns={columns} data={props?.UsersData} isGlobalFilter={true} customPageSize={20} className='Invoice table' />
    </React.Fragment>
  );
};

export default UsersTable;
