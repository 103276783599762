import React from 'react';
import {Modal, ModalBody, ModalHeader, ModalFooter, Button} from 'reactstrap';

function UsersUnBlockModal(props) {
  return (
    <>
      <Modal
        isOpen={props?.modalBlockUser}
        onClosed={() => props?.toggle()}
        toggle={() => {
          props?.toggle();
        }}
        centered
      >
        <ModalHeader>Confirm</ModalHeader>
        <ModalBody>{`Are you sure you want to unblock user ${props?.data?.userName}`} ?</ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={() => {
              props?.callUnblockApi(props?.data);
              props?.toggle();
            }}
          >
            Unblock
          </Button>{' '}
          <Button color='secondary' onClick={() => props?.toggle()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default UsersUnBlockModal;
