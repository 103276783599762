import React, {useEffect} from 'react';
import {Navigate} from 'react-router-dom';

import {useProfile} from '../Hooks/UserHooks';
import {useDispatch, useSelector} from 'react-redux';
import {loaderStart} from '../store/actions';

const AuthProtected = (props) => {
  const {userProfile, loading} = useProfile();
  const dispatch = useDispatch();

  useEffect(() => {
    if (useProfile && !loading) dispatch(loaderStart());
  }, [loading]);
  /*
    redirect is un-auth access protected routes via url
    */

  if (!userProfile && loading) {
    return <Navigate to={{pathname: '/login', state: {from: props.location}}} />;
  }
  return <>{props.children}</>;
};

export {AuthProtected};
