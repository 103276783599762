import axios from 'axios';
import {toast} from 'react-toastify';
import {PL_LIST, PL_DETAIL} from '../apiRoutes';
import {POST_PROFITLOSS_DETAIL, POST_PROFITLOSS_LIST} from './actionTypes';
import moment from 'moment';

import {errorHandlers} from './../../helpers/errorHandlers';
import {loaderStart, loaderStop} from '../actions';

export const getListPL = (date) => async (dispatch) => {
  dispatch(loaderStart());

  const date1 = moment(date[0]).format('YYYY-MM-DD');
  const date2 = moment(date[1]).format('YYYY-MM-DD');

  let url = PL_LIST + '?fromDate=' + date1 + '&toDate=' + date2;

  axios
    .post(url)
    .then((res) => {
      dispatch(loaderStop());
      dispatch({
        type: POST_PROFITLOSS_LIST,
        payload: res
      });
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};

export const getDetailPL = (date, acc) => async (dispatch) => {
  dispatch(loaderStart());

  const date1 = moment(date[0]).format('YYYY-MM-DD');
  const date2 = moment(date[1]).format('YYYY-MM-DD');

  let url = PL_DETAIL + '?account=' + acc + '&fromDate=' + date1 + '&toDate=' + date2;

  axios
    .post(url)
    .then((res) => {

      dispatch(loaderStop());
      dispatch({
        type: POST_PROFITLOSS_DETAIL,
        payload: res
      });
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};
