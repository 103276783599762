import {PARTYSTOCK_FETCH_SUCCESS, PARTYSTOCK_FETCH_ERROR, PARTYSTOCK_REMOVE_DATA} from './actionTypes';

const initialState = {
  error: '',
  partyStockList: [],
  loading: false,
  refresh: false
};

const partyStockReducer = (state = initialState, action) => {
  switch (action.type) {
    case PARTYSTOCK_FETCH_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: '',
        partyStockList: action.payload
      };
      break;
    case PARTYSTOCK_FETCH_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload
      };
      break;
    case PARTYSTOCK_REMOVE_DATA:
      state = {
        ...state,
        loading: false,
        partyStockList: []
      };
      break;

    default:
      state = {...state};
      break;
  }
  return state;
};

export default partyStockReducer;
