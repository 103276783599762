import axios from 'axios';
import {DOWNLOAD_TRAIL_REPORT_PDF, DOWNLOAD_TRIAL_REPORT_EXCEL, TRIAL_BALANCE} from '../apiRoutes';
import {TRIALBALANCE_FETCH_SUCCESS, TRIALBALANCE_FETCH_ERROR, TRIALBALANCE_LOADER_START, TRIALBALANCE_LOADER_STOP} from './actionTypes';

import {errorHandlers} from '../../helpers/errorHandlers';
import {loaderStart, loaderStop} from '../actions';
import moment from 'moment';

export const getTrialBalanceList = (check, date, selectedGroup) => async (dispatch) => {
  // dispatch(loaderStart());
  let url;
  dispatch({
    type: TRIALBALANCE_LOADER_START
  });
  let groupId = selectedGroup ? selectedGroup : 0;
  const date1 = moment(date[0]).format('YYYY-MM-DD');
  const date2 = moment(date[1]).format('YYYY-MM-DD');
  if (check) {
    url = TRIAL_BALANCE + '?fromDate=' + date1 + '&toDate=' + date2 + '&accountGroupId=' + groupId;
  } else {
    url = TRIAL_BALANCE + '?fromDate=' + '' + '&toDate=' + '' + '&accountGroupId=' + 0;
  }

  axios
    .get(url)
    .then((response) => {
      dispatch(loaderStop());
      dispatch({
        type: TRIALBALANCE_LOADER_STOP
      });
      dispatch({
        type: TRIALBALANCE_FETCH_SUCCESS,
        payload: response
      });
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
      dispatch({
        type: TRIALBALANCE_LOADER_STOP
      });
    });
};

export const downloadTrialList = (date, name, type, value, label, check) => async (dispatch) => {
  dispatch(loaderStart());
  let name1 = name?.trim();
  if (!name1) {
    name1 = 'Trial Balance Report';
  }
  let tag;
  if (!check || value == '' || value === undefined) {
    value = 0;
  }
  if (!check || label === undefined) {
    label = 'All';
  }
  if (type == 'pdf') {
    tag = '.pdf';
  } else {
    tag = '.csv';
  }
  let date1;
  let date2;
  if (!check) {
    date1 = '';
    date2 = '';
  } else {
    date1 = moment(date[0]).format('YYYY-MM-DD');
    date2 = moment(date[1]).format('YYYY-MM-DD');
  }
  const url =
    type == 'csv'
      ? DOWNLOAD_TRIAL_REPORT_EXCEL + '?fromDate=' + date1 + '&toDate=' + date2 + '&accountGroupId=' + value + '&accountGroupName=' + label
      : DOWNLOAD_TRAIL_REPORT_PDF + '?fromDate=' + date1 + '&toDate=' + date2 + '&accountGroupId=' + value + '&accountGroupName=' + label;
  axios
    .get(url, {
      responseType: 'blob'
    })
    .then((response) => {
      dispatch(loaderStop());
      const url = URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = url;
      link.download = name1 + tag;
      link.click();
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};
