import {
  GET_USERS_ERROR,
  GET_USERS,
  GET_USERS_SUCCESS,
  USER_SAVE_START,
  USER_SAVE_SUCCESS,
  USER_SAVE_ERROR,
  USER_ROLE_SUCCEESS
} from './actionTypes';

const initialState = {
  error: '',
  usersList: [],
  loading: false
};

const usersList = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_ERROR:
      state = {
        ...state,
        loading: true,
        error: ''
      };
      break;
    case GET_USERS:
      state = {
        ...state,
        loading: false,
        error: '',
        usersList: action.payload
      };
      break;
    case GET_USERS_SUCCESS:
      state = {
        ...state,
        usersList: action.payload,
        loading: false
      };
      break;
    case USER_SAVE_START:
      state = {
        ...state,
        loading: true,
        error: ''
      };
      break;
    case USER_SAVE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: '',
        usersList: action.payload
      };
      break;
    case USER_SAVE_ERROR:
      state = {
        ...state,
        usersList: action.payload,
        loading: false
      };
      break;
    case USER_ROLE_SUCCEESS:
      state = {
        ...state,
        userRole: action.payload,
        loading: false
      };
      break;
    default:
      state = {...state};
      break;
  }
  return state;
};

export default usersList;
