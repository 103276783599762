/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody, Col, Container, Row, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {useDispatch, useSelector} from 'react-redux';
import {getAccounts, getCompanyConfigurtion, getCurrency, setCompanyConfigurtion} from '../../../store/actions';
import Select from 'react-select';
import {COMAPNY_CONFIG_PENDINGENTRY} from '../../../store/apiRoutes';
import axios from 'axios';

const Configuration = (args) => {
  document.title = 'Configuration  - account-books';
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [selectedBrokerage, setSelectedBrokerage] = useState('');
  const [selectedMetal, setSelectedMetal] = useState('');
  const [selectedCurrencySales, setSelectedCurrencySales] = useState('');
  const [selectedeMetalPurchase, setSelectedMetalPurchase] = useState('');
  const [selectedeCurrencyPurchase, setSelectedCurrencyPurchase] = useState('');
  const [selectedeCashBook, setSlectedCashBook] = useState('');
  const [city, setCity] = useState(false);
  const [tranLock, setTranLock] = useState(false);
  const [cashLock, setCashLock] = useState(false);
  const [bankLock, setBankLock] = useState(false);
  const [tranStatus, setTranStatus] = useState(false);
  const [cashStatus, setCashStatus] = useState(false);
  const [bankStatus, setBankStatus] = useState(false);
  const [pendCount, setPendCount] = useState(0);
  const [code, setCode] = useState('');

  const dispatch = useDispatch();
  const {accounts, currency, configurationData} = useSelector((state) => ({
    accounts: state.accounts.accounts,
    currency: state.companyConfiguration.currency,
    configurationData: state.companyConfiguration.configurationData
  }));

  useEffect(() => {
    dispatch(getAccounts());
    dispatch(getCurrency());

    dispatch(getCompanyConfigurtion());
  }, [dispatch]);

  useEffect(() => {
    configurationDataset();
  }, [configurationData, currency, accounts, dispatch]);

  const updateComapnyConfigurations = (value, code) => {
    dispatch(setCompanyConfigurtion(value, code));
  };
  const configurationDataset = () => {
    configurationData.length > 0 &&
      configurationData?.map((item) => {
        if (item?.code == 'BaseCurrency') {
          currency?.map((foo) => {
            if (foo?.id == item.value) {
              setSelectedCurrency({
                value: foo.id,
                label: foo?.currencyName
              });
            }
          });
        } else if (item?.code === 'ComAccount') {
          accounts?.map((foo) => {
            if (foo?.id == item.value) {
              setSelectedBrokerage({
                value: foo?.id,
                label: foo?.accountName
              });
            }
          });
        } else if (item?.code == 'SalesAccount') {
          accounts?.map((foo) => {
            if (foo?.id == item.value) {
              setSelectedMetal({
                value: foo?.id,
                label: foo?.accountName
              });
            }
          });
        } else if (item?.code == 'SalesAccountCurr') {
          accounts?.map((foo) => {
            if (foo?.id == item.value) {
              setSelectedCurrencySales({
                value: foo?.id,
                label: foo?.accountName
              });
            }
          });
        } else if (item?.code == 'PurchaseAccount') {
          accounts?.map((foo) => {
            if (foo?.id == item.value) {
              setSelectedMetalPurchase({
                value: foo?.id,
                label: foo?.accountName
              });
            }
          });
        } else if (item?.code == 'PurchaseAccountCurr') {
          accounts?.map((foo) => {
            if (foo?.id == item.value) {
              setSelectedCurrencyPurchase({
                value: foo?.id,
                label: foo?.accountName
              });
            }
          });
        } else if (item?.code == 'CashAccount') {
          accounts?.map((foo) => {
            if (foo?.id == item.value) {
              setSlectedCashBook({
                value: foo?.id,
                label: foo?.accountName
              });
            }
          });
        } else if (item?.code == 'City') {
          item?.value == 'true' && setCity(true);
        } else if (item?.code == 'TranLock') {
          item?.value == 'true' && setTranLock(true);
        } else if (item?.code == 'CashLock') {
          item?.value == 'true' && setCashLock(true);
        } else if (item?.code == 'BankLock') {
          item?.value == 'true' && setBankLock(true);
        } else if (item?.code == 'TranStatus') {
          item?.value == 'true' && setTranStatus(true);
        } else if (item?.code == 'CashStatus') {
          item?.value == 'true' && setCashStatus(true);
        } else if (item?.code == 'BankStatus') {
          item?.value == 'true' && setBankStatus(true);
        }
      });
  };

  const handleDropDownChange = (selectedValue, type) => {
    if (type === 'currency') {
      setSelectedCurrency(selectedValue);
      updateComapnyConfigurations(selectedValue.value, 'BaseCurrency');
    } else if (type === 'brokerage') {
      setSelectedBrokerage(selectedValue);
      updateComapnyConfigurations(selectedValue.value, 'ComAccount');
    } else if (type === 'metalSales') {
      setSelectedMetal(selectedValue);
      updateComapnyConfigurations(selectedValue.value, 'SalesAccount');
    } else if (type === 'currencySales') {
      setSelectedCurrencySales(selectedValue);
      updateComapnyConfigurations(selectedValue.value, 'SalesAccountCurr');
    } else if (type === 'metalPurchase') {
      setSelectedMetalPurchase(selectedValue);
      updateComapnyConfigurations(selectedValue.value, 'PurchaseAccount');
    } else if (type === 'currencyPurchase') {
      setSelectedCurrencyPurchase(selectedValue);
      updateComapnyConfigurations(selectedValue.value, 'PurchaseAccountCurr');
    } else if (type === 'CashAccount') {
      setSlectedCashBook(selectedValue);
      updateComapnyConfigurations(selectedValue.value, 'CashAccount');
    }
  };
  const [statusModal, setStatusModal] = useState(false);
  const toggle = () => {
    setStatusModal(!statusModal);
  };

  const getPendingEntry = (code) => {
    axios
      .get(COMAPNY_CONFIG_PENDINGENTRY + '?code=' + code)
      .then((res) => {
        setPendCount(res?.count);
        if (res.count != 0) {
          setStatusModal(true);
        } else {
          let a;
          if (code == 'TranStatus') {
            a = tranStatus;
            setTranStatus(!tranStatus);
          } else if (code == 'CashStatus') {
            a = cashStatus;
            setCashStatus(!cashStatus);
          } else {
            a = bankStatus;
            setBankStatus(!bankStatus);
          }
          updateComapnyConfigurations(!a, code);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateStatus = () => {
    let a;
    if (code == 'TranStatus') {
      a = tranStatus;
      setTranStatus(!tranStatus);
    } else if (code == 'CashStatus') {
      a = cashStatus;
      setCashStatus(!cashStatus);
    } else {
      a = bankStatus;
      setBankStatus(!bankStatus);
    }
    setStatusModal(false);
    updateComapnyConfigurations(!a, code);
  };

  const handleTranStatus = () => {
    setCode('TranStatus');

    if (tranStatus) {
      getPendingEntry('TranStatus');
    } else {
      setTranStatus(!tranStatus);
      updateComapnyConfigurations(!tranStatus, 'TranStatus');
    }
  };
  const handleCashStatus = () => {
    setCode('CashStatus');
    if (cashStatus) {
      getPendingEntry('CashStatus');
    } else {
      setCashStatus(!cashStatus);
      updateComapnyConfigurations(!cashStatus, 'CashStatus'); //boolean, code
    }
  };
  const handleBankStatus = () => {
    setCode('BankStatus');
    if (bankStatus) {
      getPendingEntry('BankStatus');
    } else {
      setBankStatus(!bankStatus);
      updateComapnyConfigurations(!bankStatus, 'BankStatus'); //boolean, code
    }
  };

  return (
    <div className='page-content'>
      <Container fluid={true}>
        <Breadcrumbs title='Company Configuration' breadcrumbItem='Configuration' />
        <Card className='cashbookMinHeight'>
          <CardBody className='heightCust'>
            <Row>
              <Col sm='6' md='3' className='mb-3'>
                <label className='control-label'>Base Currency</label>
                <div className=''>
                  <Select
                    placeholder='Select Base Currency  A/C'
                    value={selectedCurrency}
                    onChange={(selectedOption) => {
                      handleDropDownChange(selectedOption, 'currency');
                    }}
                    isSearchable={true}
                    options={currency?.map((value) => ({
                      value: value.id,
                      label: value?.currencyName
                    }))}
                    name='currency'
                    isLoading={false}
                    loadingMessage={() => 'Fetching Base Currency Purchase Account'}
                    noOptionsMessage={() => 'Currency Purchase A/C appears here'}
                  />
                </div>
              </Col>
              <Col sm='6' md='3' className='mb-2'>
                <label className='control-label'> Brokerage A/C</label>
                <div>
                  <Select
                    placeholder='Select Brokerage A/C'
                    value={selectedBrokerage}
                    onChange={(selectedOption) => {
                      handleDropDownChange(selectedOption, 'brokerage');
                    }}
                    isSearchable={true}
                    options={accounts?.map((value) => ({
                      value: value.id,
                      label: value.accountName
                    }))}
                    name='groupName'
                    isLoading={false}
                    loadingMessage={() => 'Fetching Brokerage Account'}
                    noOptionsMessage={() => 'Brokerage A/C appears here'}
                  />
                </div>
              </Col>
              <Col sm='6' md='3' className='mb-3'>
                <label className='control-label'> Metal Sales A/C</label>
                <div>
                  <Select
                    placeholder='Select Metal Sales A/C'
                    value={selectedMetal}
                    onChange={(selectedOption) => {
                      handleDropDownChange(selectedOption, 'metalSales');
                    }}
                    isSearchable={true}
                    options={accounts?.map((value) => ({
                      value: value.id,
                      label: value.accountName
                    }))}
                    name='groupName'
                    isLoading={false}
                    loadingMessage={() => 'Fetching  Metal Sales Account'}
                    noOptionsMessage={() => ' Metal Sales A/C appears here'}
                  />
                </div>
              </Col>
              <Col sm='6' md='3' className='mb-3'>
                <label className='control-label'>Currency Sales A/C</label>
                <div>
                  <Select
                    placeholder='Select Currency A/C'
                    value={selectedCurrencySales}
                    onChange={(selectedOption) => {
                      handleDropDownChange(selectedOption, 'currencySales');
                    }}
                    isSearchable={true}
                    options={accounts?.map((value) => ({
                      value: value.id,
                      label: value.accountName
                    }))}
                    name='groupName'
                    isLoading={false}
                    loadingMessage={() => 'Fetching Currency Account'}
                    noOptionsMessage={() => 'Currency A/C appears here'}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm='6' md='3' className='mb-3'>
                <label className='control-label'>Metal Purchase A/C</label>{' '}
                <div>
                  <Select
                    placeholder='Select Metal Purchase A/C'
                    value={selectedeMetalPurchase}
                    onChange={(selectedOption) => {
                      handleDropDownChange(selectedOption, 'metalPurchase');
                    }}
                    isSearchable={true}
                    options={accounts?.map((value) => ({
                      value: value.id,
                      label: value.accountName
                    }))}
                    name='groupName'
                    isLoading={false}
                    loadingMessage={() => 'Fetching Metal Purchase Account'}
                    noOptionsMessage={() => 'Metal Purchase A/C appears here'}
                  />
                </div>
              </Col>
              <Col sm='6' md='3' className='mb-3'>
                <label className='control-label'> Currency Purchase A/C</label>

                <div>
                  <Select
                    placeholder='Select Currency  A/C'
                    value={selectedeCurrencyPurchase}
                    onChange={(selectedOption) => {
                      handleDropDownChange(selectedOption, 'currencyPurchase');
                    }}
                    isSearchable={true}
                    options={accounts?.map((value) => ({
                      value: value.id,
                      label: value.accountName
                    }))}
                    name='groupName'
                    isLoading={false}
                    loadingMessage={() => 'Fetching Currency Purchase Account'}
                    noOptionsMessage={() => 'Currency Purchase A/C appears here'}
                  />
                </div>
              </Col>
              <Col sm='6' md='3' className='mb-3'>
                <label className='control-label'> CashBook A/C</label>

                <div>
                  <Select
                    placeholder='Select CashBook  A/C'
                    value={selectedeCashBook}
                    onChange={(selectedOption) => {
                      handleDropDownChange(selectedOption, 'CashAccount');
                    }}
                    isSearchable={true}
                    options={accounts?.map((value) => ({
                      value: value.id,
                      label: value.accountName
                    }))}
                    name='groupName'
                    isLoading={false}
                    loadingMessage={() => 'Fetching CashBook  Account'}
                    noOptionsMessage={() => 'CashBook A/C appears here'}
                  />
                </div>
              </Col>
              <Col sm='6' md='3' className='mb-3 mt-5 '>
                <div className='form-check form-switch form-switch-sm mb-3' dir='ltr'>
                  <input
                    type='checkbox'
                    value={city}
                    checked={city}
                    onChange={() => {
                      setCity(!city);
                      updateComapnyConfigurations(!city, 'City');
                    }}
                    className='form-check-input'
                    id='customControlInline'
                  />
                  <label for='customControlInline' className=' ms-2 cursor'>
                    City
                  </label>
                </div>
              </Col>
            </Row>{' '}
            <Row className='mt-4'>
              <Col sm='12' md='6' className='mb-3 '>
                <Row className='bg-light'>
                  <h4 className=' mt-3 '>Lock Management</h4>
                  <Col sm='3' md='4' className='mb-3 mt-3 '>
                    <div className='form-check form-switch form-switch-sm mb-3' dir='ltr'>
                      <input
                        type='checkbox'
                        value={tranLock}
                        checked={tranLock}
                        onChange={() => {
                          setTranLock(!tranLock);
                          updateComapnyConfigurations(!tranLock, 'TranLock');
                        }}
                        className='form-check-input'
                        id='TranLock'
                      />
                      <label for='TranLock' className=' ms-2 cursor'>
                        Transaction Lock
                      </label>
                    </div>
                  </Col>
                  <Col sm='3' md='4' className='mb-3 mt-3 '>
                    <div className='form-check form-switch form-switch-sm mb-3' dir='ltr'>
                      <input
                        type='checkbox'
                        value={cashLock}
                        checked={cashLock}
                        onChange={() => {
                          setCashLock(!cashLock);
                          updateComapnyConfigurations(!cashLock, 'CashLock');
                        }}
                        className='form-check-input'
                        id='CashLock'
                      />
                      <label for='CashLock' className='control-label  ms-2 cursor'>
                        Cashbook Lock
                      </label>
                    </div>
                  </Col>
                  <Col sm='3' md='4' className='mb-3 mt-3 '>
                    <div className='form-check form-switch form-switch-sm mb-3' dir='ltr'>
                      <input
                        type='checkbox'
                        value={bankLock}
                        checked={bankLock}
                        onChange={() => {
                          setBankLock(!bankLock);
                          updateComapnyConfigurations(!bankLock, 'BankLock');
                        }}
                        className='form-check-input'
                        id='BankLock'
                      />
                      <label for='BankLock' className='control-label  ms-2 cursor'>
                        BankBook Lock
                      </label>
                    </div>
                  </Col>
                </Row>
              </Col>
              {/* <Col sm='12' md='6' className='mb-3 '></Col> */}
              <Col sm='12' md='6' className='mb-3 '>
                <Row className='bg-light'>
                  <h4 className=' mt-3 '>Status Management</h4>
                  <Col sm='3' md='4' className='mb-3 mt-3 '>
                    <div className='form-check form-switch form-switch-sm mb-3' dir='ltr'>
                      <input
                        type='checkbox'
                        value={tranStatus}
                        checked={tranStatus}
                        onChange={() => handleTranStatus()}
                        className='form-check-input'
                        id='TranStatus'
                      />
                      <label for='TranStatus' className=' ms-2 cursor'>
                        Transaction Status
                      </label>
                    </div>
                  </Col>
                  <Col sm='3' md='4' className='mb-3 mt-3 '>
                    <div className='form-check form-switch form-switch-sm mb-3' dir='ltr'>
                      <input
                        type='checkbox'
                        value={cashStatus}
                        checked={cashStatus}
                        onChange={() => handleCashStatus()}
                        className='form-check-input'
                        id='CashStatus'
                      />
                      <label for='CashStatus' className='control-label  ms-2 cursor'>
                        Cashbook Status
                      </label>
                    </div>
                  </Col>
                  <Col sm='3' md='4' className='mb-3 mt-3 '>
                    <div className='form-check form-switch form-switch-sm mb-3' dir='ltr'>
                      <input
                        type='checkbox'
                        value={bankStatus}
                        checked={bankStatus}
                        onChange={() => handleBankStatus()}
                        className='form-check-input'
                        id='BankStatus'
                      />
                      <label for='BankStatus' className='control-label  ms-2 cursor'>
                        BankBook Status
                      </label>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>

      <Modal isOpen={statusModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Pending Records: {pendCount}</ModalHeader>
        <ModalBody>Are you sure you want to approve these records?</ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={() => updateStatus()}>
            yes
          </Button>
          <Button color='secondary' onClick={() => toggle()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Configuration;
