import React, {useEffect, useState} from 'react';
import {Row, Col, Container, Modal, Card, CardBody, ModalBody, ModalFooter, Button, ModalHeader, Input} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import GroupTable from './PurchaseTable';
import axios from 'axios';
import {toast} from 'react-toastify';

import Select from 'react-select';
import 'flatpickr/dist/themes/material_blue.css';
import {useDispatch, useSelector} from 'react-redux';
import {getPurchaseList, deletePurchase} from '../../store/Purchase/actions';
import {useNavigate, useLocation} from 'react-router-dom';
import moment from 'moment';

const Purchase = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  let heading = '';
  if (location.pathname.match('/metal/order')) {
    heading = 'Purchase Metal Order';
  } else if (location.pathname.match('/metal/invoice')) {
    heading = 'Purchase Metal Invoice';
  } else if (location.pathname.match('/currency/order')) {
    heading = 'Purchase Currency Order';
  } else if (location.pathname.match('/currency/invoice')) {
    heading = 'Purchase Currency Invoice';
  }
  document.title = heading + ' - account-books';

  const purchaseResult = useSelector((state) => state.purchaseReducer);

  let prevDate = moment().subtract(6, 'day');
  const [date, setDate] = useState([prevDate._d, new Date()]);
  const [rows, setRows] = useState([]);
  const [rows2, setRows2] = useState([]);

  const [search, setSearch] = useState('');
  const [supplier, setSupplier] = useState('');
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [row, setRow] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };
  const handleConfirmDelete = () => {
    dispatch(deletePurchase(row, heading));
    setDeleteModal(false);
  };

  useEffect(() => {
    setSearch('');
  }, [heading]);

  useEffect(() => {
    let defDate = [prevDate._d, new Date()];
    setDate(defDate);
    setSupplier('');
    dispatch(getPurchaseList(defDate, '', heading));
  }, [location?.pathname]);

  useEffect(() => {
    dispatch(getPurchaseList(date, supplier?.value, heading));
  }, [purchaseResult.delRefresh]);

  useEffect(() => {
    axios.get('Common/GetSupplierList').then((res) => {
      res.map((a) => {
        a.value = a.id;
        a.label = a.accountName;
      });
      setSupplierOptions(res);
    });
  }, []);

  useEffect(() => {
    setRows(purchaseResult?.purchaseList);
    setRows2(purchaseResult?.purchaseList);
  }, [purchaseResult?.purchaseList]);

  const handleSupplier = (e) => {
    setSupplier(e);
    dispatch(getPurchaseList(date, e.value, heading));
  };

  const handleEdit = (row) => {
    setRow(row);
    navigate('/purchase/new', {state: {editRow: row, heading: heading, from: location.pathname}});
  };
  const handleDelete = (row) => {
    setRow(row);
    setDeleteModal(true);
  };

  const handleAddPurchase = () => {
    navigate('/purchase/new', {
      state: {heading: heading, from: location.pathname}
    });
  };
  const handleDate = (e) => {
    let defDate = [prevDate._d, new Date()];
    if (e.length == 0) {
      setDate(defDate);
    } else {
      setDate(e);
    }
    if (e.length > 1) {
      dispatch(getPurchaseList(e, supplier?.value, heading));
    }
  };
  const handleDateClose = (e) => {
    if (date.length != 2) {
      let defDate = [e[0], e[0]];
      setDate(defDate);
      if (!supplier?.value) {
        toast.info('Please select the supplier');
        return;
      }
      dispatch(getPurchaseList(defDate, supplier?.value, heading));
    }
  };
  const handleSearch = (key) => {
    setSearch(key);
    const result = rows2.filter((a) => {
      return (
        a.id?.toString()?.includes(key) ||
        a.totalAmount?.toString()?.includes(key) ||
        a.orderNo?.toString()?.includes(key.toLowerCase()) ||
        a.supplierName?.toLowerCase()?.includes(key.toLowerCase()) ||
        a.date?.includes(key)
      );
    });
    setRows(result);
  };

  return (
    <>
      <Modal
        isOpen={deleteModal}
        onClosed={() => setDeleteModal(false)}
        toggle={() => {
          toggleDeleteModal();
        }}
        centered
      >
        <ModalHeader>Delete {heading?.split('Purchase')[1]}</ModalHeader>
        <ModalBody>Are you sure want to delete {heading?.split('Purchase')[1]} ?</ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={() => handleConfirmDelete()}>
            Delete
          </Button>{' '}
          <Button color='secondary' onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <div className='page-content'>
        <Container fluid={true}>
          <Breadcrumbs breadcrumbItem={heading} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className='row2'>
                    <div>
                      <input
                        value={search}
                        placeholder={`(${rows.length}) Records...`}
                        className='form-control searchBar deskView2'
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                    </div>

                    <div className='row0'>
                      <div className='w220 '>
                        <Flatpickr
                          className='form-control '
                          placeholder='Date Range'
                          value={date}
                          options={{
                            mode: 'range',
                            disableMobile: true,
                            dateFormat: 'd/m/Y '
                          }}
                          onChange={(e) => handleDate(e)}
                          onClose={(e) => handleDateClose(e)}
                        />
                      </div>
                      <div className='w200 px-2 '>
                        <Select
                          placeholder='Select Supplier'
                          options={supplierOptions}
                          value={supplier}
                          onChange={(e) => handleSupplier(e)}
                        />
                      </div>

                      <div>
                        <button type='button' className='btn btn-success' onClick={handleAddPurchase}>
                          <i className='mdi mdi-plus me-1'></i>
                          <span className='deskView'>Add {heading.split('Purchase')[1]}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='mobView mb-3 mt-3 '>
                    <input
                      placeholder={`(${rows.length}) Records...`}
                      className='form-control searchBar'
                      onChange={(e) => handleSearch(e.target.value)}
                      value={search}
                    />
                  </div>
                  <div id='table-invoices-list ' className='changeHeight'>
                    {rows.length > 0 ? (
                      <GroupTable tableData={rows} handleEdit={handleEdit} handleDelete={handleDelete} heading={heading} />
                    ) : (
                      <div className='text-center mt-5 mb-4 noDAtaA'>No Data Available</div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>{' '}
    </>
  );
};

export default Purchase;
