import axios from 'axios';
import {toast} from 'react-toastify';

import {
  BB_LIST,
  BB_SAVE,
  BB_DELETE,
  BB_CSV,
  BB_PDF,
  BBC_PDF,
  BBC_CSV,
  BB_COMM_LIST,
  BB_COMM_SAVE,
  BB_COMM_DELETE,
  BB_APPROVE
} from '../apiRoutes';

import {errorHandlers} from './../../helpers/errorHandlers';
import {loaderStart, loaderStop} from '../actions';
import {POST_BB_LIST, POST_BB_SAVE, POST_BB_DELETE, POST_BB_COMM_LIST, POST_BB_COMM_SAVE, POST_BB_COMM_DELETE} from './actionTypes';
import moment from 'moment';
const userData = JSON.parse(localStorage.getItem('userData'));

export const getBankBookList = (date, type, party, filter) => async (dispatch) => {
  dispatch(loaderStart());
  let partyID = party?.value ? party?.value : 0;
  let date1 = moment(date[0]).format('MM/DD/YYYY');

  let isDel;
  let status = filter ? filter : 0;
  if (filter == 3) {
    isDel = 1;
  } else {
    isDel = 0;
  }
  let url =
    BB_LIST + '?date=' + date1 + '&transType=' + type + '&partyAccountID=' + partyID + '&isDeleted=' + isDel + '&BankStatus=' + status;
  axios
    .post(url)
    .then((res) => {
      dispatch(loaderStop());
      dispatch({
        type: POST_BB_LIST,
        payload: res
      });
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};

export const saveBankBook = (bookInfo, onclearValue, fp, filter, toggleTempUnlock) => async (dispatch) => {
  dispatch(loaderStart());

  let data = {
    date: moment(bookInfo.date).format('YYYY-MM-DD'),
    amount: Number(bookInfo?.amount),
    bankAccountID: bookInfo?.sender.value,
    bankAccountName: bookInfo?.sender?.label,
    partyAccountID: bookInfo?.party.value,
    partyAccountName: bookInfo?.party?.label,
    narration: bookInfo?.remark,
    trans_type: bookInfo?.type,
    bankStatus: bookInfo?.bankStatus ? 1 : 2,
    id: bookInfo.id ? bookInfo.id : 0
  };

  let url = BB_SAVE;

  axios
    .post(url, data)
    .then((res) => {
      dispatch(loaderStop());
      if (res == 1 && bookInfo.id) {
        onclearValue(bookInfo?.type);
        toggleTempUnlock();
        toast.success('Record updated successfully');
        dispatch(getBankBookList([bookInfo.date], bookInfo?.type, '', filter));
      } else if (res == 1) {
        onclearValue(bookInfo?.type);
        toast.success('Record added successfully');
        dispatch(getBankBookList([bookInfo.date], bookInfo?.type, '', filter));
      } else {
        toast.error("Action Can't be Performed");
      }
      dispatch({
        type: POST_BB_SAVE,
        payload: res
      });
      fp?.current.focus();
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};

export const deleteBankBook = (ID, date, type, onclearValue, filter) => async (dispatch) => {
  dispatch(loaderStart());
  let url = BB_DELETE + ID + '?userId=' + userData.id;
  axios
    .post(url)
    .then((res) => {
      dispatch(loaderStop());

      if (res == 1) {
        toast.success('Record deleted successfully');
        onclearValue(type);
        dispatch(getBankBookList(date, type, '', filter));
      } else {
        toast.error("Action Can't be Performed");
      }
      dispatch({
        type: POST_BB_DELETE,
        payload: res
      });
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};

export const downloadBankBookList = (date, type, tranType, party, filter, bStatus) => async (dispatch) => {
  dispatch(loaderStart());
  let partyId = party?.value ? party?.value : 0;
  let partyName = party?.label ? party?.label : 'All';
  let url;
  let isDel;
  let status = filter ? filter : 0;
  if (filter == 3) {
    isDel = 1;
  } else {
    isDel = 0;
  }
  let isStatusDis = bStatus ? 0 : 1;
  let commonUrl =
    '?date=' +
    date +
    '&transType=' +
    tranType +
    '&partyAccountID=' +
    partyId +
    '&partyAccountName=' +
    partyName +
    '&BankStatus=' +
    status +
    '&isDeleted=' +
    isDel +
    '&isStatusDisabled=' +
    isStatusDis;

  if (type === 'pdf') {
    url = BB_PDF + commonUrl;
  } else {
    url = BB_CSV + commonUrl;
  }

  axios
    .get(url, {
      responseType: 'blob'
    })
    .then((response) => {
      dispatch(loaderStop());
      const url = URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = url;
      // eslint-disable-next-line no-useless-concat
      link.download = 'Bankbook' + `.${type}`;
      link.click();
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};

export const downloadBBComm = (date, type, tranType, party) => async (dispatch) => {
  dispatch(loaderStart());
  let partyId = party?.value ? party?.value : 0;
  let url;
  if (type === 'pdf') {
    url = BBC_PDF + '?date=' + date + '&tranType=' + tranType + '&partyAccountID=' + partyId;
  } else {
    url = BBC_CSV + '?date=' + date + '&transType=' + tranType + '&partyAccountID=' + partyId;
  }

  axios
    .get(url, {
      responseType: 'blob'
    })
    .then((response) => {
      dispatch(loaderStop());
      const url = URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = url;
      // eslint-disable-next-line no-useless-concat
      link.download = 'Bankbook Commission' + `.${type}`;
      link.click();
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};
//-----------------

export const getBankCommList = (date, type) => async (dispatch) => {
  dispatch(loaderStart());

  let date1 = moment(date[0]).format('MM/DD/YYYY');
  let url = BB_COMM_LIST + '?date=' + date1 + '&tranType=' + type;
  axios
    .post(url)
    .then((res) => {
      dispatch(loaderStop());
      dispatch({
        type: POST_BB_COMM_LIST,
        payload: res
      });
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};

export const saveComm = (commList, bookInfo, onClearCommission, delList) => async (dispatch) => {
  dispatch(loaderStart());

  commList.push(...delList);
  let arr = commList.map((a) => {
    return {
      id: a.id,
      companyID: userData.companyID,
      date: moment(bookInfo.date).format('YYYY-MM-DD'),
      trans_type: bookInfo.type,
      isSplit: a.isParent ? false : true,
      isDeleted: a.isDeleted ? a.isDeleted : 0,
      pAmt: a.pAmt,
      partyAccountID: a.partyAccountID,
      partyAccountName: a.partyAccountName,
      amount: Number(a.amt),
      rate: Number(a.rate),
      commission: Number(a.comm)
    };
  });

  let url = BB_COMM_SAVE;

  axios
    .post(url, arr)
    .then((res) => {
      dispatch(loaderStop());
      if (res == 1 && bookInfo.id) {
        toast.success('Commission updated successfully');
        onClearCommission();

        dispatch(getBankBookList([bookInfo.date], bookInfo.type));
      } else if (res == 1) {
        toast.success('Commission updated successfully');
        onClearCommission();

        dispatch(getBankBookList([bookInfo.date], bookInfo.type));
      } else {
        toast.error("Action Can't be Performed");
      }
      dispatch({
        type: POST_BB_COMM_SAVE,
        payload: res
      });
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};

export const deleteComm = (id) => async (dispatch) => {
  let url = BB_COMM_DELETE + id + '?userId=' + userData.id;
  axios
    .post(url)
    .then((res) => {
      dispatch({
        type: POST_BB_COMM_DELETE,
        payload: res
      });
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};

export const ApproveBankbook = (row, filter, type) => async (dispatch) => {
  dispatch(loaderStart());
  axios
    .post(BB_APPROVE + '?id=' + row?.id)
    .then((res) => {
      dispatch(loaderStop());
      toast.success('Bankbook status changed successfully');
      // date, type, party, isDel1, status1
      dispatch(getBankBookList([row?.date], type, '', filter));
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};
