import React, {Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTable, useGlobalFilter, useAsyncDebounce, useSortBy, useFilters, useExpanded, usePagination} from 'react-table';
import {Table, Row, Col, Button, Input} from 'reactstrap';
import {Filter, DefaultColumnFilter} from './filters';

// Define a default UI for filtering
function GlobalFilter({preGlobalFilteredRows, globalFilter, setGlobalFilter}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  useEffect(() => {
    if (globalFilter == undefined) {
      setValue(globalFilter);
    }
  }, [globalFilter]);

  return (
    <Col sm={3}>
      <div className='search-box me-2 mb-2 d-inline-block'>
        <div className='position-relative'>
          <label htmlFor='search-bar-0' className='search-label'>
            <span id='search-bar-0-label' className='sr-only'>
              Search this table
            </span>
            <input
              onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
              id='search-bar-0'
              type='text'
              className='form-control'
              placeholder={`${count} records...`}
              value={value || ''}
            />
          </label>
          <i className='bx bx-search-alt search-icon'></i>
        </div>
      </div>
    </Col>
  );
}

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  tableName,
  tableDataExtra,
  customPageSizeOptions
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: {pageIndex, pageSize}
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      defaultColumn: {Filter: DefaultColumnFilter},
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize
      }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };
  return (
    <Fragment>
      <Row className='mb-2'>
        {isGlobalFilter && (
          <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
        )}
        {isAddOptions && (
          <Col sm='7'>
            <div className='text-sm-end'>
              <Button type='button' color='success' className='btn-rounded  mb-2 me-2' onClick={handleOrderClicks}>
                <i className='mdi mdi-plus me-1' />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm='7'>
            <div className='text-sm-end'>
              <Button type='button' color='primary' className='btn mb-2 me-2' onClick={handleUserClick}>
                <i className='mdi mdi-plus-circle-outline me-1' />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm='7'>
            <div className='text-sm-end'>
              <Button type='button' color='success' className='btn-rounded mb-2 me-2' onClick={handleCustomerClick}>
                <i className='mdi mdi-plus me-1' />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row>
      {page.length > 0 ? (
        <div className='table-responsive react-table'>
          <Table bordered hover {...getTableProps()} className={className}>
            <thead className='table-light table-nowrap'>
              {headerGroups.map((headerGroup) => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} className={column?.headerClassName}>
                      {column.canGroupBy ? (
                        // If the column can be grouped, let's add a toggle
                        <span {...column.getGroupByToggleProps()} />
                      ) : null}
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.length > 0 ? (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <tr>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              key={cell.id}
                              {...cell.getCellProps()}
                              className={row?.original?.isDeleted && cell?.column?.Header !== 'Date' && 'deleted'}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    </Fragment>
                  );
                })
              ) : (
                <tr className='text-center'>
                  <p>No Data Available</p>
                </tr>
              )}
              {page.length > 0 && tableName == 'stockTable' && (
                <>
                  <tr>
                    <td className='text-end' colSpan={4}>
                      Total :
                    </td>
                    <td>{tableDataExtra?.totalBalance}</td>
                    <td></td>
                    <td>{tableDataExtra?.totalAmount}</td>
                  </tr>
                </>
              )}
              {page.length > 0 && tableName == 'partyStock' && (
                <>
                  <tr>
                    <td className='text-end' colSpan={2}>
                      Total:-
                    </td>
                    <td>{tableDataExtra?.totalQty}</td>
                    <td></td>
                    <td></td>
                    <td className=''>{tableDataExtra?.totalAmount}</td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className='text-center p-5'>No Data Available</div>
      )}

      {page.length > 0 && (
        <Row className=''>
          <Col md='2' lg='2'>
            <select className='form-select' value={pageSize} onChange={onChangeInSelect}>
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>{' '}
          <Col className='col-md-auto justify-content-md-end justify-content-center align-items-center ms-auto'>
            <div className='d-flex gap-1'>
              <Button color='primary' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
              </Button>
              <Button color='primary' onClick={previousPage} disabled={!canPreviousPage}>
                {'<'}
              </Button>
            </div>
          </Col>
          <Col className='col-md-auto d-none d-md-block mt-2'>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length == 0 ? 1 : pageOptions.length}
            </strong>
          </Col>
          <Col className='col-md-auto'>
            <Input
              type='number'
              min={1}
              style={{width: 70}}
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={onChangeInInput}
            />
          </Col>
          <Col className='col-md-auto'>
            <div className='d-flex gap-1'>
              <Button color='primary' onClick={nextPage} disabled={!canNextPage}>
                {'>'}
              </Button>
              <Button color='primary' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {'>>'}
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any
};

export default TableContainer;
